import { atom } from 'recoil';
import Cookies from 'js-cookie';

export const clientID = atom({
  key: 'clientID',
  default: Cookies.get('clientID') || ''
})

export const zcToken = atom({
  key: 'zcToken',
  default: Cookies.get('zcToken') || ''
})

export const loggedIn = atom({
  key: 'loggedIn',
  default: Cookies.get('zcToken') ? true : false
})

export const loading = atom({
  key: 'loading',
  default: true
})

export const userData = atom({
  key: 'userData',
  default: {}
})

export const cart = atom({
  key:'cart',
  default:[]
})