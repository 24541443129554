import { navigate } from '@reach/router'
import axios from 'axios'
import React from 'react'
import {
  Plus as PlusIcon,
  Edit as EditIcon,
  Trash2 as DeleteIcon,
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'

export default function PromotionsPage() {
  const [promotions, setPromotions] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [activePromotions, setActivePromotions] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchPromotions = async () => {
    try {
      const { data } = await axios.get('promotions')
      if (data.success) {
        setPromotions(data.data)
        setActivePromotions(filterActive(data.data))
        setRows(
          data.data.map(
            (data) => ({
              id: data.id,
              name: data.product.name,
              price: data.product.price,
              discountValue: data.discount_value,
              type: data.type,
              startAt: data.start_at,
              endAt: data.end_at,
              isActive: data.is_active,
            })
          )
        )
      }
    } catch (err) {
      const error = getErrorMessage(err)
      toast.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const deletePromotion = async (id, index) => {
    try {
      const { data } = await axios.delete(`promotions/${id}`)
      if (data.success) {
        const temp = [...promotions]
        temp.splice(index, 1)
        setPromotions(temp)
        setActivePromotions(filterActive(temp))
        setRows(
          temp.map((data) => ({
            id: data.id,
            name: data.product.name,
            type: data.type,
            startAt: data.start_at,
            endAt: data.end_at,
            isActive: data.is_active,
          }))
        )
        toast.success(
          <UndoAction
            name={promotions[index].name}
            onClick={() => restorePromotion(id, index)}
          />, 4000
        )
      }
    } catch (err) {
      const error = getErrorMessage(err)
      toast.error(error)
    }
  }

  const restorePromotion = async (id, index) => {
    try {
      const { data } = await axios.post(`promotions/${id}/restore`)

      if (data.success) {
        const temp = [...promotions]
        temp[index] = data.data
        setPromotions(temp)
        setActivePromotions(filterActive(temp))
        setRows(
          temp.map(
            (data) => ({
              id: data.id,
              name: data.product.name,
              type: data.type,
              startAt: data.start_at,
              endAt: data.end_at,
              isActive: data.is_active,
            }))
        )
        toast.remove()
      }
    } catch (err) {
      const error = getErrorMessage(err)
      toast.error(error)
    }
  }

  React.useEffect(() => {
    fetchPromotions()
  }, [])

  return (
    <Card>
      <Card.Header
        title='Sales and Promotions'
        description='promotions'
        tableStats={[
          { label: 'Total', value: promotions.length },
          { label: 'Active', value: activePromotions.length },
        ]}
        btns={[
          {
            title: 'Add New Promotion',
            icon: PlusIcon,
            href: 'promotions/add',
            variant: 'primary',
          }
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            width: '50px',
            key: 'id',
          },
          {
            title: 'Name',
            key: 'name',
          },
          {
            title: 'Price',
            width: '100px',
            key: 'price',
            unit: 'ks',
          },
          {
            title: 'Discount Value',
            width: '100px',
            key: 'discountValue',
            unit: 'ks',
          },
          {
            title: 'Type',
            width: '100px',
            key: 'type',
            className: 'badge badge-secondary rounded-pill',
          },
          {
            title: 'Start At',
            width: '100px',
            key: 'startAt',
          },
          {
            title: 'End At',
            width: '100px',
            key: 'endAt',
          },
          {
            title: 'Active',
            width: '50px',
            key: 'isActive',
            editable: {
              type: 'switch',
              name: 'is_active',
            }
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id) => navigate(`promotions/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deletePromotion,
                },
              ]
            }
          }
        ]}
      />
    </Card>
  )
}
