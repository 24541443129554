import React from 'react'
import Cookies from 'js-cookie'
import { Link, navigate, Match } from '@reach/router'
import { useRecoilValue } from 'recoil'
import {
  Menu as MenuIcon,
  X as XIcon,
  ShoppingCart as ShoppingCartIcon,
  Users as UsersIcon,
  BarChart as BarChartIcon,
  Award as AwardIcon,
  Book as BookIcon,
  Box as BoxIcon,
  Anchor as AnchorIcon,
  Star as StarIcon,
  Layers as LayerIcon,
  Folder as FolderIcon,
  Flag as FlagIcon,
  Cloud as CloudIcon,
  Sun as SunIcon,
  Home as HomeIcon,
  Truck as TruckIcon,
  DollarSign as DollarIcon,
  MessageSquare as MessageIcon,
  Bell as BellIcon,
  LogOut as LogOutIcon,
  ChevronDown as ChevronDownIcon,
  Moon as MoonIcon,
  ShoppingBag as ShoppingBagIcon,
  CreditCard as CreditCardIcon,
  Clock as ClockIcon,
  Calendar as CalendarIcon,
  Gift as GiftIcon,
} from 'react-feather'

import { loading, userData } from '../utils/recoil'
import LoadingSpinner from './LoadingSpinner'
import initAside from '../dashforge/dashforge.aside'

const NavLink = ({ to, children }) => (
  <Match path={to}>
    {({ match }) => (
      <li className={match ? 'nav-item active' : 'nav-item'}>
        <Link className='nav-link' to={to}>
          {children}
        </Link>
      </li>
    )}
  </Match>
)

export default function Layout({
  children,
  blank = false,
  sidebar = true,
  header = true,
}) {
  const { id, name, roles } = useRecoilValue(userData)
  const isUserLoading = useRecoilValue(loading)
  const [toggleMode, setToggleMode] = React.useState(true)

  React.useEffect(() => {
    if (!isUserLoading) {
      initAside()
    }
  }, [isUserLoading])

  const signOut = (e) => {
    e.preventDefault()
    Cookies.remove('zcToken')
    navigate('/signin?redirect=')
  }

  const toggleDarkMode = () => {
    const darkModeStyleSheetElement = document.getElementById('dark-mode')
    setToggleMode(!toggleMode)
    if (darkModeStyleSheetElement) {
      darkModeStyleSheetElement.remove()
    } else {
      var link = document.createElement('link')
      link.setAttribute('rel', 'stylesheet')
      link.type = 'text/css'
      link.href = '/assets/css/skin.dark.css'
      link.id = 'dark-mode'
      document.head.appendChild(link)
    }
  }

  if (isUserLoading) {
    return <LoadingSpinner />
  }

  if (blank) {
    return children
  }

  return (
    <div>
      {sidebar && (
        <aside className='layout aside aside-fixed d-print-none'>
          <div className='aside-header'>
            <a href='../../' className='aside-logo'>
              Zay <span>Chin</span>
            </a>
            <a href='#' className='aside-menu-link'>
              <MenuIcon className='feather feather-menu' />
              <XIcon className='feather feather-menu' />
            </a>
          </div>

          <div className='aside-body'>
            <div className='aside-loggedin'>
              <div className='d-flex align-items-center justify-content-start'>
                <div className='avatar avatar-online'>
                  <span className='avatar-initial rounded-circle'>
                    {name
                      ? name
                          .match(/(\b\S)?/g)
                          .join('')
                          .match(/(^\S|\S$)?/g)
                          .join('')
                          .toUpperCase()
                      : 'UK'}
                  </span>
                </div>
                <div className='aside-alert-link'>
                  <a
                    href=''
                    onClick={signOut}
                    data-toggle='tooltip'
                    title='Sign out'
                  >
                    <LogOutIcon />
                  </a>
                </div>
              </div>
              <div className='aside-loggedin-user'>
                <a
                  href='#loggedinMenu'
                  className='d-flex align-items-center justify-content-between mg-b-2'
                  data-toggle='collapse'
                >
                  <h6 className='tx-semibold mg-b-0'>
                    {name ? name : 'Unknown'}
                  </h6>
                  <ChevronDownIcon className='feather feather-menu' />
                </a>
                <p className='tx-color-03 tx-12 mg-b-0'>
                  {roles ? (
                    roles.map((role, index) => (
                      <span
                        className='badge rounded-pill badge-warning'
                        key={index}
                      >
                        {role.name}
                      </span>
                    ))
                  ) : (
                    <span className='badge rounded-circle badge-light'>
                      ဒါအလုပ်မလုပ်သေးဘူးဗျ
                    </span>
                  )}
                </p>
              </div>
              <div className='collapse' id='loggedinMenu'>
                <ul className='nav nav-aside mg-b-0'>
                  <li className='nav-item'>
                    <a href={`/users/${id}/edit`} className='nav-link'>
                      <i className='icon ion-create-outline mg-r-10'></i>{' '}
                      <span>Edit Profile</span>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a href='' className='nav-link' onClick={signOut}>
                      <i className='icon ion-log-out-outline  mg-r-10'></i>{' '}
                      <span>Sign Out</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <ul className='nav nav-aside '>
              <li className='nav-label'>Dashboard</li>
              <NavLink to='/'>
                <BarChartIcon />
                <span>Overall</span>
              </NavLink>
              <li className='nav-label mg-t-25'>Orders</li>
              <NavLink to='/orders'>
                <ShoppingBagIcon />
                <span>Orders</span>
              </NavLink>
              <NavLink to='/carts'>
                <ShoppingCartIcon />
                <span>Carts</span>
              </NavLink>
              <li className='nav-label mg-t-25'>Inventories</li>
              <NavLink to='/inventories/entries'>
                <BookIcon />
                <span>Inventories</span>
              </NavLink>
              <NavLink to='/products'>
                <BoxIcon />
                <span>Products</span>
              </NavLink>
              <NavLink to='/suppliers'>
                <AnchorIcon />
                <span>Suppliers</span>
              </NavLink>
              <NavLink to='/brands'>
                <StarIcon />
                <span>Brands</span>
              </NavLink>
              <li className='nav-label mg-t-25'>Grouping</li>
              <NavLink to='/collections'>
                <LayerIcon />
                <span>Collections</span>
              </NavLink>
              <NavLink to='/promotions'>
                <GiftIcon />
                <span>Promotions</span>
              </NavLink>
              <NavLink to='/categories'>
                <FolderIcon />
                <span>Categories</span>
              </NavLink>
              <NavLink to='/badges'>
                <AwardIcon />
                <span>Badges</span>
              </NavLink>
              <li className='nav-label mg-t-25'>Locations</li>
              <NavLink to='/countries'>
                <FlagIcon />
                <span>Countries</span>
              </NavLink>
              <NavLink to='/cities'>
                <CloudIcon />
                <span>Cities</span>
              </NavLink>
              <NavLink to='/townships'>
                <SunIcon />
                <span>Townships</span>
              </NavLink>
              <NavLink to='/warehouses'>
                <HomeIcon />
                <span>Warehouses</span>
              </NavLink>
              <li className='nav-label mg-t-25'>Delivery</li>
              <NavLink to='/deliveries'>
                <TruckIcon />
                <span>Deliveries</span>
              </NavLink>
              <NavLink to='/delivery-dates'>
                <CalendarIcon />
                <span>Delivery Dates</span>
              </NavLink>
              <NavLink to='/delivery-times'>
                <ClockIcon />
                <span>Delivery Times</span>
              </NavLink>
              <li className='nav-label mg-t-25'>Merchants</li>
              <NavLink to='/banks'>
                <DollarIcon />
                <span>Banks</span>
              </NavLink>
              <li className='nav-label mg-t-25'>User Management</li>
              <NavLink to='/users'>
                <UsersIcon />
                <span>Users</span>
              </NavLink>
              <NavLink to='/roles'>
                <AwardIcon />
                <span>Roles</span>
              </NavLink>
              <li className='nav-label mg-t-25'>System</li> {/*Nav Label*/}
              {/* <li className='nav-item'>
              <Link to='/configurations/app' className='nav-link'>
                <i data-feather='sliders'></i>
                <span>App Configurations</span>
              </Link>
            </li> */}
              {/* <li className='nav-item'>
              <Link to='/configurations' className='nav-link'>
                <i className='icon ion-settings  pd-r-5' ></i>
                <span>Configurations</span>
              </Link>
            </li>*/}
              <NavLink to='/sms'>
                <MessageIcon />
                <span>SMS</span>
              </NavLink>
              <NavLink to='/notifications'>
                <BellIcon />
                <span>Notifications</span>
              </NavLink>
            </ul>
          </div>
        </aside>
      )}
      <div className='content ht-100v pd-0'>
        {header && (
          <div className='content-header d-print-none'>
            <div className='content-search'>
              <i data-feather='search'></i>
              <input
                type='search'
                className='form-control'
                placeholder='Search...'
              />
            </div>
            <div className='navbar-right'>
              <nav className='nav'>
                <a href='#' onClick={toggleDarkMode} className='nav-link'>
                  {toggleMode ? (
                    <MoonIcon className='feather feather-menu' fill='#e3e7ec' />
                  ) : (
                    <SunIcon className='feather feather-menu' />
                  )}
                </a>
              </nav>
            </div>
          </div>
        )}
        <div className='content-body pos-relative'>{children}</div>
      </div>
    </div>
  )
}
