import React from 'react'
import { navigate, useLocation } from '@reach/router'
import axios from 'axios'
import queryString from 'query-string'
import {
  Edit2 as EditIcon,
  Plus as PlusIcon,
  Sliders as FilterIcon,
  Trash2 as DeleteIcon
} from 'react-feather'
import toast from 'react-hot-toast'
import { isArray, isEmpty } from 'underscore'
import Card from '../../components/Card'
import ProductsFilter from '../../components/filters/ProductsFilter'
import LoadingSpinner from '../../components/LoadingSpinner'
import OffCanvas from '../../components/OffCanvas'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import initOffcanvas from '../../dashforge/dashforge.offcanvas'
import initPeity from '../../dashforge/dashforge.peity'
import useFilter from '../../hooks/useFilter'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'

export default function ProductsPage() {
  const location = useLocation()
  const searchParams = queryString.parse(location.search, {
    arrayFormat: 'comma',
    parseNumbers: true,
    parseBooleans: true,
  })
  const [params, setParams] = React.useState(searchParams)
  const results = useFilter('products', params)
  const [products, setProducts] = React.useState([])
  const [activeProducts, setActiveProducts] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [prices, setPrices] = React.useState([])
  const [isToggleFilter, setIsToggleFilter] = React.useState(false)

  const toggleFilter = () => {
    setIsToggleFilter((isToggleFilter) => !isToggleFilter)
  }

  const restoreProduct = async (id, index) => {
    try {
      const { data } = await axios.post(`products/${id}/restore`)
      console.log('restore data', data)
      if (data.success) {
        const temp = [...products]
        temp[index] = data.data
        setProducts(temp)
        setRows(
          temp.map(
            (data) => ({
              id: data.id,
              name: data.name,
              mmName: data.mm_name,
              price: data.price,
              prices: data.prices.map((price) => price.price).join(','),
              sku: data.sku,
              thumb:
                data.files[0] &&
                `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
              weight: data.weight,
              isActive: data.is_active,
              outOfStock: data.out_of_stock,
              isHighlighted: data.is_highlighted,
            }))
        )
        toast.remove()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteProduct = async (id, index) => {
    try {
      const { data } = await axios.delete(`products/${id}`)
      console.log('delete data', data)
      if (data.success) {
        const temp = [...products]
        temp.splice(index, 1)
        setProducts(temp)
        setRows(
          temp.map(
            (data) => ({
              id: data.id,
              name: data.name,
              mmName: data.mm_name,
              price: data.price,
              prices: data.prices.map((price) => price.price).join(','),
              sku: data.sku,
              thumb:
                data.files[0] &&
                `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
              categories: data.categories || [],
              badges: data.badges,
              isActive: data.is_active,
              outOfStock: data.out_of_stock,
              isHighlighted: data.is_highlighted,
            }))
        )
        toast(
          <UndoAction
            onClick={() => restoreProduct(id, index)}
            name={products[index].name}
          />
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const editProduct = async (
    id,
    index,
    name,
    value,
    defaultValue,
    setShowPopover
  ) => {
    try {
      const { data } = await axios.put(`products/${id}/partial-update`, {
        name,
        value: value || defaultValue,
      })
      console.log('partial edit', data)
      if (data.success) {
        const temp = [...products]
        temp[index][name] = value || defaultValue
        setProducts(temp)
        setRows(
          temp.map(
            (data) => ({
              id: data.id,
              name: data.name,
              mmName: data.mm_name,
              price: data.price,
              prices: data.prices.map((price) => price.price).join(','),
              sku: data.sku,
              thumb: `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
              weight: data.weight,
              isActive: data.is_active,
              outOfStock: data.out_of_stock,
              isHighlighted: data.is_highlighted,
            }))
        )
        toast.success('Success')
        setShowPopover(false)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    if (results.length) {
      setIsLoading(false)
      setProducts(results)
      setActiveProducts(filterActive(results))
      setRows(
        results.map(
          (data) => ({
            id: data.id,
            name: data.name,
            mmName: data.mm_name,
            price: data.price,
            prices: data.prices.map((price) => price.price).join(','),
            sku: data.sku,
            thumb:
              data.files[0] &&
              `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
            weight: data.weight,
            isActive: data.is_active,
            outOfStock: data.out_of_stock,
            isHighlighted: data.is_highlighted,
          }))
      )
    }
  }, [results])

  React.useEffect(() => {
    if (!isEmpty(params)) {
      let queries = []
      for (let property in params) {
        if (params[property]) {
          queries.push(`${property}=${isArray(params[property]) ? params[property].join(',') : params[property]}`)
        }
      }
      const queryString = queries.join('&')
      navigate(`products?${queryString}`)
    } else {
      navigate(`products`)
    }
  }, [params])

  React.useEffect(() => {
    initOffcanvas(setIsToggleFilter)
  }, [isToggleFilter])

  React.useEffect(() => {
    if (!isLoading) {
      initPeity()
    }
  }, [isLoading])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Products'
        description='Things we can sell'
        tableStats={[
          {
            label: 'total',
            value: products.length,
          },
          {
            label: 'active',
            value: activeProducts.length,
          },
        ]}
        btns={[
          {
            icon: FilterIcon,
            onClick: toggleFilter,
            variant: 'outline-light',
          },
          {
            title: 'Add new Product',
            icon: PlusIcon,
            href: `/products/add`,
            variant: 'primary',
          },
        ]}
      />
      <Table
        onClick={editProduct}
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Image',
            width: '100px',
            render: {
              type: 'image',
              key: 'thumb',
              alt: '',
              className: 'img-fluid pd-0',
            }
          },
          {
            title: 'Name',
            key: 'name',
            subs: [
              {
                key: 'mmName',
                className: 'tx-color-03',
              },
            ],
            editable: {
              type: 'text',
              name: 'name',
            },
          },
          {
            title: 'SKU',
            key: 'sku',
            editable: {
              type: 'text',
              name: 'sku',
            },
            width: '150px',
          },
          {
            title: 'Price',
            key: 'price',
            editable: {
              type: 'number',
              name: 'price',
              defaultValue: 0,
            },
          },
          {
            title: 'Price History',
            render: (id, index) => (
              <>
                <span className='peity-line'>
                  {rows[index].prices}
                </span>
              </>
            ),
          },
          {
            title: 'Active',
            key: 'isActive',
            width: '50px',
            editable: {
              type: 'switch',
              name: 'is_active',
            },
          },
          {
            title: 'Highlight',
            key: 'isHighlighted',
            width: '50px',
            editable: {
              type: 'switch',
              name: 'is_highlighted',
            },
          },
          {
            title: 'Out Of Stock',
            key: 'outOfStock',
            width: '120px',
            editable: {
              type: 'switch',
              name: 'out_of_stock',
            },
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id) => navigate(`products/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteProduct,
                }
              ],
            },
          },
        ]}
      />
      {
        isToggleFilter && (
          <OffCanvas>
            <ProductsFilter
              params={params}
              setParams={setParams}
              setRows={setRows}
            />
          </OffCanvas>
        )
      }
    </Card>
  )
}
