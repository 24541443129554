import React from 'react'
import {
  Maximize2 as MaximizeIcon,
  ArrowUp as ArrowUpIcon,
} from 'react-feather'

export default function LTVDownloadWidget() {
  return (
    <div className='card'>
      <div className='card-header pd-y-20 d-md-flex align-items-center justify-content-between'>
        <h6 className='mg-b-0'>LTV</h6>
        <div>
          <a href='' className='tx-13 link-03'>
            <MaximizeIcon className='wd-15 ht-15' />
          </a>
        </div>
      </div>
      <div className='card-body pos-relative'>
        <div className='row'>
          <div className='col-6'>
            <p className='tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-5'>
              Average Basket
            </p>
            <div className='d-flex align-items-center'>
              <div className='wd-10 ht-10 rounded-circle bg-pink mg-r-5'></div>
              <h6 className='tx-normal tx-rubik mg-b-0'>
                20200{' '}
                <span className='mg-l-5 tx-10 tx-normal tx-success'>
                  <ArrowUpIcon className='icon' /> 0.5%
                </span>
              </h6>
            </div>
          </div>
          <div className='col-6'>
            <p className='tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5'>
              Average order per day
            </p>
            <div className='d-flex align-items-center'>
              <div className='wd-10 ht-10 rounded-circle bg-primary mg-r-5'></div>
              <h6 className='tx-normal tx-rubik mg-b-0'>
                242
                <span className='mg-l-5 tx-10 tx-normal tx-success'>
                  <ArrowUpIcon className='icon' /> 0.2%
                </span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
