import React from 'react'
import { Settings as SettingsIcon, Trash as TrashIcon } from 'react-feather'
import Modal from '../../common/Modal'
import FileUpload from '../FileUpload'

function LinkComponentItem({ item, index, onDelete }) {
  const [displaySetting, setDisplaySetting] = React.useState(false)
  const [image, setImage] = React.useState(null)
  console.log(image)
  return (
    <div className='component-item card mb-3'>
      {/* <img src='...' class='card-img-top' alt='...' /> */}
      <div
        className='d-flex'
        style={{ position: 'absolute', right: '15px', top: '15px' }}
      >
        <button
          className='btn btn-light btn-xs mg-r-5'
          onClick={() => setDisplaySetting(true)}
        >
          <SettingsIcon />
        </button>
        <button
          className='btn btn-danger btn-xs'
          onClick={() => onDelete(index)}
        >
          <TrashIcon />
        </button>
      </div>
      <div className='card-body'>
        <p
          className='card-text d-flex align-items-center justify-content-center'
          style={{ height: 100 }}
        >
          Please upload a image
        </p>
      </div>
      {displaySetting && (
        <Modal onDismiss={() => setDisplaySetting(false)}>
          <Modal.Header>Configure Component</Modal.Header>
          <Modal.Body>
            <FileUpload
              name='image'
              relatedType='builder'
              photos={image && [image]}
              setPhotos={(photos) => setImage(photos[0])}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default LinkComponentItem
