import React from 'react'
import Card from '../../components/Card'
import DeliveryTimeForm from './DeliveryTimeForm'

export default function AddDeliveryTimePage() {
  return (
    <Card>
      <Card.Header
        title='Add New DeliveryTime'
        description='DeliveryTimes'
        href='/delivery-times'
      />
      <DeliveryTimeForm />
    </Card>
  )
}
