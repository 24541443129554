import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import ErrorComponent from '../../components/ErrorComponent'
import getErrorMessage from '../../utils/getErrorMessage'
import getUrlParameter from '../../utils/getUrlParameter'

export default function SignInPage(props) {
  const redirect = getUrlParameter('redirect')
  
  const signIn = async ({ phone }) => {
    try {
      axios.defaults.baseURL = process.env.REACT_APP_MAIN_PUBLIC_URL
      const { data } = await axios({
        method: 'POST',
        url: 'auth/login',
        data: {
          phone,
        },
      })
      if (data.success) {
        navigate('/verification', {
          state: {
            phone: data.phone,
            redirect,
          },
        })
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  return (
    <div
      className='full-height-page d-flex sign-in-background'
    >
      <div className='d-flex flex-1 align-items-center justify-content-center'>
        <Formik
          initialValues={{
            phone: '',
          }}
          validationSchema={Yup.object({
            phone: Yup.string()
              .required('Phone Required')
              .min(5, 'Phone must be at least 5 characters')
          })}
          onSubmit={(values) => {
            console.log('formik values', values)
            signIn(values)
          }}
        >
          {({ isSubmitting }) => (
            <div className='col-8 col-sm-6 col-md-4 card bd-0 pt-2'>
              <Form className='card-body'>
                <div className='form-group'>
                  <h3>Sign In</h3>
                  <p>Welcome back! Sign in Again..</p>
                </div>
                <div className='form-group '>
                  <label>Phone Number</label>
                  <Field
                    name='phone'
                    placeholder='09'
                    type='text'
                    className='form-control bg-gray-100'
                    autoFocus
                  />
                  <ErrorComponent>
                    <ErrorMessage name='phone' />
                  </ErrorComponent>
                </div>
                <div className='form-group'>
                  <button
                    className='btn btn-primary btn-block tx-white form-control'
                    type='submit'
                  >
                    {isSubmitting ? 'Getting OTP...' : 'Get OTP'}
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  )
}
