import React from 'react'
import { navigate, useParams } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import MultiSelect from '../../common/MultiSelect'
import SingleSelect from '../../common/SingleSelect'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import FileUpload from '../../components/FileUpload'
import { notificationTypes as types } from '../../data/types'
import getErrorMessage from '../../utils/getErrorMessage'

export default function NotificationForm({ editForm, id }) {
  const [users, setUsers] = React.useState([])
  const [type, setType] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [body, setBody] = React.useState(useParams().body || '')
  const [isGlobal, setIsGlobal] = React.useState(false)
  const [priority, setPriority] = React.useState('')
  const [image, setImage] = React.useState([])
  const [deepLink, setDeepLink] = React.useState('')
  const [path, setPath] = React.useState('')
  const [isPushNotification, setIsPushNotification] = React.useState(false)
  const [isSendSms, setIsSendSms] = React.useState(false)

  const priorities = [
    {
      id: 'low',
      name: 'Low'
    },
    {
      id: 'normal',
      name: 'Normal'
    },
    {
      id: 'high',
      name: 'High'
    }
  ]

  const notificationSchema = Yup.object({
    type: Yup.string()
      .required('Required'),
    priority: Yup.string()
      .required('Priority Required'),
  })

  const onSubmit = async ({
    users,
    type,
    title,
    body,
    isGlobal,
    priority,
    deepLink,
    path,
    isPushNotification,
    isSendSms,
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const url = editForm ? `notifications/${id}` : `notifications`

      const { data } = await axios({
        method,
        url,
        data: {
          users: users.map(
            (user) => user.value
          ),
          type,
          title,
          body,
          priority,
          files: image.map(
            (file) => file.id
          ),
          deeplink: deepLink,
          path,
          is_global: isGlobal,
          is_push_notification: isPushNotification,
          is_send_sms: isSendSms,
        }
      })
      console.log('notification create', data)
      if (data.success) {
        navigate('/notifications')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  return (
    <Container>
      <Formik
        initialValues={{
          users,
          type,
          title,
          body,
          priority,
          deepLink,
          path,
          isGlobal,
          isPushNotification,
          isSendSms,
        }}
        validationSchema={notificationSchema}
        onSubmit={
          (values) => {
            onSubmit(values);
          }
        }
      >
        {
          ({
            values,
            isSubmitting,
            setFieldValue,
            handleChange,
          }) => (
            <Form>
              <div className='form-group row'>
                <div className='col-md-3 tx-md-right'>
                  <label className='col-form-label'>Users</label>
                </div>
                <div className='col-md-9'>
                  <MultiSelect
                    name='users'
                    endpoint='users'
                    onChange={(users) => setFieldValue('users', users)}
                    defaultValue={values.users}
                    className='pd-0'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-3 tx-md-right'>
                  <label className='col-form-label'>Title</label>
                </div>
                <div className='col-md-9'>
                  <Field
                    name='title'
                    type='text'
                    className='form-control'
                    placeholder='Order Received'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-3 tx-md-right'>
                  <label className='col-form-label'>Body</label>
                </div>
                <div className='col-md-9'>
                  <Field
                    name='body'
                    as='textarea'
                    className='form-control'
                    placeholder='လူကြီးမင်း၏ အော်ဒါကို လက်ခံရရှိပါသည်။'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-3 tx-md-right'>
                  <label className='col-form-label'>Type</label>
                </div>
                <div className='col-md-9'>
                  <SingleSelect
                    name='type'
                    label='Type'
                    data={types}
                    onChange={(type) => setFieldValue('type', type)}
                  />
                  <ErrorComponent>
                    <ErrorMessage name='type' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-3 tx-md-right'>
                  <label className='col-form-label'>Priority</label>
                </div>
                <div className='col-md-9'>
                  <SingleSelect
                    name='priority'
                    label='Priority'
                    data={priorities}
                    onChange={(priority) => setFieldValue('priority', priority)}
                  />
                  <ErrorComponent>
                    <ErrorMessage name='priority' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-md-3 tx-md-right'>
                  <label className='col-form-label'>Image</label>
                </div>
                <div className='col-md-9'>
                  <FileUpload
                    name='image'
                    id={id}
                    relatedType='notifications'
                    multiple={false}
                    photos={image}
                    setPhotos={setImage}
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-3 tx-md-right'>
                  <label className='col-form-label'>Deep Link</label>
                </div>
                <div className='col-md-9'>
                  <Field
                    name='deepLink'
                    type='text'
                    className='form-control'
                    placeholder='Deep Link'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-3 tx-md-right'>
                  <label className='col-form-label'>Path</label>
                </div>
                <div className='col-md-9'>
                  <Field
                    name='path'
                    type='type'
                    className='form-control'
                    placeholder='Path'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      name='isPushNotification'
                      type='checkbox'
                      className='custom-control-input'
                      id='isPushNotification'
                      value={values.isPushNotification}
                      checked={values.isPushNotification}
                      onChange={handleChange}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='isPushNotification'>
                      Push Notification
                    </label>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      id='sendSms'
                      name='isSendSms'
                      type='checkbox'
                      className='custom-control-input'
                      value={values.isSendSms}
                      checked={values.isSendSms}
                      onChange={handleChange}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='sendSms'>
                      Send SMS
                    </label>
                  </div>
                </div>
              </div>


              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      type='checkbox'
                      name='isGlobal'
                      id='isGlobal'
                      className='custom-control-input'
                      value={values.isGlobal}
                      checked={values.isGlobal}
                      onChange={handleChange}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='isGlobal'>
                      Global
                    </label>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <button type='submit' className='btn btn-primary btn-block'>
                    {isSubmitting ? `SUBMITTING` : `SUBMIT`}
                  </button>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </Container>
  )
}