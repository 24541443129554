import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Edit2 as EditIcon,
  Plus as PlusIcon,
  Trash2 as DeleteIcon
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import LoadingSpinner from '../../components/LoadingSpinner'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'

export default function DeliveriesPage() {
  const [deliveries, setDeliveries] = React.useState([])
  const [activeDeliveries, setActiveDeliveries] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchDeliveries = async () => {
    try {
      const { data } = await axios.get('deliveries')
      console.log('deliveries', data)
      if (data.success) {
        setDeliveries(data.data)
        setActiveDeliveries(filterActive(data.data))
        setRows(
          data.data.map((data) => ({
            id: data.id,
            logo: data.files[0] && `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
            name: data.name,
            phones: data.phones?.join(', '),
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreDelivery = async (id, index) => {
    try {
      const { data } = await axios.post(`deliveries/${id}/restore`)
      if (data.success) {
        const temp = [...deliveries]
        temp[index] = data.data
        setDeliveries(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            logo: data.files[0] && `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
            name: data.name,
            phones: data.phones.join(', '),
          }))
        )
        toast.remove()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteDelivery = async (id, index) => {
    try {
      const { data } = await axios.delete(`deliveries/${id}`)
      if (data.success) {
        const temp = [...deliveries]
        temp.splice(index, 1)
        setDeliveries(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            logo: data.files[0] && `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
            name: data.name,
            phones: data.phones.join(', '),
          }))
        )
        toast.success(
          <UndoAction
            onClick={() => restoreDelivery(id, index)}
            name={deliveries[index].name}
          />,
          4000
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    fetchDeliveries()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Deliveries'
        description='Deliveries'
        tableStats={[
          { label: 'total deliveries', value: deliveries.length },
          { label: 'active deliveries', value: activeDeliveries.length },
        ]}
        btns={[
          {
            title: 'Add new delivery',
            icon: PlusIcon,
            href: 'deliveries/add',
            variant: 'primary',
          },
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Logo',
            width: '100px',
            render: {
              type: 'image',
              key: 'logo',
              alt: '',
              className: 'img-fluid pd-0',
            }
          },
          {
            title: 'Name',
            key: 'name',
            width: '200px',
          },
          {
            title: 'Phones',
            key: 'phones',
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id)=> navigate(`deliveries/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteDelivery,
                },
              ]
            },
          },
        ]}
      />
    </Card>
  )
}
