import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import moment from 'moment'
import {
  Edit2 as EditIcon, 
  Plus as PlusIcon,
  Trash2 as DeleteIcon,
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import LoadingSpinner from '../../components/LoadingSpinner'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'

export default function BadgesPage() {
  const [badges, setBadges] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [activeBadges, setActiveBadges] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchBadges = async () => {
    try {
      const { data } = await axios.get('badges')
      console.log('badges', data.data)
      if (data.success) {
        setBadges(data.data)
        setActiveBadges(filterActive(data.data))
        setRows(
          data.data.map((data) => ({
            id: data.id,
            name: data.name,
            start: moment(data.start).format('YYYY-MM-DD'),
            end: moment(data.end).format('YYYY-MM-DD'),
            image: data.files[0] && `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreBadge = async (id, index) => {
    try {
      const { data } = await axios.post(`badges/${id}/restore`)
      if (data.success) {
        const temp = [...badges]
        temp[index] = data.data
        setBadges(temp)
        setRows(
          temp.map((badge) => ({
            id: badge.id,
            name: badge.name,
            start: moment(badge.start).format('YYYY-MM-DD'),
            end: moment(badge.end).format('YYYY-MM-DD'),
            image: data.files[0] && `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
          }))
        )
        toast.remove()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteBadge = async (id, index) => {
    try {
      const { data } = await axios.delete(`badges/${id}`)
      if (data.success) {
        const temp = [...badges]
        temp.splice(index, 1)
        setBadges(temp)
        setRows(
          temp.map((badge) => ({
            id: badge.id,
            name: badge.name,
            start: moment(badge.start).format('YYYY-MM-DD'),
            end: moment(badge.end).format('YYYY-MM-DD'),
            image: data.files[0] && `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
          }))
        )
        toast(
          <UndoAction
            onClick={() => restoreBadge(id, index)}
            name={badges[index].name}
          />
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    fetchBadges()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Badges'
        description='Badges'
        tableStats={[
          { label: 'Total Badges', value: badges.length },
          { label: 'Active Badges', value: activeBadges.length },
        ]}
        btns={[
          {
            title: 'Add new badge',
            icon: PlusIcon,
            href: 'badges/add',
            variant: 'primary',
          },
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Image',
            width: '100px',
            render: {
              type: 'image',
              key: 'image',
              alt: '',
              className: 'img-fluid pd-0',
            }
          },
          {
            title: 'Name',
            key: 'name',
          },
          {
            title: 'Start Date',
            key: 'start',
            width: '100px',
          },
          {
            title: 'End Date',
            key: 'end',
            width: '100px',
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id) => navigate(`badges/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteBadge,
                }
              ]
            },
          },
        ]}
      />
    </Card>
  )
}
