import React from 'react'
import Card from '../../components/Card'
import CityForm from './CityForm'

export default function AddCityPage() {
  return (
    <Card>
      <Card.Header
        title='Add New City'
        description='cities'
        href='/cities'
      />
      <CityForm/>
    </Card>
  )
}
