import React from 'react'
import Modal from '../common/Modal'

export default function ConfirmationDialog({
  id,
  title,
  content,
  btnTitle,
  onClick,
  onDismiss,
}) {

  return (
    <Modal id={id} onDismiss={onDismiss}>
      <div onKeyDown={() => onClick()} >
        <Modal.Header>
          <div className='modal-title'>
            <h3>{title}</h3>
          </div>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>{content}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-white'
            data-dismiss='modal'
          >
            {'Cancel'}
          </button>
          <button
            className='btn btn-primary'
            onClick={() => onClick()}
            data-dismiss='modal'
          >
            {btnTitle}
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  )
}