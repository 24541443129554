export default function initAside() {
  window.$(function () {
    'use strict'
    window.$('[data-toggle="tooltip"]').tooltip()
    if (document.getElementsByClassName('aside-body').length) {
      const asideBody = new window.PerfectScrollbar('.aside-body', {
        suppressScrollX: true,
      })

      if (window.$('.aside-backdrop').length === 0) {
        window.$('body').append('<div class="aside-backdrop"></div>')
      }

      var mql = window.matchMedia('(min-width:992px) and (max-width: 1199px)')

      function doMinimize(e) {
        if (e.matches) {
          window.$('.aside').addClass('minimize')
        } else {
          window.$('.aside').removeClass('minimize')
        }

        asideBody.update()
      }

      mql.addListener(doMinimize)
      doMinimize(mql)

      window.$('.aside-menu-link').on('click', function (e) {
        e.preventDefault()

        if (window.matchMedia('(min-width: 992px)').matches) {
          window.$(this).closest('.aside').toggleClass('minimize')
        } else {
          window.$('body').toggleClass('show-aside')
        }

        asideBody.update()
      })

      window.$('.nav-aside .with-sub').on('click', '.nav-link', function (e) {
        e.preventDefault()

        window.$(this).parent().siblings().removeClass('show')
        window.$(this).parent().toggleClass('show')

        asideBody.update()
      })

      window.$('body').on('mouseenter', '.minimize .aside-body', function (e) {
        console.log('e')
        window.$(this).parent().addClass('maximize')
      })

      window.$('body').on('mouseleave', '.minimize .aside-body', function (e) {
        window.$(this).parent().removeClass('maximize')

        asideBody.update()
      })

      window.$('body').on('click', '.aside-backdrop', function (e) {
        window.$('body').removeClass('show-aside')
      })
    }
  })
}
