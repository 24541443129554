import React from 'react'
import Card from '../../components/Card'
import UserForm from './UserForm'

export default function AddUserPage() {
  return (
    <Card>
      <Card.Header
        title='Add New User'
        description='users'
        href='/users'
      />
      <UserForm />
    </Card>
  )
}