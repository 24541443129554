import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Edit2 as EditIcon,
  Layers as LayersIcon,
  Plus as PlusIcon,
  Trash2 as DeleteIcon
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import LoadingSpinner from '../../components/LoadingSpinner'
import UndoAction from '../../components/UndoAction'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'

export default function CategoriesPage() {
  const [categories, setCategories] = React.useState([])
  const [subCategories, setSubCategories] = React.useState([])
  const [activeCategories, setActiveCategories] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchCategories = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get('categories')
      console.log('categories', data)
      if (data.success) {
        setCategories(data.data)
        data.data.map((data) =>
          setSubCategories((prev) => [...prev, ...data.subs])
        )
        setActiveCategories([
          ...filterActive(data.data),
          ...filterActive(...data.data.map((data) => data.subs)),
        ])
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreCategory = async (id, index) => {
    try {
      const { data } = await axios.post(`categories/${id}/restore`)
      if (data.success) {
        // const temp = [...categories]
        // temp[index] = data.data
        // setCategories(temp)
        fetchCategories()
        toast.remove()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteCategory = async (id, index) => {
    try {
      const { data } = await axios.delete(`categories/${id}`)
      data.success && fetchCategories()
      if (data.success) {
        fetchCategories()
        toast.success(
          <UndoAction
            onClick={() => restoreCategory(id, index)}
            name='Category'
          />
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    fetchCategories()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Categories'
        description='Categories'
        tableStats={[
          {
            label: 'total',
            value: categories.length + subCategories.length,
          },
          {
            label: 'parent',
            value: categories.length,
          },
          {
            label: 'sub',
            value: subCategories.length,
          },
          {
            label: 'active',
            value: activeCategories.length,
          },
        ]}
        btns={[
          {
            title: 'Arrange',
            icon: LayersIcon,
            href: 'categories/arrange',
            variant: 'white',
          },
          {
            title: 'Add new Category',
            icon: PlusIcon,
            href: `/categories/add`,
            variant: 'primary',
          },
        ]}
      />

      <table className='table table-dashboard'>
        <thead className='thead-light'>
          <tr>
            <th scope='col' width='50px'>
              ID
            </th>
            <th scope='col'>Name</th>
            <th scope='col' width='100px'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => (
            <React.Fragment key={index}>
              <tr className='bg-light'>
                <td>{category.id}</td>
                <td>{category.name}</td>
                <td>
                  <button
                    className='btn btn-info btn-icons pd-t-0 pd-b-0 pd-l-3 pd-r-3 mg-r-5'
                    onClick={() => navigate(`categories/${category.id}/edit`)}
                  >
                    <EditIcon />
                  </button>
                  <button
                    className='btn btn-danger btn-icons pd-t-0 pd-b-0 pd-l-3 pd-r-3 mg-r-5'
                    onClick={() => deleteCategory(category.id, index)}
                    disabled={category.subs.length || false}
                  >
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
              {category.subs.map((subCategory, subIndex) => {
                return (
                  <tr key={subIndex}>
                    <td>{subCategory.id}</td>
                    <td>{subCategory.name}</td>
                    <td>
                      <button
                        className='btn btn-info btn-icon btn-xxs mg-r-5'
                        onClick={() =>
                          navigate(`categories/${subCategory.id}/edit`)
                        }
                      >
                        <EditIcon />
                      </button>
                      <button
                        className='btn btn-danger btn-icon btn-xxs mg-r-5'
                        onClick={() => deleteCategory(subCategory.id)}
                      >
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </Card>
  )
}
