import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Clipboard as ClipboardIcon,
  Copy as CopyIcon,
  Plus as PlusIcon,
  Send as SendIcon,
  CheckCircle as CheckCircleIcon,
  XCircle as XCircleIcon,
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import LoadingSpinner from '../../components/LoadingSpinner'
import Table from '../../components/Table'
import getErrorMessage from '../../utils/getErrorMessage'

export default function SmsPage() {
  const [sms, setSms] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [duplicateContent, setDuplicateContent] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchSms = async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: 'sms'
      })
      console.log('sms', data.data)
      if (data.success) {
        setSms(data.data)
        setRows(
          data.data.map((data) => ({
            id: data.id,
            phone: data.phone,
            sms: data.content,
            success: data.success,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.err(err)
    } finally {
      setIsLoading(false)
    }
  }

  const copyPhone = (id, index) => {
    let textField = document.createElement('textarea')
    textField.innerText = sms[index].phone
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toast.success('Copied Phone')
  }

  const duplicateSms = (id, index) => {
    setDuplicateContent(sms[index].content)
    toast.success('Duplicated SMS')
  }

  const resendSms = async (id, index) => {
    try {
      const { data } = await axios({
        method: 'POST',
        url: 'sms',
        data: {
          phones: [rows[index].phone],
          message: rows[index].sms,
        }
      })
      console.log('resend res ', data)
      if (data.success) {
        toast.success('Scheduled to send')
        navigate('/sms')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    fetchSms()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='SMS'
        description='SMS'
        tableStats={[{ label: 'Total SMS', value: sms.length }]}
        btns={[
          {
            title: 'Send SMS',
            icon: PlusIcon,
            href: `sms/send?content=${duplicateContent}`,
            variant: 'primary',
          },
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Phone',
            key: 'phone',
            width: '100px',
          },
          {
            title: 'SMS',
            key: 'sms',
          },
          {
            title: 'Status',
            width: '50px',
            render: (id, index) => (
              <span className={`badge badge-pill`}>
                {
                  sms[index].success ? (
                    <CheckCircleIcon className='tx-success' />
                  ) : (
                      <XCircleIcon className='tx-danger' />
                    )
                }
              </span>
            ),
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: ClipboardIcon,
                  variant: 'btn-success',
                  onClick: copyPhone,
                },
                {
                  icon: CopyIcon,
                  variant: 'btn-info',
                  onClick: duplicateSms,
                },
                {
                  icon: SendIcon,
                  variant: 'btn-danger',
                  onClick: resendSms,
                }
              ]
            },
          },
        ]}
      />
    </Card>
  )
}
