import React from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import AsyncSelect from 'react-select/async'
import getErrorMessage from '../utils/getErrorMessage'

export default function AsyncSingleSelect({ defaultValue, endpoint, options = [], className, onChange }) {
  //filter the input value from the options
  console.log({ defaultValue })
  const filterOptions = async (value) => {
    try {
      const inputValue = value.split(' ').join('').toLowerCase()
      if (options.length) {
        const filteredOptions = options.filter(data => {
          const str = data.label.split(' ').join('').toLowerCase()
          return str.includes(inputValue)
        })
        return filteredOptions
      } else {
        const { data } = await axios.get(endpoint)
        const filteredOptions = data.data
          .filter(({ name }) => name.split(' ').join('').toLowerCase().includes(inputValue))
          .map(data => {
            if (endpoint === 'users') {
              return ({ label: `${data.name}`, value: data.id })
            } else if (endpoint === 'products') {
              return ({ label: data.name, value: data.id, price: data.price, thumb: data.files[0]?.url })
            } else {
              return ({ label: data.name, value: data.id })
            }
          })
        return filteredOptions
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
      return []
    }
  }

  const loadOptions = async (inputValue, callback) => {
    const options = await filterOptions(inputValue)
    callback(options)
  }

  return (
    <AsyncSelect
      className={className}
      cacheOptions
      defaultValue={defaultValue}
      defaultOptions={options || []}
      loadOptions={loadOptions}
      onChange={onChange}
      // isSearchable={false}
    />
  )
}