import React from 'react';
import {
  Minus as MinusIcon,
  Plus as PlusIcon,
} from 'react-feather';
import { connectHits } from 'react-instantsearch-dom';
import { isEmpty } from 'underscore';

const Hits = ({ hits, onChange, onCheck }) => {
  return (
    <ul className='list-unstyled media-list tx-12 tx-sm-13 mg-b-5'>
      {
        hits.map((hit, index) => (

          <li className='media bg-ui-01 pd-y-10 pd-x-15 mg-t-1' key={index}>
            <div className='avatar'>
              {
                !isEmpty(hit.files) && (
                  <img
                    src={`${process.env.REACT_APP_IMAGE_API_URL}${hit.files[0].url}_100.jpg`}
                    className='rounded-circle'
                    alt=''
                  />
                )
              }
            </div>
            <div className='media-body mg-l-15'>
              <h6 className='tx-13 mg-b-2'>{hit.name}</h6>
              <span className='d-block tx-color-02'>{hit.price} ks</span>
            </div>
            <a
              type='button'
              className='btn btn-white rounded-circle btn-icon mg-l-15'
              onClick={() => onChange(hit)}
            >
              {
                onCheck(hit.id) ? (
                  <MinusIcon />
                ) : (
                    <PlusIcon />
                  )
              }
            </a>
          </li>
        )
        )}
    </ul>
  )
}

const ProductHits = connectHits(Hits);
export default ProductHits;