import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import Cleave from 'cleave.js/react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { CheckSquare as CheckSquareIcon, Trash2 as DeleteIcon } from 'react-feather'
import toast from 'react-hot-toast'
import AsyncSingleSelect from '../../common/AsyncSingleSelect'
import SingleSelect from '../../common/SingleSelect'
import LoadingSpinner from '../../components/LoadingSpinner'
import { discountTypes as types } from '../../data/types'
import getErrorMessage from '../../utils/getErrorMessage'

export default function PromotionForm({ editForm, id }) {
  const [isLoading, setIsLoading] = React.useState(true)
  const [products, setProducts] = React.useState([
    {
      product_id: '',
      type: 'fixed_amount',
      discount_value: '',
      start_date: moment(new Date()).format('YYYY-MM-DD'),
      start_time: '',
      end_date: moment(new Date()).format('YYYY-MM-DD'),
      end_time: '',
    },
  ])
  console.log({ products })

  const onSubmit = async () => {
    try {
      const temp = editForm ? products[0] : products.slice(0, products.length - 1)
      const method = editForm ? 'PUT' : 'POST'
      const url = editForm ? `promotions/${id}` : `promotions`
      const body = editForm ? {
        product_id: temp.product_id,
        type: temp.type,
        discount_value: temp.discount_value,
        start_at: temp.start_date.concat(` ${temp.start_time}`),
        end_at: temp.end_date.concat(` ${temp.end_time}`),
        is_active: temp.is_active,
      } : temp.map(
        (product) => ({
          product_id: product.product_id,
          type: product.type,
          discount_value: product.discount_value,
          start_at: product.start_date.concat(` ${product.start_time}`),
          end_at: product.end_date.concat(` ${product.end_time}`),
          is_active: true,
        })
      );

      const { data } = await axios({
        method,
        url,
        data: body,
      })
      if (data.success) {
        navigate('/promotions')
        toast.success('Success')
      }
    } catch (err) {
      const error = getErrorMessage(err)
      toast.error(error)
    }
  }

  const onSelectAll = (name, value) => {
    const temp = [...products]
    setProducts(
      temp.map(
        (product) => ({
          ...product,
          [name]: value,
        })
      )
    )
  }

  const onDelete = async (id) => {
    try {
      // console.log({id})
      // const temp = products.filter(
      //   (product) => product.product_id !== id
      // )
      // setProducts(temp)
    } catch (err) {
      const error = getErrorMessage(err)
      toast.error(error)
    }
  }

  const fetchPromotion = async () => {
    try {
      const { data } = await axios.get(`promotions/${id}`)
      console.log('promotion detail', data)
      if (data.success) {
        const promotion = data.data
        setProducts([{
          product_id: promotion.product.id,
          defaultValue: {
            label: promotion.product.name,
            value: promotion.product.id,
          },
          type: promotion.type,
          discount_value: promotion.discount_value,
          start_date: moment(promotion.start_at.split(' ')[0]).format('YYYY-MM-DD'),
          start_time: promotion.start_at.split(' ')[1],
          end_date: moment(promotion.end_at.split(' ')[0]).format('YYYY-MM-DD'),
          end_time: promotion.end_at.split(' ')[1],
          is_active: Boolean(promotion.is_active),
        }])
      }
    } catch (err) {
      const error = getErrorMessage(err)
      toast.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChange = (name, value, index) => {
    const temp = [...products]
    temp[index][name] = value
    setProducts(temp)
    if (!editForm && name === 'product_id') {
      if (index === products.length - 1) {
        setProducts([
          ...temp,
          {
            product_id: '',
            type: 'fixed_amount',
            discount_value: '',
            start_date: moment(new Date()).format('YYYY-MM-DD'),
            start_time: '',
            end_date: moment(new Date()).format('YYYY-MM-DD'),
            end_time: '',
          },
        ])
      }
    }
  }

  React.useEffect(() => {
    editForm && fetchPromotion()
  }, [])

  if (editForm && isLoading) {
    return (
      <LoadingSpinner />
    )
  }

  return (
    <div className='table-responsive'>
      <table className='table table-dashboard'>
        <thead>
          <tr>
            <th scope='col'>Product Name</th>
            <th scope='col'>Type</th>
            <th scope='col'>Discount Value</th>
            <th scope='col'>Start Date</th>
            <th scope='col'>End Date</th>
            <th scope='col'></th>
          </tr>
        </thead>
        <tbody>
          {
            products.map(
              (product, index) => (
                <tr key={index} className='wd-100p'>
                  <td className='pd-y-10 wd-25p'>
                    <AsyncSingleSelect
                      defaultValue={product.defaultValue}
                      endpoint='products'
                      onChange={(value) => onChange('product_id', value.value, index)}
                    />
                  </td>
                  <td className='pd-y-10 wd-15p'>
                    <SingleSelect
                      label='Type'
                      data={types}
                      value={product.type}
                      onChange={(type) => onChange('type', type, index)}
                    />
                    <div>
                      <a
                        className='cursor-pointer'
                        onClick={() => onSelectAll('type', product.type)}
                      >
                        <span className='tx-primary tx-10 pd-l-10'>
                          SELECT ALL
                        </span>
                      </a>
                    </div>
                  </td>
                  <td className='pd-y-10 wd-5p'>
                    <input
                      type='number'
                      placeholder='200'
                      className='form-control'
                      value={product.discount_value}
                      onChange={(e) => onChange('discount_value', e.target.value, index)}
                    />
                    <a
                      className='cursor-pointer'
                      onClick={() => onSelectAll('discount_value', product.discount_value)}
                    >
                      <span className='tx-primary tx-10 pd-l-10'>
                        SELECT ALL
                      </span>
                    </a>
                  </td>
                  <td className='pd-y-10' >
                    <div className='d-flex'>
                      <div className='wd-60p'>
                        <DatePicker
                          className='form-control date'
                          selected={new Date(product.start_date)}
                          onChange={(date) => onChange('start_date', moment(date).format('YYYY-MM-DD'), index)}
                        />
                        <div>
                          <a
                            className='cursor-pointer'
                            onClick={() => onSelectAll('start_date', product.start_date)}
                          >
                            <span className='tx-primary tx-10 pd-l-10'>
                              SELECT ALL
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='wd-30p'>
                        <Cleave
                          options={{
                            time: true,
                            timePattern: ['h', 'm']
                          }}
                          placeholder='09:00'
                          className='form-control time'
                          value={product.start_time}
                          onChange={(e) => onChange('start_time', e.target.value, index)}
                        />
                        <a
                          className='cursor-pointer'
                          onClick={() => onSelectAll('start_time', product.start_time)}
                        >
                          <span className='tx-primary tx-10 pd-l-10'>
                            SELECT ALL
                          </span>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td className='pd-y-10' >
                    <div className='d-flex'>
                      <div className='wd-60p'>
                        <DatePicker
                          className='form-control date'
                          selected={new Date(product.end_date)}
                          onChange={(date) => onChange('end_date', moment(date).format('YYYY-MM-DD'), index)}
                        />
                        <div>
                          <a
                            className='cursor-pointer'
                            onClick={() => onSelectAll('end_date', product.end_date)}
                          >
                            <span className='tx-primary tx-10 pd-l-10'>
                              SELECT ALL
                          </span>
                          </a>
                        </div>
                      </div>
                      <div className='wd-30p'>
                        <Cleave
                          options={{
                            time: true,
                            timePattern: ['h', 'm']
                          }}
                          placeholder='17:00'
                          className='form-control time'
                          value={product.end_time}
                          onChange={(e) => onChange('end_time', e.target.value, index)}
                        />
                        <a
                          className='cursor-pointer'
                          onClick={() => onSelectAll('end_time', product.end_time)}
                        >
                          <span className='tx-primary tx-10 pd-l-10'>
                            SELECT ALL
                          </span>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td className='pd-y-10 wd-5p' >
                    <button
                      type='button'
                      tabIndex={-1}
                      className='btn btn-icon btn-danger'
                      disabled={!product.product_id}
                      onClick={() => onDelete(product.product_id)}
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              )
            )
          }
          <tr>
            <td colSpan='6'>
              <button
                type='submit'
                className='btn btn-primary btn-block mg-b-10'
                onClick={onSubmit}
              >
                Submit
          </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
