import React from 'react'
import { navigate } from '@reach/router'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import moment from 'moment'
import toast from 'react-hot-toast'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import getErrorMessage from '../../utils/getErrorMessage'
import { Calendar as CalendarIcon} from 'react-feather'

export default function DeliveryDatesForm({ editForm, id }) {
  const [deliveryDates, setDeliveryDates] = React.useState([new Date(), new Date()])
  const [deliveryLimit, setDeliveryLimit] = React.useState()

  const onSubmit = async ({
    deliveryDates,
    deliveryLimit,
  }) => {
    try {
      const { data } = await axios({
        method: 'POST',
        url: 'deliverydates',
        data: {
          delivery_dates: {
            start: moment(deliveryDates[0]).format('YYYY-MM-DD'),
            end: moment(deliveryDates[1]).format('YYYY-MM-DD')
          },
          delivery_limit: deliveryLimit,
        }
      })
      console.log('deliverydates response', data)
      if(data.success){
        toast.success('Success')
        navigate('/delivery-dates')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  return (
    <Container>
      <Formik
        initialValues={{
          deliveryDates,
          deliveryLimit,
        }}
        onSubmit={(values) => {
          console.log('formik values', values)
          onSubmit(values)
        }}
      >
        {
          ({ values, handleChange, setFieldValue, isSubmitting }) => (
            <Form>
              <div className='form-group row'>
                <div className='col-md-3 text-md-right'>
                  <label className='col-form-label'>
                    <span className='tx-danger'>* </span>Date Range
                  </label>
                </div>
                <div className='col-md-9'>
                  <DateRangePicker
                    name='deliveryDates'
                    value={values.deliveryDates}
                    onChange={(dateRange) => setFieldValue('deliveryDates', dateRange)}
                    calendarIcon={<CalendarIcon color='#1b2e4b'/>}
                  />
                  <ErrorComponent>
                    <ErrorMessage name='deliveryDates' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-3 text-md-right'>
                  <label className='col-form-label'>
                    Delivery Limit
                  </label>
                </div>
                <div className='col-2'>
                  <Field
                    name='deliveryLimit'
                    type='number'
                    className='form-control'
                    placeholder='100'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-4 offset-md-3'>
                  <button className='btn btn-primary btn-block'>
                    {
                      isSubmitting ? 'Submitting' : 'Submit'
                    }
                  </button>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </Container >
  )
}
