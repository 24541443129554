import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import slugify from 'slugify'
import * as Yup from 'yup'
import SingleSelect from '../../common/SingleSelect'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'

export default function BrandForm({ editForm, id }) {
  const [name, setName] = React.useState('')
  const [mmName, setMmName] = React.useState('')
  const [supplierId, setSupplierId] = React.useState('')
  const [slug, setSlug] = React.useState('')
  const [isFeatured, setIsFeatured] = React.useState(false)
  const [isActive, setIsActive] = React.useState(true)
  const [order, setOrder] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(true)

  const BrandSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required'),
    mmName: Yup.string()
      .required('Myanmar Name is required'),
    slug: Yup.string()
      .required('Slug is required'),
  })

  const onSubmit = async ({
    name,
    mmName,
    supplierId,
    slug,
    isFeatured,
    isActive,
    order
  }) => {
    try {
      setIsLoading(true)
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `brands/${id}` : `brands`
      const body = {
        name,
        mm_name: mmName,
        supplier_id: supplierId,
        slug,
        order,
        is_featured: isFeatured,
        is_active: isActive
      }
      console.log('api body', { ...body, order })
      const { data } = await axios({
        method,
        url: endpoint,
        data: body,
      })
      if (data.success) {
        toast.success('Success')
        navigate('/brands')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchBrand = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`brands/${id}`)
      if (data.success) {
        const brand = data.data
        setName(brand.name)
        setMmName(brand.mm_name)
        setSupplierId(brand.supplier_id)
        setSlug(brand.slug)
        setIsActive(Boolean(brand.is_active))
        setIsFeatured(Boolean(brand.is_featured))
        setOrder(brand.order)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    editForm && fetchBrand()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Formik
        initialValues={{
          name,
          mmName,
          supplierId,
          slug,
          isFeatured,
          isActive,
          order
        }}
        validationSchema={BrandSchema}
        onSubmit={(values) => {
          console.log('formik values', values)
          onSubmit(values)
        }}
      >
        {
          ({ values, setFieldValue, isSubmitting, handleChange, handleBlur }) => (
            <Container>
              <Form>
                <div className='form-group row'>
                  <label className='col-md-3 tx-md-right col-form-label'>
                    <span className='tx-danger'>* </span>Name
                    </label>
                  <div className='col-md-9'>
                    <Field
                      name='name'
                      type='text'
                      className='form-control'
                      placeholder='E-LAN'
                      onChange={(e) => {
                        setFieldValue('name', e.target.value)
                        setFieldValue('slug', slugify(e.target.value).toLowerCase())
                      }}
                      autoFocus
                    />
                    <ErrorComponent>
                      <ErrorMessage name='name' />
                    </ErrorComponent>
                  </div>
                </div>

                <div className='form-group row'>
                  <label className='col-md-3 tx-md-right col-form-label'>
                    <span className='tx-danger'>* </span>Slug
                  </label>
                  <div className='col-md-9'>
                    <Field
                      name='slug'
                      type='text'
                      className='form-control'
                      placeholder='e-lan'
                      onChange={(e) => setFieldValue('slug', e.target.value)}
                    />
                    <ErrorComponent>
                      <ErrorMessage name='slug' />
                    </ErrorComponent>
                  </div>
                </div>

                <div className='form-group row'>
                  <label className='col-md-3 tx-md-right col-form-label'>
                    <span className='tx-danger'>* </span>Myanmar Name
                  </label>
                  <div className='col-md-9'>
                    <Field
                      name='mmName'
                      type='text'
                      className='form-control'
                      placeholder='အီလန်'
                    />
                    <ErrorComponent>
                      <ErrorMessage name='mmName' />
                    </ErrorComponent>
                  </div>
                </div>

                <div className='form-group row '>
                  <label className='col-md-3 tx-md-right col-form-label'>Supplier</label>
                  <div className='col-md-9'>
                    <SingleSelect
                      name='supplierId'
                      label='Supplier'
                      endpoint='suppliers'
                      value={values.supplierId}
                      onChange={supplierId => setFieldValue('supplierId', supplierId)}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                {editForm && (
                  <div className='form-group row'>
                    <label className='col-md-3 tx-md-right col-form-label'>Order</label>
                    <div className='col-md-9'>
                      <Field
                        name='order'
                        type='number'
                        className='form-control'
                      />
                    </div>
                  </div>
                )}

                <div className='form-group row'>
                  <div className='offset-md-3 col-md-9'>
                    <div className='custom-switch custom-control'>
                      <input
                        name='isActive'
                        type='checkbox'
                        id='isActive'
                        className='custom-control-input'
                        value={values.isActive}
                        checked={values.isActive}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor='isActive' className='custom-control-label'>Active</label>
                    </div>
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-md-9 offset-md-3'>
                    <div className='custom-switch custom-control'>
                      <input
                        name='isFeatured'
                        type='checkbox'
                        id='isFeatured'
                        className='custom-control-input'
                        value={values.isFeatured}
                        checked={values.isFeatured}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor='isFeatured' className='custom-control-label'> Featured</label>
                    </div>
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-md-9 offset-md-3'>
                    <button type='submit' className='btn btn-primary btn-block'>
                      {
                        isSubmitting ? 'Submitting' : 'Submit'
                      }
                    </button>
                  </div>
                </div>

              </Form>
            </Container>
          )
        }
      </Formik>
    </>
  )
}