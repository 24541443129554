import React from 'react'
import { useParams } from '@reach/router'
import Card from '../../components/Card'
import SupplierForm from './SupplierForm'

export default function EditSupplierPage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Supplier'
        description='suppliers'
        href='/suppliers'
      />
      <SupplierForm editForm id={id} />
    </Card>
  )
}