import React from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { navigate } from '@reach/router'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import getErrorMessage from '../../utils/getErrorMessage'
import getUrlParameter from '../../utils/getUrlParameter'
import axios from 'axios'

export default function SmsForm() {
  const duplicateContent = getUrlParameter('content')
  const [phones, setPhones] = React.useState([])
  const [message, setMessage] = React.useState(duplicateContent)
  const [isLoading, setIsLoading] = React.useState(true)
  const [count, setCount] = React.useState(0)

  const messageSchema = Yup.object({
    phones: Yup.string()
      .required('Phones Required'),
    message: Yup.string()
      .required('Message Required')
  })

  const onSubmit = async ({ phones, message }) => {
    try {
      const { data } = await axios({
        method: 'POST',
        url:'sms',
        data: {
          phones: phones.split(','),
          message
        }
      })
      console.log('sms res: ', data)
      if (data.success) {
        toast.success('Scheduled to send')
        navigate('/sms')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  return (
    <Formik
      initialValues={{
        phones,
        message
      }}
      validationSchema={messageSchema}
      onSubmit={(values) => {
        console.log('formik values', values)
        onSubmit(values)
      }}
    >
      {
        ({ isSubmitting, setFieldValue }) => (
          <Container>
            <Form>
              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Phones
                </label>
                <div className='col-md-9'>
                  <Field
                    type='text'
                    name='phones'
                    className='form-control'
                    placeholder='09123456789,09987654321'
                    autoFocus
                  />
                  <ErrorComponent>
                    <ErrorMessage name='phones' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Messages
                </label>
                <div className='col-md-9'>
                  <Field
                    as='textarea'
                    name='message'
                    className='form-control'
                    placeholder='လူကြီးမင်း၏ အော်ဒါကို လက်ခံရရှိပါသည်။'
                    onChange={(e) => {
                      setFieldValue('message', e.target.value)
                      setCount(e.target.value.length)
                    }}
                  />
                  {
                    Math.floor(count / 160) > 0 && <p className='float-right'>{`(${Math.floor(count / 160)})`}</p>
                  }
                  {
                    Math.floor(count / 160) > 0 && <p className='float-right'>{160 * (Math.floor(count / 160) + 1) - count}</p>
                  }
                  <ErrorComponent>
                    <ErrorMessage name='message' />
                  </ErrorComponent>
                </div>

              </div>
              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <button className='btn btn-primary btn-block btn-uppercase'>
                    {isSubmitting ? 'Sending' : 'Send'}
                  </button>
                </div>
              </div>
            </Form>
          </Container>
        )
      }
    </Formik>
  )
}