import React from 'react'
import { connectPagination } from 'react-instantsearch-dom';

const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
  <ul className='pagination pagination-circle'>
    <li class='page-item disabled'><a class='page-link page-link-icon' href='#'><i data-feather='chevron-left'></i></a></li>
    {new Array(nbPages).fill(null).map((_, index) => {
      const page = index + 1;
      const style = {
        fontWeight: currentRefinement === page ? 'bold' : '',
      };

      return (
        <li key={index} className='page-item'>
          <a
            href={createURL(page)}
            style={style}
            onClick={event => {
              event.preventDefault();
              refine(page);
            }}
            className='page-link'
          >
            {page}
          </a>
        </li>
      );
    })}
    <li class='page-item'><a class='page-link page-link-icon' href='#'><i data-feather='chevron-right'></i></a></li>
  </ul>
);

const AlgoliaPagination = connectPagination(Pagination)
export default AlgoliaPagination