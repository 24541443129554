import React from 'react'
import Card from '../../components/Card'
import WarehouseForm from './WarehouseForm'

export default function AddWarehousePage() {
  return (
    <Card>
      <Card.Header
        title='Add New Warehouse'
        description='warehouses'
        href='/warehouses'
      />
      <WarehouseForm />
    </Card>
  )
}