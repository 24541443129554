import React from 'react'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from 'react-feather'

export default function Pagination({
  page,
  lastPage,
  onChangeFilter,
}) {
  
  let pages = [];
  for (let i = 1; i <= lastPage; i++) {
    pages.push(i)
  }

  return (
    <>
      {
        lastPage > 1 && (
          <ul className='pagination pagination-circle justify-content-center mg-t-5'>
            <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
              <a
                className='page-link page-link-icon'
                onClick={() => { onChangeFilter('page', page - 1) }}
              >
                <ChevronLeftIcon />
              </a>
            </li>
            {
              pages.map(
                (item, index) => (
                  <li className={`page-item ${item === page ? 'active' : ''}`} key={index}>
                    <a
                      className='page-link'
                      onClick={() => { onChangeFilter('page', item) }}
                    >
                      {item}
                    </a>
                  </li>
                )
              )
            }
            <li className={`page-item ${page === lastPage ? 'disabled' : ''}`}>
              <a
                className='page-link page-link-icon'
                onClick={() => { onChangeFilter('page', page + 1) }}
              >
                <ChevronRightIcon />
              </a>
            </li>
          </ul>
        )
      }
    </>
  )
}
