import React from 'react';
import { navigate } from '@reach/router';
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import AsyncSingleSelect from '../../common/AsyncSingleSelect';
import SingleSelect from '../../common/SingleSelect';
import Container from '../../components/Container';
import ErrorComponent from '../../components/ErrorComponent';
import getErrorMessage from '../../utils/getErrorMessage';

export default function EntriesForm() {
  const [entryType, setEntryType] = React.useState('purchase')
  const [date, setDate] = React.useState(new Date())
  const [note, setNote] = React.useState('')
  const [warehouseId, setWarehouseId] = React.useState()
  const [voucher, setVoucher] = React.useState()
  const [entries, setEntries] = React.useState([
    {
      id: '',
      quantity: 0,
      rate: ''
    },
  ])
  const [isLoading, setIsLoading] = React.useState(true)

  const entrySchema = Yup.object({
    entryType: Yup.string()
      .required('Entry Type Required'),
    entries: Yup.string()
      .required('Entries Required'),
    warehouseId: Yup.string()
      .required('Warehouse Required'),
    voucher: Yup.string()
      .required('Voucher Required')
  })

  const entryTypes = [
    { label: 'Purchase', value: 'purchase' },
    { label: 'Sale', value: 'sale' },
    { label: 'Waste', value: 'waste' },
    { label: 'Sale Return', value: 'sale_return' },
    { label: 'Purchase Return', value: 'purchase_return' },
    { label: 'Purchase FOC', value: 'purchase_foc' },
    { label: 'Sale FOC', value: 'sale_foc' }
  ]

  const onChangeProduct = (product, index) => {
    let temp = [...entries]
    temp[index].id = product.value
    if (index + 1 === temp.length) {
      temp = [...temp, { id: '', quantity: 0, rate: 0 }]
    }
    setEntries(temp)
  }

  const onChangeQuantity = (value, index) => {
    const temp = [...entries]
    temp[index].quantity = value
    setEntries(temp)
  }

  const onChangeRate = (value, index) => {
    const temp = [...entries]
    temp[index].rate = value
    setEntries(temp)
  }

  const onSubmit = async ({
    entryType,
    date,
    voucher,
    note,
    warehouseId
  }) => {
    try {
      const temp = entries.slice(0, entries.length - 1)
      const isAllValid = temp.every(entry => entry.id && entry.quantity && entry.rate)
      console.log('isAllValid : ', isAllValid)
      if (isAllValid) {
        const { data } = await axios({
          method: 'POST',
          url: 'inventories',
          data: {
            reason: entryType,
            note,
            date,
            voucher,
            products: temp,
            warehouse_id: warehouseId
          }
        })
        console.log('response inventory', data)
        if (data.success) {
          setIsLoading(false)
          toast.success('Success')
          navigate('/inventories/entries')
        }
      } else {
        toast.error('Data is not complete')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  return (
    <Container>
      <Formik
        initialValues={{
          entryType,
          date,
          voucher,
          entries,
          note,
          warehouseId

        }}
        validationSchema={entrySchema}
        onSubmit={values => {
          console.log('formik values', values)
          onSubmit(values)
        }}

      >
        {
          ({ values, isSubmitting, setFieldValue, handleChange, handleBlur }) => (
            <Form>
              <div className='form-group row'>
                <div className='col-12'>
                  <label >Date</label>
                </div>
                <div className='col-12'>
                  <DatePicker
                    name='date'
                    selected={values.date}
                    className='form-control'
                    onChange={date => {
                      console.log('date is', date)
                      setFieldValue('date', date)
                    }}
                  // showTimeSelect
                  // dateFormat='Pp'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-12'>
                  <label>Entry Type</label>
                </div>
                <div className='col-6'>
                  <AsyncSingleSelect
                    name='entryType'
                    options={entryTypes}
                    onChange={(entry) => setFieldValue('entryType', entry.value)}
                  />
                  <ErrorComponent>
                    <ErrorMessage name='entryType' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-12'>
                  <label>Warehouse</label>
                </div>
                <div className='col-6'>
                  <SingleSelect
                    name='warehouseId'
                    label='Warehouse'
                    endpoint='warehouses'
                    value={values.warehouseId}
                    onChange={warehouseId => setFieldValue('warehouseId', warehouseId)}
                    onBlur={handleBlur}
                  />
                  <ErrorComponent>
                    <ErrorMessage name='warehouseId' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-12'>
                  <label>Voucher</label>
                </div>
                <div className='col-6'>
                  <Field
                    name='voucher'
                    type='text'
                    className='form-control'
                    placeholder='zxc1%o3ip'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='voucher' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-12'>
                  <label>Note</label>
                </div>
                <div className='col-12'>
                  <Field
                    name='note'
                    as='textarea'
                    className='form-control'
                    placeholder='Noted By Zin'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-12'>
                  {
                    entries.map((entry, index) => (
                      <div className='row' key={index}>
                        <div className='col-md-6'>
                          <label>Choose Product</label>
                          <AsyncSingleSelect
                            endpoint='products'
                            onChange={(value) => onChangeProduct(value, index)}
                            className='mg-b-20'
                          />
                        </div>
                        <div className='form-group col-md-3'>
                          <label>Quantity</label>
                          <input
                            type='number'
                            className='form-control'
                            placeholder='20'
                            onChange={(e) => onChangeQuantity(e.target.value, index)}
                          />
                        </div>
                        <div className='form-group col-md-3'>
                          <label>Rate</label>
                          <input
                            type='number'
                            className='form-control'
                            placeholder='100'
                            onChange={(e) => onChangeRate(e.target.value, index)}
                          />
                        </div>
                        <ErrorComponent>
                          <ErrorMessage name='entries' />
                        </ErrorComponent>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-12'>
                  <button type='submit' className='btn btn-primary btn-block btn-uppercase'>
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </button>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </Container>
  )
}