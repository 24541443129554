import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Edit2 as EditIcon,
  Plus as PlusIcon,
  Trash2 as DeleteIcon
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import LoadingSpinner from '../../components/LoadingSpinner'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import getErrorMessage from '../../utils/getErrorMessage'

export default function UsersPage() {
  const [users, setUsers] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  function getColorByStatus(status) {
    switch (status) {
      case 'unverified': return 'warning'
      case 'approved': return 'success'
      case 'banned': return 'danger'
    }
  }

  const fetchUsers = async () => {
    try {
      const { data } = await axios.get('users')
      if (data.success) {
        setUsers(data.data)
        setRows(
          data.data.map((data) => ({
            id: data.id,
            name: data.name,
            roles: data.roles,
            status: data.status,
            phone: data.phone,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteUser = async (id, index) => {
    try {
      const { data } = await axios.delete(`users/${id}`)
      if (data.success) {
        let temp = [...users]
        temp.splice(index, 1)
        setUsers(temp)
        setRows(
          temp.map((user) => ({
            id: user.id,
            phone: user.phone,
            roles: user.roles,
            name: user.name,
            status: user.status,
          }))
        )
        toast.success(
          <UndoAction
            onClick={() => restoreUser(id, index)}
            name={users[index].name}
          />,
          0,
          4000
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const restoreUser = async (id, index) => {
    try {
      const { data } = await axios.post(`users/${id}/restore`)
      console.log('restored user', data.data)
      if (data.success) {
        let temp = [...users]
        temp[index] = data.data
        setRows(
          temp.map((user) => ({
            id: user.id,
            phone: user.phone,
            roles: user.roles,
            name: user.name,
            status: user.status,
          }))
        )
        toast.remove()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    fetchUsers()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Users'
        description='Members in Zay Chin'
        tableStats={[{ label: 'Total Users', value: users.length }]}
        btns={[
          {
            title: 'Add new user',
            icon: PlusIcon,
            href: 'users/add',
            variant: 'primary',
          },
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Name',
            key: 'name',
          },
          {
            title: 'Phone',
            key: 'phone',
          },
          {
            title: 'Roles',
            render: {
              type: 'array',
              key: 'roles',
              className: 'badge badge-secondary rounded-pill mg-1'
            }
          },
          {
            title: 'Status',
            width: '100px',
            render: (id, index) => {
              return (
                <span className={`badge badge-${getColorByStatus(users[index]?.status)} rounded-pill`} >
                  {users[index]?.status}
                </span>
              )
            },
          },
          {
            title: 'Actions',
            width: '10px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id) => navigate(`users/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteUser
                }
              ]
            },
          },
        ]}
      />
    </Card>
  )
}
