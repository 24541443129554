import React from 'react'
import { navigate } from '@reach/router'
import toast from 'react-hot-toast'
import Table from '../../components/Table'
import getErrorMessage from '../../utils/getErrorMessage'
import Card from '../../components/Card'
import {
  Clipboard as ClipboardIcon,
  Copy as CopyIcon,
  Send as SendIcon,
  Plus as PlusIcon,
} from 'react-feather'
import axios from 'axios'

export default function NotificationsPage() {
  const [notifications, setNotifications] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [duplicatedBody, setDuplicatedBody] = React.useState('')

  const fetchNotifications = async () => {
    try {
      const { data } = await axios.get('notifications')
      if (data.success) {
        setNotifications(data.data)
        setRows(
          data.data.map(
            (data) => (
              {
                id: data.id,
                name: data.title,
                body: data.body,
                priority: data.priority,
                isRead: data.is_read,
              }
            )))
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onDuplicateBody = (id, index) => {
    setDuplicatedBody(notifications[index].body)
    toast.success('Duplicated!')
  }

  React.useEffect(() => {
    fetchNotifications()
  }, [])

  return (
    <Card>
      <Card.Header
        title='Notifications'
        description='notifications'
        btns={[
          {
            title: 'Add new notification',
            icon: PlusIcon,
            href: `/notifications/add?body=${duplicatedBody}`,
            variant: 'primary'
          }
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            width: '50px',
            key: 'id',
          },
          {
            title: 'Title',
            key: 'title',
          },
          {
            title: 'Body',
            key: 'body',
          },
          {
            title: 'Priority',
            width: '100px',
            key: 'priority',
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: CopyIcon,
                  variant: 'btn-info',
                  onClick: onDuplicateBody,
                },
                {
                  icon: SendIcon,
                  variant: 'btn-danger',
                  // onClick: resendSms,
                }
              ]
            },
          },
        ]}
      />
    </Card>
  )
}