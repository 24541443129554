import { useParams } from '@reach/router'
import React from 'react'
import PageHeader from '../../components/PageHeader'
import EntriesForm from './EntriesForm'

export default function EditEntriesPage() {
  const { id } = useParams()

  return (
    <>
      <PageHeader
        title='Edit Entry'
        breadcrumbs={['inventories/entries']}
        btns={[
          {
            title: 'Go Back to Entries',
            icon: 'ion-arrow-left',
            href: `/inventories/entries`,
            variant: 'white'
          }
        ]}
      />
      <EntriesForm editForm id={id} />
    </>
  )
}