import { useParams } from '@reach/router'
import React from 'react'
import Card from '../../components/Card'
import CityForm from './CityForm'

export default function EditRegionPage(props) {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit City'
        description='cities'
        href='/cities'
      />
      <CityForm editForm id={id} />
    </Card>

  )
}