import React from 'react'
import { Menu as MenuIcon } from 'react-feather'
import {
  setThousandWithComma,
  setThousandWithKilo,
} from '../utils/formatThousands'

export default function Cart({
  cart,
  index,
  onCreateOrder,
  onSendNotification,
  onDelete ,
}) {

    return (
    <>
      {cart.cart_items.length > 0 && (
        <div className='card'>
          <div className='card-header d-flex justify-content-between bd-b-0 pd-b-0 pd-r-10'>
            <div>
              <h6 className='cart-title'>
                {cart.user ? cart.user.name : 'Unregistered'}
              </h6>
              <ul className='list-inline d-flex'>
                <li className='list-inline-item d-flex align-items-center mg-r-20'>
                  <span className='d-block wd-10 ht-10 bg-df-2 rounded mg-r-5'></span>
                  <span className='tx-sans tx-uppercase tx-10 tx-medium tx-color-03'>
                    {cart.no_of_order}
                  </span>
                </li>
                <li className='list-inline-item d-flex align-items-center'>
                  <span className='d-block wd-10 ht-10 bg-df-1 rounded mg-r-5'></span>
                  <span className='tx-sans tx-uppercase tx-10 tx-medium tx-color-03'>
                    {setThousandWithComma(cart.total)}
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <div className='dropdown'>
                <button
                  className='btn btn-icon link-03'
                  type='button'
                  data-toggle='dropdown'
                  aria-expanded={false}
                >
                  <MenuIcon />
                </button>
                <div className='dropdown-menu'>
                  <ul className='list-unstyled list-group list-group-flush'>
                    <li
                      className='list-group-item tx-success wd-250'
                      onClick={() => {
                        onCreateOrder(cart.user ? cart.user.id : '', cart.id)
                      }}
                    >
                      Create Order From This Cart
                    </li>
                    <li
                      className='list-group-item'
                      onClick={() => {
                        onSendNotification(cart.id, cart.client_id)
                      }}
                    >
                      Send Notification
                    </li>
                    <li
                      className='list-group-item tx-danger'
                      onClick={() => onDelete(cart.id, index)}
                    >
                      Delete
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='table-responsive pos-relative'>
            <table className='table table-dashboard mg-b-0'>
              <thead>
                <tr>
                  <th scope='col'>Item</th>
                  <th scope='col'>Quantity</th>
                  <th scope='col' className='tx-right'>
                    Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {cart.cart_items.map((item, index) => (
                  <tr key={index}>
                    <td width='300px'>{item.product?.name}</td>
                    <td width='100px'>x {item.quantity}</td>
                    <td width='100px' className='tx-right'>
                      {setThousandWithKilo(item.product.price)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={3} className='tx-right'>
                    {setThousandWithComma(cart.total)} ks
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}
