import { useParams } from '@reach/router'
import React from 'react'
import Card from '../../components/Card'
import BrandForm from './BrandForm'

export default function EditBrandPage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Brand'
        description='brands'
        href='/brands'
      />
      <BrandForm editForm id={id} />
    </Card>
  )
}