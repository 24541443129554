import React from 'react';
import axios from 'axios';

const useFilter = (url, params, setLastPage) => {
  const [results, setResults] = React.useState([]);

  const fetch = async () => {
    try {
      const { data } = await axios({
        url,
        params
      });
      if (data.success) {
        setResults(data.data)
        setLastPage(data.pagination.last_page)
      }
    } catch (error) {
      setResults([]);
    }
  }

  React.useEffect(() => {
    fetch();
  }, [params])

  return results;
}
export default useFilter;