import React from 'react'
import { navigate } from '@reach/router'
import toast from 'react-hot-toast'
import LoadingSpinner from '../../components/LoadingSpinner'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'
import {
  Plus as PlusIcon,
  Edit2 as EditIcon,
  Trash2 as DeleteIcon,
} from 'react-feather'
import Card from '../../components/Card'
import axios from 'axios'

export default function BrandPage() {
  const [page, setPage] = React.useState(1)
  const [brands, setBrands] = React.useState([])
  const [activeBrands, setActiveBrands] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchBrands = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get('brands')
      if (data.success) {
        setBrands(data.data)
        setActiveBrands(filterActive(data.data))
        setRows(
          data.data.map((data) => ({
            id: data.id,
            name: data.name,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreBrand = async (id, index) => {
    try {
      const { data } = await axios({
        method: 'POST',
        url: `brands/${id}/restore`,
      })
      if (data.success) {
        let temp = [...brands]
        temp[index] = data.data
        setBrands(temp)
        toast.remove()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteBrand = async (id, index) => {
    try {
      const { data } = await axios({
        method: 'DELETE',
        url: `brands/${id}`,
      })
      console.log({data})
      if (data.success) {
        const temp = [...brands]
        temp.splice(index, 1)
        setBrands(temp)
        setRows(temp.map((data) => (
          {
            id: data.id,
            name: data.name,
          }
        )))
        toast.success(
          <UndoAction
            onClick={() => restoreBrand(id, index)}
            name={brands[index].name}
          />,
          0,
          4000
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    fetchBrands()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Brands'
        description='Brands'
        tableStats={[
          { label: 'total brands', value: brands.length },
          { label: 'active brands', value: activeBrands.length },
        ]}
        btns={[
          {
            title: 'Add new brand',
            icon: PlusIcon,
            href: 'brands/add',
            variant: 'primary',
          },
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Name',
            key: 'name',
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id)=> navigate(`brands/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteBrand,
                }
              ],
            },
          },
        ]}
      />
    </Card>
  )
}
