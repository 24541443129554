import React from 'react'
import { navigate } from '@reach/router'
import arrayMove from 'array-move'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import AlgoliaModal from '../../common/AlgoliaModal'
import MultiSelect from '../../common/MultiSelect'
import SingleSelect from '../../common/SingleSelect'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import FileUpload from '../../components/FileUpload'
import LoadingSpinner from '../../components/LoadingSpinner'
import SortableList from '../../components/SortableList'
import SortableProduct from '../../components/SortableProduct'
import { searchClient } from '../../data/searchClient'
import { types } from '../../data/types'
import getErrorMessage from '../../utils/getErrorMessage'
import { isEmpty } from 'underscore'

export default function CollectionForm({ editForm, id }) {
  const [name, setName] = React.useState('')
  const [mmName, setMmName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [mmDescription, setMmDescription] = React.useState('')
  const [type, setType] = React.useState('product')
  const [cover, setCover] = React.useState([])
  const [items, setItems] = React.useState('')
  const [products, setProducts] = React.useState([])
  const [isPublic, setIsPublic] = React.useState(false)
  const [isActive, setIsActive] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(true)
  const [showProductPicker, setShowProductPicker] = React.useState(false)

  const fetchCollection = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`collections/${id}`)
      console.log('collection datail', data)
      if (data.success) {
        const collection = data.data
        setName(collection.name)
        setMmName(collection.mm_name)
        setDescription(collection.desc)
        setMmDescription(collection.mm_desc)
        setType(collection.type)
        !isEmpty(collection.files) && setCover([{
          id: collection.files[0].id,
          url: `${process.env.REACT_APP_IMAGE_API_URL}${collection.files[0].url}_100.jpg`,
          status: 'success',
        }])
        setIsPublic(Boolean(collection.is_public))
        setIsActive(Boolean(collection.is_active))
        collection.type === 'product' ?
          setProducts(collection.items) :
          collection.type === 'single_category' ?
            setItems(collection.items[0].id) :
            setItems(collection.items.map(
              (item) => ({ label: item.name, value: item.id }))
            )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleProductsChange = (hit) => {
    const isProductExisted = products.find((product) => product.id === hit.id)
    if (isProductExisted) {
      setProducts(products.filter(
        (product) => product.id !== hit.id)
      )
    } else {
      setProducts((prev) => [...prev, hit])
    }
  }

  const collectionSchema = Yup.object({
    name: Yup.string()
      .required('Name Required'),
  })

  const onSubmit = async ({
    name,
    mmName,
    description,
    mmDescription,
    type,
    items,
    isPublic,
    isActive
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `collections/${id}` : `collections`
      const body = {
        name,
        mm_name: mmName,
        desc: description,
        mm_desc: mmDescription,
        type,
        items: type === 'product' ?
          (products.map(product => product.id)) :
          (type === 'single_category' ? [items] : items.map(item => item.value)),
        is_public: isPublic,
        is_active: isActive
      }
      console.log('body', body)
      const { data } = await axios({
        method,
        url: endpoint,
        data: body,
      })
      console.log('response', data)
      if (data.success) {
        toast.success('Success')
        navigate('/collections')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteProduct = (id) => {
    console.log('delete id', id)
    const remainingProducts = products.filter(product => product.id !== id)
    console.log('remaining products', remainingProducts)
    setProducts(remainingProducts)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log('oldindex', oldIndex)
    console.log('newindex', newIndex)
    setProducts(arrayMove(products, oldIndex, newIndex))
  };

  const isChecked = (hitId) => {
    return products.find((product) => product.id === hitId)
  }

  React.useEffect(() => {
    editForm && fetchCollection()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={{
        name,
        mmName,
        description,
        mmDescription,
        type,
        items,
        isPublic,
        isActive
      }}
      validationSchema={collectionSchema}
      onSubmit={(values) => {
        console.log('formik values', values)
        onSubmit(values)
      }}
    >
      {({ values, isSubmitting, setFieldValue, handleChange, handleBlur }) => (
        <Container >
          <Form>
            <div className='form-group row'>
              <label className='col-md-3 text-md-right col-form-label'>
                <span className='tx-danger'>* </span>Name
                </label>
              <Field
                name='name'
                type='text'
                placeholder='Weekly Promotion'
                className='form-control col-md-9'
                autoFocus
              />
              <ErrorComponent className='offset-md-3'>
                <ErrorMessage name='name' />
              </ErrorComponent>
            </div>

            <div className='form-group row'>
              <label className='col-md-3 text-md-right col-form-label'>
                Myanmar Name
                </label>
              <Field
                name='mmName'
                type='text'
                placeholder='Weekly Promotion'
                className='form-control col-md-9'
              />
              <ErrorComponent className='offset-md-3'>
                <ErrorMessage name='mmName' />
              </ErrorComponent>
            </div>

            <div className='form-group row'>
              <label className='col-md-3 text-md-right col-form-label'>
                Description
                </label>
              <Field
                name='description'
                as='textarea'
                placeholder='Collection Description'
                className='form-control col-md-9'
              />
              <ErrorComponent className='offset-md-3'>
                <ErrorMessage name='description' />
              </ErrorComponent>
            </div>

            <div className='form-group row'>
              <label className='col-md-3 text-md-right col-form-label'>
                Myanmar Description
                </label>
              <Field
                name='mmDescription'
                as='textarea'
                placeholder='Myanmar Description'
                className='form-control col-md-9'
              />
              <ErrorComponent className='offset-md-3'>
                <ErrorMessage name='mmDescription' />
              </ErrorComponent>
            </div>

            <div className='form-group row'>
              <label className='col-md-3 text-md-right col-form-label'>
                Type
              </label>
              <SingleSelect
                name='type'
                label='Types'
                value={values.type}
                data={types}
                onChange={type => {
                  setProducts([])
                  setFieldValue('type', type)
                }}
                onBlur={handleBlur}
                className='col-md-9 offset-md-3 mg-0'
              />
            </div>

            <div className='form-group row' zIndex={9999}>
              {
                values.type === 'product' &&
                <>
                  <button
                    type='button'
                    className='btn btn-light col-md-9 offset-md-3'
                    onClick={() => setShowProductPicker(true)}
                  >Pick Products
                  </button>
                  {
                    showProductPicker && (
                      <AlgoliaModal
                        id='modal'
                        searchClient={searchClient}
                        indexName='products_index'
                        onChange={handleProductsChange}
                        onCheck={isChecked}
                        onDismiss={() => setShowProductPicker(false)}
                      />
                    )
                  }
                </>
              }
              {
                values.type === 'single_category' &&
                <SingleSelect
                  name='items'
                  label='Single Category'
                  value={values.items}
                  endpoint='childCategories'
                  onChange={category => setFieldValue('items', category)}
                  onBlur={handleBlur}
                  className='col-md-9 offset-md-3'
                />
              }
              {
                values.type === 'multi_category' &&
                <MultiSelect
                  name='items'
                  endpoint='childCategories'
                  onChange={(categories) => setFieldValue('items', categories)}
                  defaultValue={values.items}
                  className='col-md-9 offset-md-3 pd-0'
                />
              }
              {
                values.type === 'collection' &&
                <MultiSelect
                  name='items'
                  endpoint='collections'
                  onChange={(collections) => setFieldValue('items', collections)}
                  defaultValue={values.items}
                  className='col-md-9 offset-md-3 pd-0'
                />
              }
            </div>

            <div className='form-group row'>
              <SortableList
                items={products}
                axis='y'
                onDelete={deleteProduct}
                onSortEnd={onSortEnd}
                renderItemComponent={SortableProduct}
                className='offset-md-3 col-md-9 pd-0'
              />
            </div>

            <div className='form-group row'>
              <label className='col-md-3 tx-md-right col-form-label'>
                Cover
              </label>
              <div className='col-md-9'>
                <FileUpload
                  name='cover'
                  id={id}
                  relatedType='collections'
                  multiple={false}
                  photos={cover}
                  setPhotos={setCover}
                />
              </div>
            </div>

            <div className=' form-group row offset-md-3'>
              <div className='custom-switch custom-control'>
                <input
                  name='isPublic'
                  type='checkbox'
                  id='isPublic'
                  className='custom-control-input'
                  value={values.isPublic}
                  checked={values.isPublic}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor='isPublic' className='custom-control-label'>
                  Public
                </label>
              </div>
            </div>

            <div className=' form-group row offset-md-3'>
              <div className='custom-switch custom-control'>
                <input
                  name='isActive'
                  type='checkbox'
                  id='isActive'
                  className='custom-control-input'
                  value={values.isActive}
                  checked={values.isActive}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor='isActive' className='custom-control-label'>
                  Active
                </label>
              </div>
            </div>

            <div className='form-group row'>
              <button className='btn btn-primary btn-block offset-md-3'>
                {
                  isSubmitting ? 'Submitting' : 'Submit'
                }
              </button>
            </div>

          </Form>
        </Container>
      )}
    </Formik>
  )
}