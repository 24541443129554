import React from 'react';
import { navigate } from '@reach/router';
import axios from 'axios';
import {
  Save as SaveIcon
} from 'react-feather';
import toast from 'react-hot-toast';
import { ReactSortable } from 'react-sortablejs';
import Card from '../../components/Card';
import Contain from '../../components/Container';
import LoadingSpinner from '../../components/LoadingSpinner';
import getErrorMessage from '../../utils/getErrorMessage';
const sortableOptions = {
  animation: 150,
  fallbackOnBody: true,
  swapThreshold: 0.65,
  ghostClass: 'ghost',
  group: 'shared'
};

export default function ArrangeCategoriesPage() {
  const [categories, setCategories] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchCategories = async () => {
    try {
      const { data } = await axios.get('categories')
      console.log('categories', data.data)
      if (data.success) {
        setCategories(data.data)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async () => {
    try {
      let temp = categories.map((category, index) => (
        {
          ...category,
          order: index + 1,
          subs: [
            ...category.subs.map((sub, subIndex) => (
              {
                ...sub,
                order: subIndex + 1,
                parent_id: category.id
              }
            ))
          ]
        }
      ))

      let mixedCategories = [];
      temp.map((parent) => {
        mixedCategories.push(parent)
        parent.subs.map((child) => mixedCategories.push(child))
        return []
      })

      const body = mixedCategories.map((category) => ({
        id: category.id,
        order: category.order,
        parent_id: category.parent_id
      }))

      const { data } = await axios.put('categories/arrange', body)
      if (data.success) {
        navigate('/categories')
        toast.success('Success')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    fetchCategories()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Arrange Categories'
        description='categories'
        href='/categories'
        btns={[
          {
            title: 'Submit',
            icon: SaveIcon,
            onClick: onSubmit,
            variant: 'primary'
          }
        ]}
      />
      <div className='mg-b-20'>
        <Contain>
          <ReactSortable list={categories} setList={setCategories} {...sortableOptions}>
            {categories.map((category, index) => (
              <BlockWrapper
                key={category.id}
                category={category}
                categoryIndex={[index]}
                setCategories={setCategories}
              />
            ))}
          </ReactSortable>
        </Contain>
      </div>
    </Card>
  )
}

function Container({ category, categoryIndex, setCategories }) {
  return (
    <ReactSortable
      key={category.id}
      list={category.subs}
      setList={(currentList) => {
        setCategories((sourceList) => {
          const tempList = [...sourceList];
          const cIndex = [...categoryIndex];
          const lastIndex = cIndex.pop();
          const lastArr = cIndex.reduce(
            (arr, i) => arr[i]['subs'],
            tempList
          );
          lastArr[lastIndex]['subs'] = currentList;
          return tempList;
        });
      }}
      {...sortableOptions}
    >
      {category.subs &&
        category.subs.map((subCategory, index) => {
          return (
            <BlockWrapper
              key={subCategory.id}
              category={subCategory}
              categoryIndex={[...categoryIndex, index]}
              setCategories={setCategories}
            />
          );
        })}
    </ReactSortable>
  );
}

function BlockWrapper({ category, categoryIndex, setCategories }) {
  if (!category) return null;
  if (category.parent_id === 0) {
    return (
      <div className='mg-b-5'>
        <div className='list-group-item mg-b-5 tx-info'>
          <i className='icon ion-menu mg-r-10'></i>
          {category.name}
        </div>
        <Container
          category={category}
          categoryIndex={categoryIndex}
          setCategories={setCategories}
        />
      </div>
    );
  } else {
    return (
      <div className='list-group-item mg-b-5 mg-l-15'>
        <i className='icon ion-menu mg-r-10'></i>
        {category.name}
      </div>
    );
  }
}