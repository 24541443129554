import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'

export default function SupplierForm({ editForm, id }) {
  const [name, setName] = React.useState('')
  const [mmName, setMmName] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [attentionName, setAttentionName] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchSupplier = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`suppliers/${id}`)
      if (data.success) {
        const supplier = data.data
        setName(supplier.name)
        setMmName(supplier.mm_name)
        setPhone(supplier.phone)
        setAttentionName(supplier.attention_name)
        setAddress(supplier.address)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async ({
    name,
    mmName,
    phone,
    attentionName,
    address
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `suppliers/${id}` : `suppliers`
      const body = {
        name,
        mm_name: mmName,
        phone,
        attention_name: attentionName,
        address,
      }
      const { data } = await axios({
        method,
        url: endpoint,
        data: body,
      })
      if (data.success) {
        toast.success('Success')
        navigate('/suppliers')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const supplierSchema = Yup.object({
    name: Yup.string()
      .required('Name Required'),
    mmName: Yup.string()
      .required('Myanmar Name Required'),
    phone: Yup.string()
      .required('Phone required'),
    attentionName: Yup.string()
      .required('Attention Name required'),
  })

  React.useEffect(() => {
    editForm && fetchSupplier()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={{
        name,
        mmName,
        phone,
        attentionName,
        address,
      }}
      validationSchema={supplierSchema}
      onSubmit={(values) => {
        console.log('formik values', values)
        onSubmit(values)
      }}>
      {({ values, isSubmitting, handleChange, handleBlur }) => (
        <Container>
          <Form>
            <div className='form-group row'>
              <div className='col-md-3 tx-md-right'>
                <label className='col-form-label'>
                  <span className='tx-danger'>* </span>Name
                </label>
              </div>
              <div className='col-md-9'>
                <Field
                  name='name'
                  type='text'
                  className='form-control'
                  placeholder='Uniliver'
                  autoFocus
                />
                <ErrorComponent>
                  <ErrorMessage name='name' />
                </ErrorComponent>
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-md-3 tx-md-right'>
                <label className='col-form-label'>
                  <span className='tx-danger'>* </span>Myanmar Name
              </label>
              </div>
              <div className='col-md-9'>
                <Field
                  name='mmName'
                  type='text'
                  className='form-control'
                  placeholder='ယူနီလီဗာ'
                />
                <ErrorComponent>
                  <ErrorMessage name='mmName' />
                </ErrorComponent>
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-md-3 tx-md-right'>
                <label className='col-form-label'>
                  <span className='tx-danger'>* </span>Phone
              </label>
              </div>
              <div className='col-md-9'>
                <Field
                  name='phone'
                  type='text'
                  className='form-control'
                  placeholder='09...'
                />
                <ErrorComponent>
                  <ErrorMessage name='phone' />
                </ErrorComponent>
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-md-3 tx-md-right'>
                <label className='col-form-label'>
                  <span className='tx-danger'>* </span>Attention Name</label>
              </div>
              <div className='col-md-9'>
                <Field
                  name='attentionName'
                  type='text'
                  className='form-control'
                  placeholder='U Kyaw Kyaw'
                />
                <ErrorComponent>
                  <ErrorMessage name='attentionName' />
                </ErrorComponent>
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-md-3 tx-md-right'>
                <label className='col-form-label'>Address</label>
              </div>
              <div className='col-md-9'>
                <Field
                  name='address'
                  type='text'
                  className='form-control'
                  placeholder='No.1, Alone Township, Yangon'
                />
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-md-9 offset-md-3'>
                <button type='submit' className='btn btn-primary btn-block'>
                  {isSubmitting ? 'Submitting' : 'Submit'}
                </button>
              </div>
            </div>

          </Form>
        </Container>
      )}
    </Formik>
  )
}
