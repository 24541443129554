import React from 'react'
import { Menu as MenuIcon, X as XIcon, Save as SaveIcon } from 'react-feather'
import { ReactSortable } from 'react-sortablejs'
import ProductCollectionComponentItem from '../../components/builder/ProductCollectionComponentItem'
import ComponentList from '../../components/builder/ComponentList'
import '../../assets/css/builder.css'
import LinkComponentItem from '../../components/builder/LinkComponentItem'
import _ from 'underscore'
import axios from 'axios'

function PageBuilderPage() {
  const [list, setList] = React.useState([])
  const listRef = React.useRef(null)

  listRef.current = list

  function handleAddListItem(item) {
    setList((prevList) => [...prevList, item])
  }

  function handleUpdateList(data, index, updatePreview = true) {
    const listCopy = [...listRef.current]
    listCopy[index] = { ...listCopy[index], ...data }
    setList(listCopy)
    if (updatePreview) {
      fetchPreviewData(data, index)
    }
  }

  async function fetchPreviewData(item, index) {
    if (item.type === 'category_products') {
      const getCategory = await axios.get('categories/' + item.categoryId, {
        baseURL: process.env.REACT_APP_MAIN_PUBLIC_URL,
      })
      console.log(item, index)
      handleUpdateList(
        {
          previewData: getCategory.data,
          isPreviewLoading: false,
        },
        index,
        false
      )
    }
  }

  function handleRemove(index) {
    setList((prevList) => {
      let updatedList = [...prevList]
      updatedList.splice(index, 1)
      return updatedList
    })
  }

  return (
    <div className='page-builder content ht-100v pd-0'>
      <header className='navbar bg-white-100 navbar-header navbar-header-fixed'>
        <a href='' id='sidebarMenuOpen' className='burger-menu'>
          <MenuIcon />
        </a>
        <div className='navbar-brand'>
          <div className='content-search'>
            <i data-feather='search'></i>
            <input
              type='search'
              className='form-control'
              placeholder='Page Title...'
            />
          </div>
        </div>
        <div className='navbar-right'>
          <a
            href='https://themeforest.net/item/dashforge-responsive-admin-dashboard-template/23725961'
            className='btn btn-buy'
          >
            <SaveIcon /> <span>Save</span>
          </a>
        </div>
      </header>
      <div
        className='content-body pos-relative'
        style={{ paddingTop: 60, marginRight: 240 }}
      >
        <div style={{ maxWidth: 500, margin: '30px auto' }}>
          <ReactSortable list={list} setList={setList}>
            {list.map((item, index) => {
              if (item.block_type === 'collection_products') {
                return (
                  <ProductCollectionComponentItem
                    item={item}
                    key={index}
                    index={index}
                    onDelete={handleRemove}
                    onUpdate={handleUpdateList}
                  />
                )
              } else if (item.block_type === 'link') {
                return (
                  <LinkComponentItem
                    item={item}
                    index={index}
                    key={index}
                    onDelete={handleRemove}
                    onUpdate={handleUpdateList}
                  />
                )
              }
            })}
          </ReactSortable>
        </div>
      </div>
      <div
        id='sidebarMenu'
        className='sidebar sidebar-fixed bg-white bd-l bd-r-0'
        style={{ right: 0, left: 'auto', overflow: 'scroll' }}
      >
        <div className='sidebar-body pd-10'>
          <span className='tx-gray-500 tx-12'>COMPONENTS</span>
          <ComponentList onClickAdd={handleAddListItem} />
        </div>
      </div>
    </div>
  )
}

export default PageBuilderPage
