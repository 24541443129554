import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { Search as SearchIcon } from 'react-feather'

function SearchBox({ currentRefinement, isSearchStalled, refine }) {
  return (
    <div className='d-flex ht-45 pd-x-15 align-items-center bd-b'>
      <input
        type='search'
        className='form-control bd-0 pd-0 shadow-none-f'
        placeholder='Search for users'
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}

      />
      <a role='button' className='link-03'>
        <SearchIcon className='feather feather-menu wd-20 ht-20 stroke-wd-2-5' />
      </a>
    </div>
  )
}
const AlgoliaSearchBox = connectSearchBox(SearchBox)
export default AlgoliaSearchBox;