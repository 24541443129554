import React from 'react'
export default function NumberInput({ value, onChange }) {
  return (
      <input
        type='number'
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className='form-control mg-r-5'
        autoFocus
      />
  )
}