import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import toast from 'react-hot-toast'
import LoadingSpinner from '../../components/LoadingSpinner'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import Summary from '../../components/TableStats'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'

export default function ConfigPage() {
  const [configs, setConfigs] = React.useState([])
  const [activeConfigs, setActiveConfigs] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchConfigs = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get('app-configs')
      if (data.success) {
        setConfigs(data.data)
        setActiveConfigs(filterActive(data.data))
        setRows(data.data.map(data => (
          {
            id: data.id,
            key: data.key,
            value: data.value
          }
        )))
        setIsLoading(false)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.err(err)
    }
  }

  React.useEffect(() => {
    fetchConfigs()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <PageHeader
        title='Configurations'
        breadcrumbs={['systems']}
        btns={[
          {
            title: 'Add new Configuration',
            icon: 'ion-add',
            href: '/configurations/add',
            variant: 'primary'
          }
        ]}
      />

      <Summary
        data={[
          {
            label: 'total configurations',
            value: configs.length
          },
          {
            label: 'active configurations',
            value: activeConfigs.length
          }
        ]}
      />

      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id'
          },
          {
            title: 'Key',
            key: 'key'
          },
          {
            title: 'Value',
            key: 'value'
          },
          {
            title: 'Actions',
            render: (id, index) => (
              <>
                <button
                  className='btn btn-info btn-icons pd-t-0 pd-b-0 pd-l-3 pd-r-3 mg-r-5'
                  onClick={() => navigate(`configurations/${id}/edit`)}
                >
                  <i className='icon ion-create' ></i>
                </button>
                <button className='btn btn-danger btn-icons pd-t-0 pd-b-0 pd-l-3 pd-r-3 mg-r-5'>
                  <i className='icon ion-trash-bin'></i>
                </button>
              </>
            )
          }
        ]}
      />
    </>
  )
}