import React from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
export default function LoadingSpinner({ loading, className }) {
  return (
    <div className={`d-flex align-items-center justify-content-center ${className}`}>
      <ScaleLoader
        height={35}
        width={4}
        margin={2}
        radius={2}
        loading={loading}
        color='#106518'
      />
    </div>
  )
}