import React from 'react'
import Card from '../../components/Card'
import NotificationForm from './NotificationForm'

export default function AddNotificationPage() {
  return (
    <Card>
      <Card.Header
        title='Add Notification'
        description='notifications'
        href='/notifications'
      />
      <NotificationForm />
    </Card>
  )
}