import { Link, navigate } from '@reach/router'
import React from 'react'

export default function PageHeader(props) {
  return (
    <div className='d-sm-flex align-items-center justify-content-between mg-b-20 '>
      <div>
        {props.breadcrumbs && (
          <nav area-label='breadcrumbs'>
            <ol className='breadcrumb breadcrumb-style1 mg-b-10 '>
              {props.breadcrumbs.map((breadcrumb, index) => (
                <li className='breadcrumb-item' key={index}>
                  <Link to={'/' + breadcrumb}>
                    <span>{breadcrumb}</span>
                  </Link>
                </li>
              ))}
            </ol>
          </nav>
        )}
        <h4 className='mg-b-0 tx-spacing--1'>{props.title}</h4>
      </div>
      <div className='d-sm-flex'>
        {props.btns.map((btn, index) => (
          <div className='mg-r-5 d-none d-md-block' key={index}>
            <button
              className={`btn btn-sm btn-uppercase btn-${btn.variant}  mg-l-5 pd-x-15`}
              onClick={() => {
                btn.onClick ? btn.onClick() : navigate(btn.href)
              }}
            >
              <btn.icon className='mg-r-5'/>
              {btn.title}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
