import React from 'react'
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import _ from 'underscore'

function Select({ endpoint, onChange }) {
  const [inputValue, setInputValue] = React.useState('')

  // async function fetchOptions() {}
  async function loadOptions(inputValue, callback) {
    const getResult = await axios.get(endpoint, {
      params: {
        name: inputValue,
      },
    })
    callback(
      _.map(getResult.data.data, (d) => ({
        label: d.name,
        value: d.id,
      }))
    )
  }

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      onInputChange={setInputValue}
      onChange={onChange}
    />
  )
}

export default Select
