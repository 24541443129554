import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import MultiSelect from '../../common/MultiSelect'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'

export default function WarehouseForm({ editForm, id }) {
  const [name, setName] = React.useState('')
  const [townships, setTownships] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const warehouseSchema = Yup.object({
    name: Yup.string()
      .required('Name Required'),
    townships: Yup.array()
      .required('Townships Required')
  })

  const fetchWarehouse = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`warehouses/${id}`)
      console.log('warehouse; detail', data)
      if (data.success) {
        const warehouse = data.data
        setName(warehouse.name)
        setTownships(warehouse.townships.map(township => ({ label: township.name, value: township.id })))
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async ({
    name,
    townships
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `warehouses/${id}` : `warehouses`
      const body = {
        name,
        townships: townships.map((township) => township.value)
      }
      const { data } = await axios({
        method,
        url: endpoint,
        data: body,
      })
      console.log('response', data)
      if (data.success) {
        toast.success('Success')
        navigate('/warehouses')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    editForm && fetchWarehouse()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={{
        name,
        townships
      }}
      validationSchema={warehouseSchema}
      onSubmit={(values) => {
        console.log('formik values', values)
        onSubmit(values)
      }}
    >
      {({ values, isSubmitting, setFieldValue, handleChange, handleBlur }) => (
        <Container >
          <Form>
            <div className='form-group row'>
              <label className='col-md-3 text-md-right col-form-label'>
                <span className='tx-danger'>* </span>Name
                </label>
              <div className='col-md-9'>
                <Field
                  name='name'
                  type='text'
                  placeholder='Yangon Warehouse'
                  className='form-control'
                  autoFocus
                />
                <ErrorComponent>
                  <ErrorMessage name='name' />
                </ErrorComponent>
              </div>
            </div>
            <div className='form-group row'>
              <label className='col-md-3 text-md-right col-form-label'>
                <span className='tx-danger'>* </span>Townships
              </label>
              <div className='col-md-9'>
                <MultiSelect
                  name='townships'
                  endpoint='townships'
                  onChange={(townships) => setFieldValue('townships', townships)}
                  defaultValue={values.townships}
                  className='pd-0'
                />
                <ErrorComponent>
                  <ErrorMessage name='townships' />
                </ErrorComponent>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-md-9 offset-md-3'>
                <button className='btn btn-primary btn-block'>
                  {
                    isSubmitting ? 'Submitting' : 'Submit'
                  }
                </button>
              </div>
            </div>

          </Form>
        </Container>
      )}
    </Formik>
  )
}