import React from 'react'
import Popover from './Popover'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'

const propTypes = {
  rows: PropTypes.array,
  cols: PropTypes.array.isRequired,
  allData: PropTypes.array,
  bulkIds: PropTypes.array,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onAllChange: PropTypes.func,
}

export default function Table(props) {
  const {
    rows,
    cols,
    allData,
    bulkIds,
    onClick,
    onChange,
    onAllChange,
  } = props

  return (
    <div className='table-responsive'>
      <table className='table table-dashboard mg-b-0'>
        <thead>
          <tr>
            {cols.map((col, index) => (
              <th key={index} width={col.width}>
                {col.title.render ? (
                  <span>
                    {
                      col.title.render.type === 'checkbox' && (
                        <div className='custom-control custom-checkbox'>
                          <input
                            type='checkbox'
                            className='custom-control-input'
                            id='selectAll'
                            value={bulkIds.length === allData.length}
                            checked={bulkIds.length && bulkIds.length === allData.length}
                            onChange={() => onAllChange()}
                          />
                          <label className='custom-control-label' htmlFor='selectAll'>&nbsp;</label>
                        </div>
                      )
                    }
                  </span>
                ) : (
                    col.title
                  )
                }
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {cols.map((col, colIndex) => (
                  <td key={colIndex} className='align-middle'>
                    {col.key ? (
                      col.editable ? (
                        <Popover
                          id={row.id}
                          index={rowIndex}
                          type={col.editable.type}
                          name={col.editable.name}
                          defaultValue={col.editable.defaultValue}
                          currentValue={row[col.key]}
                          onClick={onClick}
                          options={col.editable.options}
                        >
                          {col.editable.type === 'switch' ? (
                            row[col.key]
                          ) : (
                              <span> {row[col.name] || row[col.key]} {col.unit}</span>
                            )
                          }
                        </Popover>
                      ) : (
                          <div className={col.className}>{row[col.key]} {col.unit}</div>
                        )
                    ) : (
                        <div>
                          {
                            !col.render.type && (
                              col.render(row.id, rowIndex)
                            )
                          }
                          {
                            col.render.type === 'checkbox' && (
                              <div className='custom-control custom-checkbox'>
                                <input
                                  type='checkbox'
                                  className='custom-control-input'
                                  id={row.id}
                                  checked={bulkIds.includes(row.id)}
                                  onChange={() => onChange(row.id)}
                                />
                                <label className='custom-control-label' htmlFor={row.id}>&nbsp;</label>
                              </div>
                            )
                          }
                          {
                            col.render.type === 'array' && (
                              <span>
                                {
                                  row[col.render.key].map((data, index) => (
                                    <span className={col.render.className} key={index}>
                                      {data.name}
                                    </span>
                                  ))
                                }
                              </span>
                            )
                          }
                          {
                            col.render.type === 'image' && (
                              <img
                                src={row[col.render.key]}
                                alt={col.render.alt}
                                width={30}
                                className={col.render.className}
                              />
                            )
                          }
                          {
                            col.render.type === 'tooltips' && (
                              <span
                                className={`${col.render.className}`}
                                data-toggle='tooltip'
                                data-placement='top'
                                title={row[col.render.key]}
                              >
                                {row[col.render.key]}
                              </span>
                            )
                          }
                          {
                            col.render.type === 'actions' && (
                              <span className={col.className}>
                                {
                                  col.render.btns.map(
                                    (btn, index) => (
                                      <button
                                        key={index}
                                        className={`btn btn-icon btn-xxs mg-r-5 ${btn.variant} ${btn.className}`}
                                        onClick={() => btn.onClick(row.id, rowIndex)}
                                      >
                                        {btn.icon ? <btn.icon /> : `${row.status === 'pending' ? 'confirm' : 'approve'}`}
                                      </button>
                                    ))
                                }
                              </span>
                            )
                          }
                        </div>
                      )}
                    <div>
                      {
                        col.subs && col.subs.map((sub, index) => (
                          <span className={sub.className} key={index}>
                            {
                              sub.editable ?
                                sub.render ? (
                                  <Popover
                                    id={row.id}
                                    index={rowIndex}
                                    type={sub.editable.type}
                                    name={sub.editable.name}
                                    defaultValue={sub.editable.defaultValue}
                                    currentValue={row[sub.key]}
                                    onClick={onClick}
                                    options={sub.editable.options}
                                  >
                                    {
                                      sub.render.type === 'bullet' && (
                                        <>
                                          <span className='d-inline-block wd-5 ht-5 bg-primary mg-r-2 rounded'></span>
                                          <span className={`${sub.render.className} align-middle mg-r-5`}>
                                            {row[sub.render.name] || row[sub.render.key]} {sub.render.unit}
                                          </span>
                                        </>
                                      )
                                    }
                                  </Popover>
                                ) : (
                                    <Popover
                                      id={row.id}
                                      index={rowIndex}
                                      type={sub.editable.type}
                                      name={sub.editable.name}
                                      defaultValue={sub.editable.defaultValue}
                                      currentValue={row[sub.key]}
                                      onClick={onClick}
                                      options={sub.editable.options}
                                    >
                                      {row[sub.name] || row[sub.key]} {sub.unit}
                                    </Popover>
                                  ) : (
                                  <span>
                                    {
                                      sub.render ? (
                                        <>
                                          {
                                            sub.render.type === 'bullet' && (
                                              <>
                                                <span className='d-inline-block wd-5 ht-5 bg-primary mg-r-2 rounded'></span>
                                                <span className={`${sub.render.className} align-middle mg-r-5`}>
                                                  {row[sub.render.key]} {sub.render.unit}
                                                </span>
                                              </>
                                            )
                                          }
                                          {
                                            sub.render.type === 'bullet tooltips' && (
                                              <>
                                                <span className='d-inline-block wd-5 ht-5 bg-primary mg-r-2 rounded'></span>
                                                <span
                                                  className={`${sub.render.className} align-middle mg-r-5`}
                                                  data-toggle='tooltip'
                                                  data-placement='top'
                                                  title={sub.render.key}
                                                >
                                                  {row[sub.render.key]} {sub.render.unit}
                                                </span>
                                              </>
                                            )
                                          }
                                        </>
                                      ) : (
                                          <span> {row[sub.key]} {sub.unit}</span>
                                        )
                                    }
                                  </span>
                                )
                            }
                          </span>
                        ))
                      }
                    </div>
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div >
  )
}
Table.propTypes = propTypes;