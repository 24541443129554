import React from 'react'
import axios from 'axios'
import { useParams } from '@reach/router'
import Loading from '../../components/Loading'
import { LifeBuoy as HelpIcon } from 'react-feather'

function InvoicePage() {
  const { id } = useParams()
  const [invoices, setInvoices] = React.useState(null)

  React.useEffect(() => {
    document.getElementsByTagName('body')[0].classList.add('bg-white')
    fetchInvoices()
    return () => {
      document.getElementsByTagName('body')[0].classList.remove('bg-white')
    }
  }, [])

  async function fetchInvoices() {
    try {
      const getInvoice = await axios.get(`orders/${id}/invoices`)
      setInvoices(getInvoice.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div style={{ maxWidth: '600px' }}>
      {invoices ? (
        invoices.map((invoice, key) => (
          <div className='container pd-x-0 pd-lg-x-10 pd-xl-x-0' key={key}>
            <div className='row'>
              <div className='col-sm-6'>
                <label className='tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-04'>
                  Billed From
                </label>
                <img
                  src='https://zaychin.com/imgs/zaychin-icon-sm.png'
                  alt='Zay Chin'
                  className='d-block mg-b-10'
                  width={40}
                />
                <h6 className='tx-15 mg-b-10'>Zay Chin</h6>
                <p className='mg-b-0'>
                  No 15, Corner of 5th Aye Yeik Mon St and 1st Yadanar Mon St,
                  Hlaing, Yangon
                </p>
                <p className='mg-b-0'>Tel No: +95 9 777 333322</p>
                <p className='mg-b-0'>Email: info@zaychin.com</p>
              </div>
              <div className='col-sm-6 tx-right d-none d-md-block'>
                <label className='tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-04'>
                  Order Number
                </label>
                <h1 className='tx-normal mg-b-10 tx-spacing--2'>
                  #{invoice.order_number}
                </h1>
              </div>
              <div className='col-sm-6 col-lg-8 mg-t-40 mg-sm-t-0 mg-md-t-40'>
                <label className='tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-04'>
                  Shipped To
                </label>
                <h6 className='tx-15 mg-b-10'>{invoice.user.name}</h6>
                <p className='mg-b-0'>
                  {invoice.address.address.address},{' '}
                  {invoice.address.township.name}
                </p>
                <p className='mg-b-0'>Tel No: {invoice.user.phone}</p>
              </div>
              <div className='col-sm-6 col-lg-4 mg-t-40'>
                <table className='table table-borderless table-sm'>
                  <tr>
                    <th colSpan={2}>
                      <label className='tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03'>
                        Invoice Information
                      </label>
                    </th>
                  </tr>
                  <tr>
                    <td>Order Number</td>
                    <td className='tx-right'>{invoice.order_number}</td>
                  </tr>
                  <tr>
                    <td>Delivery Date</td>
                    <td className='tx-right'>{invoice.delivery_date}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className='table-responsive mg-t-40'>
              <table className='table table-invoice bd-b' width={'100%'}>
                <thead>
                  <tr>
                    <th className='wd-70p'>Product</th>
                    <th className='tx-right wd-10p'>QTY</th>
                    <th className='tx-right wd-10p'>Unit Price</th>
                    <th className='tx-right wd-10p'>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.items.map((item) => (
                    <tr>
                      <td className='tx-nowrap wd-70p'>{item.product.name}</td>
                      <td className='tx-right wd-10p'>{item.quantity}</td>
                      <td className='tx-right wd-10p'>{item.product.price}</td>
                      <td className='tx-right wd-10p'>
                        {item.product.price * item.quantity}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='row justify-content-between'>
              <div className='col-sm-6 col-lg-6 order-2 order-sm-0 mg-t-40 mg-sm-t-0'>
                <label className='tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03'>
                  Notes
                </label>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.{' '}
                </p>
              </div>
              <div className='col-sm-6 col-lg-4 order-1 order-sm-0'>
                <div className='table-responsive'>
                  <table
                    className='table table-borderless table-sm'
                    width={'100%'}
                  >
                    <tr>
                      <td className='tx-right wd-50p'>Sub-Total</td>
                      <td className='wd-50p tx-right'>
                        {invoice.sub_total} MMK
                      </td>
                    </tr>
                    <tr>
                      <td className='tx-right wd-50p'>Total</td>
                      <td className='wd-50p tx-right'>{invoice.total} MMK</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className='invoice-footer'>
              <div
                className='alert alert-light d-flex align-items-center'
                role='alert'
              >
                <HelpIcon className='mg-r-10' />{' '}
                <p>
                  If you aren’t satisfied with the condition of a grocery
                  product that you receive from us, we’ll issue a refund up to 7
                  days from the date of delivery.
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <Loading />
      )}
      <style>{`
        .body, td, span, p {
          font-size: 20px
        }
      `}</style>
    </div>
  )
}

export default InvoicePage
