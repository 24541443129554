import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import MultiSelect from '../../common/MultiSelect'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'
import { map } from 'underscore'

export default function DeliveryTimeForm({ editForm, id }) {
  const [name, setName] = React.useState('')
  const [mmName, setMmName] = React.useState('')
  const [startAt, setStartAt] = React.useState('')
  const [endAt, setEndAt] = React.useState('')
  const [townships, setTownships] = React.useState([])
  const [isActive, setIsActive] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(true)

  const deliveryTimeSchema = Yup.object({
    name: Yup.string()
      .required('Name Required'),
    mmName: Yup.string()
      .required('Myanmar Name Required'),
    townships: Yup.string()
      .required('Townships Required'),
  })

  const generateTimeRange = React.useCallback(() => {
    var x = 30
    var times = []
    var tt = 0
    var ap = ['AM', 'PM']

    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60)
      var mm = tt % 60
      times[i] =
        ('0' + (hh % 12)).slice(-2) +
        ':' +
        ('0' + mm).slice(-2) +
        ' ' +
        ap[Math.floor(hh / 12)]
      tt = tt + x
    }

    return times
  }, [])

  const fetchDeliveryTime = async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `timeranges/${id}`,
      })
      console.log('deliverytime detail', data)
      if (data.success) {
        const deliveryTime = data.data
        setName(deliveryTime.name)
        setMmName(deliveryTime.mm_name)
        setTownships(deliveryTime.townships.map(township => ({ label: township.name, value: township.id })))
        setStartAt(deliveryTime.start_at)
        setEndAt(deliveryTime.end_at)
        setIsActive(Boolean(deliveryTime.is_active))
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async ({
    name,
    mmName,
    startAt,
    endAt,
    townships,
    isActive,
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const url = editForm ? `timeranges/${id}` : `timeranges`
      const body = {
        name,
        mm_name: mmName,
        start_at: startAt,
        end_at: endAt,
        townships: townships.map((township) => township.value),
        is_active: isActive,
      }
      console.log({ body })
      const { data } = await axios({
        method,
        url,
        data: body,
      })
      if (data.success) {
        toast.success('Success')
        navigate('/delivery-times')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  React.useEffect(() => {
    editForm && fetchDeliveryTime()
  }, [])

  if (editForm && isLoading) {
    return (
      <LoadingSpinner />
    )
  }

  return (
    <Container>
      <Formik
        initialValues={{
          name,
          mmName,
          startAt,
          endAt,
          townships,
          isActive,
        }}
        validationSchema={deliveryTimeSchema}
        onSubmit={(values) => {
          console.log('formik values', values)
          onSubmit(values)
        }}
      >
        {
          ({
            values,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue

          }) => (
            <Form>
              <div className='form-group row'>
                <div className='col-md-3 tx-md-right col-form-label'>
                  <label>
                    <span className='tx-danger'>* </span>Name
                    </label>
                </div>
                <div className='col-md-9'>
                  <Field
                    name='name'
                    type='text'
                    className='form-control'
                    autoFocus
                  />
                  <ErrorComponent>
                    <ErrorMessage name='name' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-md-3 tx-md-right col-form-label'>
                  <label>
                    <span className='tx-danger'>* </span>Myanmar Name
                  </label>
                </div>
                <div className='col-md-9'>
                  <Field
                    name='mmName'
                    type='text'
                    className='form-control'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='mmName' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-md-3 tx-md-right col-form-label'>
                  <label>
                    <span className='tx-danger'>* </span>Time
                  </label>
                </div>
                <div className='col-md-9'>
                  <div className='row'>
                    <div className='col-6'>
                      <select
                        name='startAt'
                        onChange={(e) => setFieldValue('startAt', e.target.value)}
                        value={values.startAt}
                        className='custom-select'
                      >
                        <option selected value={''}>
                          Start Time
                        </option>
                        {map(generateTimeRange(), (time) => (
                          <option value={time}>{time}</option>
                        ))}
                      </select>
                    </div>
                    <div className='col-6'>
                      <select
                        name='endAt'
                        onChange={(e) => setFieldValue('endAt', e.target.value)}
                        value={values.endAt}
                        className='custom-select'
                      >
                        <option selected value={''}>
                          End Time
                        </option>
                        {map(generateTimeRange(), (time) => (
                          <option value={time}>{time}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-md-3 tx-md-right col-form-label'>
                  <label>Townships</label>
                </div>
                <div className='col-md-9'>
                  <MultiSelect
                    name='townships'
                    endpoint='townships'
                    onChange={(townships) => setFieldValue('townships', townships)}
                    defaultValue={values.townships}
                    className='pd-0'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='townships' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <div className='offset-md-3 col-md-9'>
                  <div className='custom-switch custom-control'>
                    <input
                      name='isActive'
                      type='checkbox'
                      id='isActive'
                      className='custom-control-input'
                      value={values.isActive}
                      checked={values.isActive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor='isActive' className='custom-control-label'>
                      Active
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <button
                    type='submit'
                    className='btn btn-primary btn-block'
                  >
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </button>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </Container>
  )
}
