import React from 'react'
import Card from '../../components/Card'
import EntriesForm from './EntriesForm'

export default function AddEntriesPage() {
  return (
    <Card>
      <Card.Header
        title='Add new Entries'
        description='Entries'
        href='/inventories/entries'
      />
      <EntriesForm />
    </Card>
  )
}