import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import FileUpload from '../../components/FileUpload'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'

export default function BankForm({ editForm, id }) {
  const [name, setName] = React.useState('')
  const [accountName, setAccountName] = React.useState('')
  const [accountNo, setAccountNo] = React.useState('')
  const [logo, setLogo] = React.useState([])
  const [order, setOrder] = React.useState('')
  const [isActive, setIsActive] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchBank = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`banks/${id}`)
      console.log('bank detail', data)
      if (data.success) {
        const bank = data.data
        setName(bank.name)
        setAccountName(bank.account_name)
        setAccountNo(bank.account_number)
        setLogo(bank.files.map(
          (file) => ({
            id: file.id,
            url: `${process.env.REACT_APP_IMAGE_API_URL}${file.url}_100.jpg`,
            status: 'success'
          })
        ))
        setOrder(bank.order)
        setIsActive(Boolean(bank.is_active))
        setIsLoading(false)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const BankSchema = Yup.object({
    name: Yup.string()
      .required('Name Required'),
    accountName: Yup.string()
      .required('Account Name Required'),
    accountNo: Yup.string().
      required('Account Number Required')
  })

  const onSubmit = async ({
    name,
    accountName,
    accountNo,
    isActive,
    order
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `banks/${id}` : `banks`
      const body = {
        name,
        account_name: accountName,
        account_number: accountNo,
        files: logo.map((file) => file.id),
        order,
        is_active: isActive
      }
      console.log('body', body)
      const { data } = await axios({
        method,
        url: endpoint,
        data: body,
      })
      if (data.success) {
        toast.success('Success')
        navigate('/banks')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    editForm && fetchBank()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Container>
      <Formik
        initialValues={{
          name,
          accountName,
          accountNo,
          isActive,
          order
        }}
        validationSchema={BankSchema}
        onSubmit={(values) => {
          console.log('formik values', values)
          onSubmit(values)
        }}
      >
        {({ values, isSubmitting, setFieldValue, handleChange, handleBlur }) => (
          <Form>
            <div className='form-group row'>
              <div className='col-md-3 text-md-right'>
                <label className='col-form-label'>
                  <span className='tx-danger'>* </span>Name
               </label>
              </div>
              <div className='col-md-9'>
                <Field
                  name='name'
                  type='text'
                  placeholder='AYA'
                  className='form-control'
                  autoFocus
                />
                <ErrorComponent>
                  <ErrorMessage name='name' />
                </ErrorComponent>
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-md-3 text-md-right'>
                <label className='col-form-label'>
                  <span className='tx-danger'>* </span>Account Name
              </label>
              </div>
              <div className='col-md-9'>
                <Field
                  name='accountName'
                  type='text'
                  placeholder='Zay Chin'
                  className='form-control'
                />
                <ErrorComponent>
                  <ErrorMessage name='accountName' />
                </ErrorComponent>
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-md-3 text-md-right'>
                <label className='col-form-label'>
                  <span className='tx-danger'>* </span>Account Number
              </label>
              </div>
              <div className='col-md-9'>
                <Field
                  name='accountNo'
                  type='text'
                  placeholder='999 000 999 000'
                  className='form-control'
                />
                <ErrorComponent>
                  <ErrorMessage name='accountNo' />
                </ErrorComponent>
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-md-3 text-md-right '>
                <label className='col-form-label'>
                  Logo
                </label>
              </div>
              <div className='col-md-9'>
                <FileUpload
                  name='logo'
                  id={id}
                  relatedType='banks'
                  multiple={false}
                  photos={logo}
                  setPhotos={setLogo}
                />
              </div>
            </div>

            {editForm && (
              <div className='form-group row'>
                <div className='col-md-3 text-md-right'>
                  <label className='col-form-label'>
                    Order
                </label>
                </div>
                <div className='col-md-9'>
                  <Field
                    name='order'
                    type='number'
                    className='form-control'
                  />
                </div>
              </div>
            )}

            <div className='form-group row'>
              <div className='offset-md-3 col-md-9'>
                <div className='custom-switch custom-control'>
                  <input
                    name='isActive'
                    type='checkbox'
                    id='isActive'
                    className='custom-control-input'
                    value={values.isActive}
                    checked={values.isActive}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor='isActive' className='custom-control-label'>
                    Active
                </label>
                </div>
              </div>
            </div>

            <div className='form-group row'>
              <div className='offset-md-3 col-md-9'>
                <button className='btn btn-primary btn-block'>
                  {
                    isSubmitting ? 'Submitting' : 'Submit'
                  }
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}