import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Edit2 as EditIcon, 
  Plus as PlusIcon,
  Trash2 as DeleteIcon,
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import LoadingSpinner from '../../components/LoadingSpinner'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'

export default function CollectionsPage() {
  const [collections, setCollections] = React.useState([])
  const [activeCollections, setActiveCollections] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchCollections = async () => {
    try {
      const { data } = await axios.get('collections', {
        page: 1,
        limit: 20,
      })
      if (data.success) {
        setCollections(data.data)
        setActiveCollections(filterActive(data.data))
        setRows(
          data.data.map((data) => ({
            id: data.id,
            name: data.name,
            type: data.type,
            isPublic: data.is_public,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreCollection = async (id, index) => {
    try {
      setIsLoading(true)
      const { data } = await axios.post(`collections/${id}/restore`)
      if (data.success) {
        const temp = [...collections]
        temp[index] = data.data
        setCollections(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            name: data.name,
            type: data.type,
            isPublic: data.is_public,
          }))
        )
        toast.remove()
        setIsLoading(false)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
      setIsLoading(false)
    }
  }

  const deleteCollection = async (id, index) => {
    try {
      setIsLoading(true)
      const { data } = await axios.delete(`collections/${id}`)
      if (data.success) {
        const temp = [...collections]
        temp.splice(index, 1)
        setCollections(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            name: data.name,
            type: data.type,
            isPublic: data.is_public,
          }))
        )
        toast(<UndoAction onClick={() => restoreCollection(id, index)} />, 4000)
        setIsLoading(false)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    fetchCollections()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Collections'
        descriptions='Collections'
        tableStats={[
          { label: 'total collections', value: collections.length },
          { label: 'active collections', value: activeCollections.length },
        ]}
        btns={[
          {
            title: 'Add new collection',
            icon: PlusIcon,
            href: 'collections/add',
            variant: 'primary',
          },
        ]}
      />

      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Name',
            key: 'name',
          },
          {
            title: 'Type',
            width: '150px',
            key: 'type',
            className: 'badge badge-secondary rounded-pill',
          },
          {
            title: 'Public',
            key: 'isPublic',
            width: '50px',
            editable: {
              type: 'switch',
              name: 'is_public',
            },
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id) => navigate(`collections/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteCollection,
                }
              ]
            }
          },
        ]}
      />
    </Card>
  )
}
