import React from 'react'
import { paymentMethods } from '../data/orders'

export default function PaymentMethods({ paymentId, onChange }) {
  
  return (
    <div className='row'>
      {
        paymentMethods.map((payment, index) => (
          <div className='col-md-6' key={index}>
            <div className='custom-control custom-checkbox' key={index}>
              <input
                type='checkbox'
                className='custom-control-input'
                id={index + payment}
                checked={paymentId === payment.id}
                onChange={() => onChange(payment.id)}
              />
              <label className='custom-control-label' htmlFor={index + payment}>{payment.name}</label>
            </div>
          </div>
        ))
      }
    </div>
  )
}