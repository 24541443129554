import React from 'react'
import NumberInput from '../common/NumberInput'
import SingleSelect from '../common/SingleSelect'
import TextInput from '../common/TextInput'
import DatePicker from 'react-datepicker'
import moment from 'moment'

export default function Editable({
  id,
  index,
  type,
  name,
  currentValue,
  defaultValue,
  setShowPopover,
  className,
  options,
  onClick
}) {
  const [value, setValue] = React.useState(currentValue)

  const onTextChange = (value) => {
    setValue(value)
  }

  return (
    <div
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          onClick(id, index, name, value, defaultValue, setShowPopover)
        }
      }}
      style={{
        position: 'absolute',
        width: '250px',
        zIndex: 9999,
        top: -57,
        right: 0,
        left: -60,
        backgroundColor: 'white'
      }}
      className={
        `d-flex justify-content-center align-items-center 
        ${className} bd bd-gray-500 rounded pd-5`
      }>
      {
        type === 'text' && <TextInput value={value} onChange={onTextChange} />
      }
      {
        type === 'number' && <NumberInput value={value} onChange={onTextChange} />
      }
      {
        type === 'select' && (
          <select
            className={`custom-select ${className}`}
            value={value}
            onChange={(e) => onTextChange(e.target.value)}
          >
            {
              options && options.map((option, index) => (
                <option
                  value={`${option.id}`}
                  key={index}
                >{option.name}
                </option>
              ))
            }
          </select>
        )
      }
      {
        type === 'date' && (
          <DatePicker
            className='form-control'
            selected={new Date(value)}
            onChange={(date) => onTextChange(moment(date).format('YYYY-MM-DD'))}
          />
        )
      }
      <button
        className='btn btn-icon btn-primary active mg-r-5 pd-x-5 pd-y-2'
        type='button'
        onClick={() => onClick(id, index, name, value, defaultValue, setShowPopover)}
      >
        <i className='icon ion-checkmark-outline tx-20'></i>
      </button>
      <button
        className='btn btn-icon btn-light pd-x-5 pd-y-2'
        type='button'
        onClick={() => setShowPopover(false)}
      >
        <i className='icon ion-close-outline tx-secondary tx-20'></i>
      </button>
    </div>
  )
}