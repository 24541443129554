import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Edit2 as EditIcon,
  Plus as PlusIcon,
  Trash2 as DeleteIcon
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import LoadingSpinner from '../../components/LoadingSpinner'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'

export default function TownshipsPage() {
  const [townships, setTownships] = React.useState([])
  const [activeTownships, setActiveTownships] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchTownships = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get('townships')
      if (data.success) {
        setTownships(data.data)
        setActiveTownships(filterActive(data.data))
        setRows(
          data.data.map((data) => ({
            id: data.id,
            name: data.name,
            cityName: data.city.name,
            deliveryFee: data.delivery_fee,
            minimumAmount: data.minimum_amount,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreTownship = async (id, index) => {
    try {
      const { data } = await axios.post(`townships/${id}/restore`)
      if (data.success) {
        const temp = [...townships]
        temp[index] = data.data
        setTownships(temp)
        setRows(temp)
        toast.remove()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteTownship = async (id, index) => {
    try {
      const { data } = await axios.delete(`townships/${id}`)
      if (data.success) {
        const temp = [...townships]
        temp.splice(index, 1)
        setTownships(temp)
        setRows(temp)
        toast.success(
          <UndoAction
            onClick={() => restoreTownship(id, index)}
            name={townships[index].name}
          />
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    fetchTownships()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Townships'
        description='Townships'
        tableStats={[
          {
            label: 'total townships',
            value: townships.length,
          },
          {
            label: 'active townships',
            value: activeTownships.length,
          },
        ]}
        btns={[
          {
            title: 'Add new Township',
            icon: PlusIcon,
            href: `/townships/add`,
            variant: 'primary',
          },
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Name',
            key: 'name',
          },
          {
            title: 'City Name',
            key: 'cityName',
          },
          {
            title: 'Delivery Fee',
            key: 'deliveryFee',
            width: '150px',
          },
          {
            title: 'Minimum Amount',
            key: 'minimumAmount',
            width: '150px',
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id) => navigate(`townships/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteTownship,
                },
              ]
            }
          },
        ]}
      />
    </Card>
  )
}
