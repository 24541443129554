import React from 'react'
import Card from '../../components/Card'
import CollectionForm from './CollectionForm'

export default function AddCollectionPage() {
  return (
    <Card>
      <Card.Header
        title='Add New Collection'
        description='collections'
        href='/collections'
      />
      <CollectionForm />
    </Card>
  )
}