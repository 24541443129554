import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'

export default function RoleForm({ editForm, id }) {
  const [name, setName] = React.useState('')
  const [permissions, setPermissions] = React.useState([])
  const [allPermissions, setAllPermissions] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [cols, setCols] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const getRowName = (permission) => {
    return permission.filter((item, index) => index !== permission.length - 1).join('_')
  }

  const getColumnName = (permission) => {
    return permission[permission.length - 1]
  }

  const getAllPermissionsStartsWithRowName = (rowName) => {
    return allPermissions.filter(permission => getRowName(permission.split('_')) === rowName)
  }

  const getAllPermissionsEndsWithColumnName = (columnName) => {
    return allPermissions.filter(permission => getColumnName(permission.split('_')) === columnName)
  }

  const isRowChecked = (rowName) => {
    const allPermissionsStartsWithRowName = getAllPermissionsStartsWithRowName(rowName)
    const selectedPermissionsStartsWithRowName = permissions.filter(permission => getRowName(permission.split('_')) === rowName)
    return selectedPermissionsStartsWithRowName.some((permission) => allPermissionsStartsWithRowName.includes(permission))
  }

  const isColumnChecked = (columnName) => {
    const allPermissionsEndsWithColumnName = getAllPermissionsEndsWithColumnName(columnName)
    const selectedPermissionsEndsWithColumnName = permissions.filter(permission => getColumnName(permission.split('_')) === columnName)
    return selectedPermissionsEndsWithColumnName.some((permission) => allPermissionsEndsWithColumnName.includes(permission))
  }

  const handleRowChange = (rowName) => {
    if (isRowChecked(rowName)) {
      setPermissions(permissions.filter(permission => getRowName(permission.split('_')) !== rowName))
    } else {
      const allPermissionsStartsWithRowName = getAllPermissionsStartsWithRowName(rowName)
      setPermissions([...new Set([...permissions, ...allPermissionsStartsWithRowName])])
    }
  }

  const handleColumnChange = (columnName) => {
    if (isColumnChecked(columnName)) {
      setPermissions(permissions.filter(permission => getColumnName(permission.split('_')) !== columnName))
    } else {
      const allPermissionsEndWithColumnName = getAllPermissionsEndsWithColumnName(columnName)
      setPermissions([...new Set([...permissions, ...allPermissionsEndWithColumnName])])
    }
  }

  const handleSelectAllChange = () => {
    if (allPermissions.length === permissions.length) {
      setPermissions([])
    } else {
      setPermissions(allPermissions)
    }
  }

  const handlePermissionChange = (name) => {
    if (permissions.includes(name)) {
      setPermissions(permissions => permissions.filter(permission => permission !== name))
    } else {
      setPermissions([...permissions, name])
    }
  }

  const fetchAllPermissions = async () => {
    try {
      const { data } = await axios.get('permissions')
      if (data.success) {
        setAllPermissions(data.data)
        const permissionsSplitted = data.data.map(data => data.split('_'))
        setRows([...new Set(permissionsSplitted.map(getRowName))])
        setCols([...new Set(permissionsSplitted.map(getColumnName))])
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const fetchRole = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`roles/${id}`)
      console.log('role detail', data.data)
      if (data.success) {
        const role = data.data
        setName(role.name)
        setPermissions(role.permissions)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async ({ name }) => {
    try {
      console.log('onsubmit', name, permissions)
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `roles/${id}` : `roles`
      const body = {
        name,
        permissions
      }
      if (permissions.length) {
        const { data } = await axios({
          method,
          url: endpoint,
          data: body,
        })
        if (data.success) {
          toast.success('Success')
          navigate('/roles')
        }
      } else {
        toast.error('Choose One Permission at least')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    editForm && fetchRole()
    fetchAllPermissions()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }


  return (
    <Formik
      initialValues={{
        name,
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required('Name Required')
      })}
      onSubmit={(values) => {
        console.log('formik values', values)
        onSubmit(values)
      }}
    >
      {
        ({ values, isSubmitting }) => (
          <Container>
            <Form>
              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label' >
                  <span className='tx-danger'>* </span>Name
                </label>
                <div className='col-md-9'>
                  <Field
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='admin'
                    autoFocus
                  />
                  <ErrorComponent >
                    <ErrorMessage name='name' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label' >
                  <span className='tx-danger'>* </span>Permissions
                </label>
                <div className='col-md-9'>
                  <table className='table align-middle'>
                    <thead className='thead-light'>
                      <tr>
                        <th scope='col'>
                          <input
                            type='checkbox'
                            name='selectAll'
                            className='mg-r-2'
                            id='selectAll'
                            onChange={(e) => handleSelectAllChange()}
                            checked={allPermissions.length === permissions.length}
                          />
                          <label htmlFor='selectAll'>Name</label>
                        </th>
                        {
                          cols.map((col, colIndex) => (
                            <th key={colIndex} scope='col'>
                              <input
                                type='checkbox'
                                name={col}
                                className='mg-r-2'
                                id={`col${colIndex}`}
                                onChange={(e) => handleColumnChange(e.target.name)}
                                checked={isColumnChecked(col)}
                              />
                              <label htmlFor={`col${colIndex}`}>{col.toUpperCase()}</label>
                            </th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        rows.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td scope='row'>
                              <input
                                type='checkbox'
                                name={row}
                                className='mg-r-2'
                                id={`row_${rowIndex}`}
                                onChange={(e) => handleRowChange(e.target.name)}
                                checked={isRowChecked(row)}
                              />
                              <label htmlFor={`row_${rowIndex}`}>{row.toUpperCase()}</label>
                            </td>
                            {
                              cols.map((col, colIndex) => (
                                <td key={colIndex} scope='col'>
                                  <input
                                    type='checkbox'
                                    name={`${row}_${col}`}
                                    disabled={!allPermissions.includes(`${row}_${col}`)}
                                    onChange={(e) => handlePermissionChange(e.target.name)}
                                    checked={permissions.includes(`${row}_${col}`)}
                                  />
                                </td>
                              ))
                            }
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                  <ErrorComponent>
                    <ErrorMessage name='permissions' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <button className='btn btn-primary btn-block btn-uppercase'>
                    {isSubmitting ? 'submitting' : 'submit'}
                  </button>
                </div>
              </div>
            </Form>
          </Container>
        )
      }
    </Formik>
  )
}