import React from 'react'
import { useParams } from '@reach/router'
import Card from '../../components/Card'
import CountryForm from './CountryForm'

export default function EditCountryPage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Country'
        description='countries'
        href='/countries'
      />
      <CountryForm editForm id={id} />
    </Card>

  )
}