export const notificationTypes = [
  {
    id: 'refund',
    name: 'Refund',
  },
  {
    id: 'promotion',
    name: 'Promotion',
  },
  {
    id: 'order_status',
    name: 'Order Status',
  },
  {
    id: 'announcement',
    name: 'Announcement',
  },
]

export const discountTypes = [
  {
    id: 'fixed_amount',
    name: 'Fixed Amount',
  },
  {
    id: 'percentage',
    name: 'Percentage',
  },
  {
    id: 'buy_one_get_one',
    name: 'Buy 1 Get 1',
  },
  {
    id: 'buy_one_get_free',
    name: 'Buy 1 Get Free',
  },
]

export const productTypes = [
  {
    id: 'basic',
    name: 'Basic',
  },
  {
    id: 'bundle',
    name: 'Bundle',
  },
]

export const types = [
  { name: 'Product', id: 'product' },
  { name: 'Single Category', id: 'single_category' },
  { name: 'Multiple Category', id: 'multi_category' },
  { name: 'Collection', id: 'collection' }
]