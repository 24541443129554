import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Edit2 as EditIcon,
  Plus as PlusIcon,
  Trash2 as DeleteIcon,
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import LoadingSpinner from '../../components/LoadingSpinner'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'

export default function SupplierPage() {
  const [suppliers, setSuppliers] = React.useState([])
  const [activeSuppliers, setActiveSuppliers] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchSuppliers = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get('suppliers')
      if (data.success) {
        setSuppliers(data.data)
        setActiveSuppliers(filterActive(data.data))
        setRows(
          data.data.map((data) => ({
            id: data.id,
            name: data.name,
            phone: data.phone,
            attentionName: data.attention_name,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreSupplier = async (id) => {
    try {
      const { data } = await axios.post(`suppliers/${id}/restore`)
      data.success && fetchSuppliers()
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteSupplier = async (id) => {
    try {
      const { data } = await axios.delete(`suppliers/${id}`)
      if (data.success) {
        fetchSuppliers()
        toast.success(<UndoAction onClick={() => restoreSupplier(id)} />)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    fetchSuppliers()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Suppliers'
        description='Suppliers'
        tableStats={[
          {
            label: 'total suppliers',
            value: suppliers.length,
          },
          {
            label: 'active suppliers',
            value: activeSuppliers.length,
          },
        ]}
        btns={[
          {
            title: 'Add new supplier',
            icon: PlusIcon,
            href: 'suppliers/add',
            variant: 'primary',
          },
        ]}
      />

      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
          },
          {
            title: 'Name',
            key: 'name',
          },
          {
            title: 'Phone',
            key: 'phone',
          },
          {
            title: 'Attention Name',
            key: 'attentionName',
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id) => navigate(`suppliers/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteSupplier,
                }
              ]
            },
          },
        ]}
      />
    </Card>
  )
}
