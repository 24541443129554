import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Edit2 as EditIcon,
  Plus as PlusIcon,
  Trash2 as DeleteIcon
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import Table from '../../components/Table'
import getErrorMessage from '../../utils/getErrorMessage'

export default function PreferredDeliveryTimesPage() {
  const [preferredDeliveryTimes, setPreferredDeliveryTimes] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchPreferredDeliveryTimes = async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: 'timeranges'
      })
      console.log('Delivery Times', data)
      if (data.success) {
        setPreferredDeliveryTimes(data.data)
        setRows(
          data.data.map((data) => ({
            id: data.id,
            name: data.name,
            townships: data.townships
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDeliveryTime = async (id, index) => {
    try {
      const { data } = await axios({
        method: 'DELETE',
        url: `timeranges/${id}`,
      })
      console.log('delete deliverytimes', data)
      if (data.success) {
        const temp = [...preferredDeliveryTimes]
        temp.splice(index, 1)
        setPreferredDeliveryTimes(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            name: data.name,
            townships: data.townships,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    fetchPreferredDeliveryTimes()
  }, [])

  return (
    <Card>
      <Card.Header
        title='Preferred Delivery Times'
        description='deliveryTimes'
        tableStats={[
          { label: 'total Preferred Delivery times', value: preferredDeliveryTimes.length },
        ]}
        btns={[
          {
            title: 'Add new Delivery Time',
            icon: PlusIcon,
            href: 'delivery-times/add',
            variant: 'primary',
          },
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Name',
            key: 'name',
            width: '200px',
          },
          {
            title: 'Townships',
            render: {
              type: 'array',
              key: 'townships',
              className: `badge rounded-pill badge-secondary mg-1`,
            }
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id)=> navigate(`delivery-times/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteDeliveryTime,
                }
              ],
            },
          },
        ]}
      />
    </Card>
  )
}
