import React from 'react'
import {
  CheckCircle as CheckCircleIcon,
  X as XIcon
} from 'react-feather'

export default function SortableImage({ value, index, onDelete }) {
  return (
    <div className='col-6 col-sm-4'>
      <div className='card card-file'>
        <div className='dropdown-file'>
          <button
            type='button'
            className='btn btn-icon btn-xxs btn-danger rounded-circle tx-white'
            onClick={() => onDelete(value.id, index)}>
            <XIcon className='tx-10' />
          </button>
        </div>
        <div className='card-file-thumb'>
          <img src={value.url} alt='' className='img-fluid' />
        </div>
        <div className='card-body tx-center bg-light'>
          {
            value.status === 'pending' ? (
              <div className='spinner-border tx-primary tx-10 mg-b-10' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
                <CheckCircleIcon className='tx-success tx-20 mg-b-10' />
              )
          }
        </div>
      </div>
    </div>
  )
}