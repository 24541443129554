import React from 'react'
import { useParams } from '@reach/router'
import Card from '../../components/Card'
import BankForm from './BankForm'

export default function EditBankPage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Bank'
        description='Banks'
        href='/banks'
      />
      <BankForm editForm id={id} />
    </Card>
  )
}