import React from 'react'
import { Link, navigate } from '@reach/router'
import { ChevronLeft as ChevronLeftIcon } from 'react-feather'
import TableStats from './TableStats'

const Card = ({ children }) => {
  return (
    <div className='card mg-b-10'>
      {children}
    </div>
  )
}

const CardHeader = ({
  title,
  description,
  href,
  tableStats = [],
  btns = [],
}) => {

  return (
    <div className='card-header pd-t-20 bd-b-0 row'>
      <div className='col-md-3'>
        <div className='d-flex align-items-center justify-content-start '>
          {
            href && (
              <Link to={href} className='link-03'>
                <ChevronLeftIcon className='mg-r-20' />
              </Link>
            )
          }
          <div className='wd-200'>
            <h6 className='mg-b-5'>{title}</h6>
            <p className='tx-13 tx-color-03 mg-b-0'>{description}</p>
          </div>
        </div>
      </div>
      <div className='col-md-9'>
        <div className='d-flex justify-content-md-between justify-content-start mg-t-10 mg-md-t-0 align-items-center'>
          <TableStats data={tableStats} className='d-none' />
          <div className='d-flex'>
            {
              btns.map((btn, index) => (
                <button
                  key={index}
                  className={`btn btn-sm btn-uppercase btn-${btn.variant} mg-t-sm-5 mg-r-5 pd-x-15 btn-xs ${btn.className}`}
                  onClick={() => {
                    btn.onClick ? btn.onClick() : navigate(btn.href)
                  }}
                  disabled={btn.disabled}
                >
                  <btn.icon className='feather feather-menu mg-r-5' />
                  {btn.title}
                </button>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

Card.Header = CardHeader

export default Card
