export default function initPeity(){
  window.$(function(){
    'use strict'
  
    // Line chart
    window.$('.peity-line').peity('line',{
      width: '100px'
    });
  
    // Bar charts
    window.$('.peity-bar').peity('bar');
  
    // Pie chart
    window.$('.peity-pie').peity('pie');
  
    // Donut chart
    window.$('.peity-donut').peity('donut');
  
    // Bar chart is already initialized found in bracket.js
  });
}
