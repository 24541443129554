import React from 'react'
import { RefreshCw as ResetIcon } from 'react-feather'
import AsyncSingleSelect from '../../common/AsyncSingleSelect'
import SingleSelect from '../../common/SingleSelect'
import {
  paymentMethods,
  platforms,
  processingBatchNumbers
} from '../../data/orders'

export default function OrdersFilter({
  setParams,
  onChangeFilter,
}) {
  const formRef = React.useRef(null)

  return (
    <form className='form pd-15' ref={formRef}>
      <div className='form-group row'>
        <div className='col-12'>
          <label className='mg-b-0'>User</label>
        </div>
        <div className='col-12'>
          <AsyncSingleSelect
            endpoint='users'
            onChange={(user) => onChangeFilter('user_id', user.value)}
          />
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-12'>
          <label className='mg-b-0'>Platform</label>
        </div>
        <div className='col-12'>
          <SingleSelect
            label='Platform'
            data={platforms}
            onChange={(platform) => onChangeFilter('platform', platform)}
          />
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-12'>
          <label className='mg-b-0'>Delivery Time Range</label>
        </div>
        <div className='col-12'>
          <SingleSelect
            label='Delivery Time'
            endpoint='timeranges'
            onChange={(preferredDeliveryTime) => onChangeFilter('time_range_id', preferredDeliveryTime)}
          />
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-12'>
          <label className='mg-b-0'>Payment Method</label>
        </div>
        <div className='col-12'>
          <SingleSelect
            label='Payment Method'
            data={paymentMethods}
            onChange={(payment) => onChangeFilter('payment_method', payment)}
          />
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-12'>
          <label className='mg-b-0'>Processing Batch Number</label>
        </div>
        <div className='col-12'>
          <SingleSelect
            label=''
            data={processingBatchNumbers}
            onChange={(processingBatchNumber) => onChangeFilter('processing_batch_number', processingBatchNumber)}
          />
        </div>
      </div>
      <div className='form-group tx-center'>
        <button
          type='button'
          className='btn btn-secondary'
          onClick={() => {
            formRef.current.reset()
            setParams({})
          }}
        >
          <ResetIcon className='mg-r-5' />
              RESET
        </button>
      </div>
    </form>
  )
}
