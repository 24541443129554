import React from 'react'
import Card from '../../components/Card'
import BrandForm from './BrandForm'

export default function AddBrandPage() {

  return (
    <Card>
      <Card.Header
        title='Add New Brand'
        description='brands'
        href='/brands'
      />
      <BrandForm />
    </Card>
  )
}