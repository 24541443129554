import { useParams } from '@reach/router'
import React from 'react'
import Card from '../../components/Card'
import CategoryForm from './CategoryForm'

export default function EditCategoryPage(props) {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Category Page'
        description='categories'
        href='/categories'
      />
      <CategoryForm editForm id={id} />
    </Card>
  )
}