import { Router } from '@reach/router'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import axios from 'axios'
import 'instantsearch.css/themes/algolia.css'
import Cookies from 'js-cookie'
import React from 'react'
import toast from 'react-hot-toast'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import './assets/css/removeArrows.css'
import ProtectedRoute from './components/ProtectedRoute'
import SignInPage from './pages/auths/SignInPage'
import VerificationPage from './pages/auths/VerificationPage'
import AddBadgePage from './pages/badges/AddBadgePage'
import BadgesPage from './pages/badges/BadgesPage'
import EditBadgePage from './pages/badges/EditBadgePage'
import AddBankPage from './pages/banks/AddBankPage'
import BanksPage from './pages/banks/BanksPage'
import EditBankPage from './pages/banks/EditBankPage'
import AddBrandPage from './pages/brands/AddBrandPage'
import BrandsPage from './pages/brands/BrandsPage'
import EditBrandPage from './pages/brands/EditBrandPage'
import CartsPage from './pages/carts/CartsPage'
import AddCategoryPage from './pages/categories/AddCategoryPage'
import ArrangeCategoriesPage from './pages/categories/ArrangeCategoriesPage'
import CategoriesPage from './pages/categories/CategoriesPage'
import EditCategoryPage from './pages/categories/EditCategoryPage'
import AddCityPage from './pages/cities/AddCityPage'
import CitiesPage from './pages/cities/CitiesPage'
import EditCityPage from './pages/cities/EditCityPage'
import AddCollectionPage from './pages/collections/AddCollectionPage'
import CollectionsPage from './pages/collections/CollectionsPage'
import EditCollectionPage from './pages/collections/EditCollectionPage'
import AddConfigPage from './pages/configs/AddConfigPage'
import ConfigPage from './pages/configs/ConfigPage'
import EditConfigPage from './pages/configs/EditConfigPage'
import AddCountryPage from './pages/countries/AddCountryPage'
import CountriesPage from './pages/countries/CountriesPage'
import EditCountryPage from './pages/countries/EditCountryPage'
import DashboardPage from './pages/DashboardPage'
import AddDeliveryPage from './pages/deliveries/AddDeliveryPage'
import DeliveriesPage from './pages/deliveries/DeliveriesPage'
import EditDeliveryPage from './pages/deliveries/EditDeliveryPage'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import AddEntriesPage from './pages/inventories/AddEntriesPage'
import EditEntriesPage from './pages/inventories/EditEntriesPage'
import EntriesPage from './pages/inventories/EntriesPage'
import AddNotificationPage from './pages/notifications/AddNotificationPage'
import NotificationsPage from './pages/notifications/NotificationsPage'
import AddProductPage from './pages/products/AddProductPage'
import EditProductPage from './pages/products/EditProductPage'
import ProductsPage from './pages/products/ProductsPage'
import AddRolePage from './pages/roles/AddRolePage'
import EditRolePage from './pages/roles/EditRolePage'
import RolesPage from './pages/roles/RolesPage'
import SendSmsPage from './pages/sms/SendSmsPage'
import SmsPage from './pages/sms/SmsPage'
import AddSupplierPage from './pages/suppliers/AddSupplierPage'
import EditSupplierPage from './pages/suppliers/EditSupplierPage'
import SupplierPage from './pages/suppliers/SupplierPage'
import AddTownshipPage from './pages/townships/AddTownshipPage'
import EditTownshipPage from './pages/townships/EditTownshipPage'
import TownshipPage from './pages/townships/TownshipsPage'
import AddUserPage from './pages/users/AddUserPage'
import EditUserPage from './pages/users/EditUserPage'
import UsersPage from './pages/users/UsersPage'
import AddWarehousePage from './pages/warehouses/AddWarehousePage'
import EditWarehousePage from './pages/warehouses/EditWarehousePage'
import WarehousesPage from './pages/warehouses/WarehousesPage'
import OrdersPage from './pages/orders/OrdersPage'
import CreateOrderPage from './pages/orders/CreateOrderPage'
import EditOrderPage from './pages/orders/EditOrderPage'
import OrderForm from './pages/orders/OrderForm'
import getErrorMessage from './utils/getErrorMessage'
import {
  clientID,
  loading,
  loggedIn,
  userData,
  zcToken,
  cart,
} from './utils/recoil'
import PreferredDeliveryTimesPage from './pages/delivery-times/PreferredDeliveryTimesPage'
import AddDeliveryTimePage from './pages/delivery-times/AddDeliveryTimePage'
import EditDeliveryTimePage from './pages/delivery-times/EditDeliveryTimePage'
import DeliveryDates from './pages/delivery-dates/DeliveryDates'
import AddDeliveryDates from './pages/delivery-dates/AddDeliveryDates'
import EditDeliveryDates from './pages/delivery-dates/EditDeliveryDates'
import InvoicePage from './pages/orders/InvoicePage'
import PageBuilderPage from './pages/pages/PageBuilderPage'
import PromotionsPage from './pages/promotions/PromotionsPage'
import AddPromotionPage from './pages/promotions/AddPromotionPage'
import EditPromotionPage from './pages/promotions/EditPromotionPage'

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      Cookies.remove('zcToken')
    }
    return Promise.reject(error)
  }
)

function App() {
  const token = useRecoilValue(zcToken)
  const clientId = useRecoilValue(clientID)
  const setIsUserLoading = useSetRecoilState(loading)
  const setIsLoggedIn = useSetRecoilState(loggedIn)
  const setUserData = useSetRecoilState(userData)
  const setCart = useSetRecoilState(cart)
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  axios.defaults.headers.put['Content-Type'] = 'application/json'
  axios.defaults.baseURL = process.env.REACT_APP_MAIN_PRIVATE_URL

  const init = async () => {
    try {
      let headers = {}
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      }
      if (clientId) {
        headers['X-CLIENT-ID'] = clientId
      }
      axios.defaults.baseURL = process.env.REACT_APP_MAIN_PUBLIC_URL
      const { data } = await axios({
        method: 'POST',
        url: 'init',
        headers,
      })
      if (data.success) {
        axios.defaults.headers.common['X-CLIENT-ID'] = data.data.client_id
        Cookies.set('clientID', data.data.client_id, {
          expires: 365,
        })
        setCart(data.data.carts)
        if (data.data.user) {
          setUserData(data.data.user)
          setIsLoggedIn(true)
        }
        setIsUserLoading(false)
      }
    } catch (error) {
      setIsLoggedIn(false)
      setIsUserLoading(false)
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <>
      <Router>
        <ProtectedRoute as={DashboardPage} path='/' />
        <ProtectedRoute as={TownshipPage} path='/townships' />
        <ProtectedRoute as={AddTownshipPage} path='/townships/add' />
        <ProtectedRoute as={EditTownshipPage} path='/townships/:id/edit' />
        <ProtectedRoute as={ConfigPage} path='/configurations' />
        <ProtectedRoute as={AddConfigPage} path='/configurations/add' />
        <ProtectedRoute as={EditConfigPage} path='/configurations/:id/edit' />
        <ProtectedRoute as={NotificationsPage} path='/notifications' />
        <ProtectedRoute as={AddNotificationPage} path='/notifications/add' />
        <ProtectedRoute as={CategoriesPage} path='/categories' />
        <ProtectedRoute as={AddCategoryPage} path='/categories/add' />
        <ProtectedRoute as={ArrangeCategoriesPage} path='/categories/arrange' />
        <ProtectedRoute as={EditCategoryPage} path='/categories/:id/edit' />
        <ProtectedRoute as={ProductsPage} path='/products' />
        <ProtectedRoute as={AddProductPage} path='/products/add' />
        <ProtectedRoute as={EditProductPage} path='/products/:id/edit' />
        <ProtectedRoute as={SupplierPage} path='/suppliers' />
        <ProtectedRoute as={AddSupplierPage} path='/suppliers/add' />
        <ProtectedRoute as={EditSupplierPage} path='/suppliers/:id/edit' />
        <ProtectedRoute as={EntriesPage} path='/inventories/entries' />
        <ProtectedRoute as={AddEntriesPage} path='/inventories/entries/add' />
        <ProtectedRoute
          as={EditEntriesPage}
          path='/inventories/entries/:id/edit'
        />
        <ProtectedRoute as={BrandsPage} path='/brands' />
        <ProtectedRoute as={AddBrandPage} path='/brands/add' />
        <ProtectedRoute as={EditBrandPage} path='/brands/:id/edit' />
        <ProtectedRoute as={BanksPage} path='/banks' />
        <ProtectedRoute as={AddBankPage} path='/banks/add' />
        <ProtectedRoute as={EditBankPage} path='/banks/:id/edit' />
        <ProtectedRoute as={DeliveriesPage} path='/deliveries' />
        <ProtectedRoute as={AddDeliveryPage} path='/deliveries/add' />
        <ProtectedRoute as={EditDeliveryPage} path='/deliveries/:id/edit' />
        <ProtectedRoute as={UsersPage} path='/users' />
        <ProtectedRoute as={AddUserPage} path='/users/add' />
        <ProtectedRoute as={EditUserPage} path='/users/:id/edit' />
        <ProtectedRoute as={RolesPage} path='/roles' />
        <ProtectedRoute as={AddRolePage} path='/roles/add' />
        <ProtectedRoute as={EditRolePage} path='/roles/:id/edit' />
        <ProtectedRoute as={CountriesPage} path='/countries' />
        <ProtectedRoute as={AddCountryPage} path='/countries/add' />
        <ProtectedRoute as={EditCountryPage} path='/countries/:id/edit' />
        <ProtectedRoute as={SmsPage} path='/sms' />
        <ProtectedRoute as={SendSmsPage} path='/sms/send' />
        <ProtectedRoute as={CitiesPage} path='/cities' />
        <ProtectedRoute as={AddCityPage} path='/cities/add' />
        <ProtectedRoute as={EditCityPage} path='/cities/:id/edit' />
        <ProtectedRoute as={BadgesPage} path='/badges' />
        <ProtectedRoute as={AddBadgePage} path='/badges/add' />
        <ProtectedRoute as={EditBadgePage} path='/badges/:id/edit' />
        <ProtectedRoute as={CollectionsPage} path='/collections' />
        <ProtectedRoute as={AddCollectionPage} path='/collections/add' />
        <ProtectedRoute as={EditCollectionPage} path='/collections/:id/edit' />
        <ProtectedRoute as={WarehousesPage} path='/warehouses' />
        <ProtectedRoute as={AddWarehousePage} path='/warehouses/add' />
        <ProtectedRoute as={EditWarehousePage} path='/warehouses/:id/edit' />
        <ProtectedRoute as={OrdersPage} path='/orders' />
        <ProtectedRoute as={CreateOrderPage} path='/orders/add' />
        <ProtectedRoute as={EditOrderPage} path='/orders/:id/edit' />
        <ProtectedRoute as={InvoicePage} path='/orders/:id/invoice' />
        <ProtectedRoute
          as={PreferredDeliveryTimesPage}
          path='/delivery-times'
        />
        <ProtectedRoute as={AddDeliveryTimePage} path='/delivery-times/add' />
        <ProtectedRoute
          as={EditDeliveryTimePage}
          path='/delivery-times/:id/edit'
        />
        <ProtectedRoute as={DeliveryDates} path='/delivery-dates' />
        <ProtectedRoute as={AddDeliveryDates} path='/delivery-dates/add' />
        <ProtectedRoute as={EditDeliveryDates} path='/delivery-dates/:id/edit' />
        <ProtectedRoute as={PromotionsPage} path='/promotions' />
        <ProtectedRoute as={AddPromotionPage} path='/promotions/add' />
        <ProtectedRoute as={EditPromotionPage} path='/promotions/:id/edit' />
        <ProtectedRoute as={CartsPage} path='/carts' />

        <ProtectedRoute
          as={PageBuilderPage}
          path='/pages/builder'
          sidebar={false}
          header={false}
          blank
        />

        <ProtectedRoute as={Error403} path='/403' />
        <ProtectedRoute as={Error404} path='/*' />
        <SignInPage path='/signin' />
        <VerificationPage path='/verification' />
        {/* <AppConfigPage path='/configurations/app' /> */}
      </Router>
    </>
  )
}

export default App
