import React from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import Loading from '../components/Loading'
import getErrorMessage from '../utils/getErrorMessage'

export default function SingleSelect({ label, value, data, endpoint, className, onChange }) {
  const [options, setOptions] = React.useState(data || [])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchData = async () => {
    try {
      if (endpoint !== 'childCategories') {
        const { data } = await axios.get(endpoint)
        if (data.success) {
          setOptions(data.data)
        }
      } else {
        const { data } = await axios({
          method: 'GET',
          url: 'categories'
        })
        console.log('categories', data.data)
        if (data.success) {
          let childs = []
          data.data.map(data => {
            data.subs.map((sub) => childs.push(sub))
          })
          setOptions(childs)
        }
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    endpoint && fetchData()
  }, [])

  if (endpoint && isLoading) {
    return <Loading  className='offset-md-3' />
  }

  return (
    <select
      className={`custom-select ${className}`}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value=''>Choose {label}</option>
      {
        options.map((option, index) => (
          <option
            value={`${option.id}`}
            key={index}
          >{option.name}
          </option>
        ))
      }
    </select>
  )
}