import React from 'react'
import { navigate, useLocation } from '@reach/router'
import axios from 'axios'
import moment from 'moment'
import { parse } from 'query-string'
import {
  Edit as EditIcon,
  Eye as EyeIcon,
  Plus as PlusIcon,
  Printer as PrinterIcon,
  RefreshCw as ResetIcon,
  Trash2 as DeleteIcon,
} from 'react-feather'
import toast from 'react-hot-toast'
import { isEmpty, propertyOf } from 'underscore'
import DatePicker from 'react-datepicker'
import Modal from '../../common/Modal'
import Card from '../../components/Card'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import OrdersFilter from '../../components/filters/OrdersFilter'
import OffCanvas from '../../components/OffCanvas'
import OrderDetail from '../../components/OrderDetail'
import Pagination from '../../components/Pagination'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import initTooltip from '../../dashforge/dashforge.tooltip'
import {
  deliverySizes,
  orderStatus,
  paymentMethods,
  processingBatchNumbers,
} from '../../data/orders'
import useFilter from '../../hooks/useFilter'
import getErrorMessage from '../../utils/getErrorMessage'
import initOffcanvas from '../../dashforge/dashforge.offcanvas'

function getColorByStatus(status) {
  switch (status) {
    case 'pending':
      return 'secondary'
    case 'processing':
      return 'warning'
    case 'approved':
      return 'info'
    case 'cancelled':
      return 'danger'
    default:
      return 'success'
  }
}

export default function OrdersPage() {
  const location = useLocation()
  const searchParams = parse(location.search, {
    parseNumbers: true,
  })
  const [params, setParams] = React.useState({
    status: 'pending',
    page: 1,
    limit: 100,
    ...searchParams,
  })
  const [lastPage, setLastPage] = React.useState(1)
  const results = useFilter('orders', params, setLastPage)
  const [orders, setOrders] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [totals, setTotals] = React.useState({})
  const [townships, setTownships] = React.useState([])
  const [deliveries, setDeliveries] = React.useState([])
  const [deliveryTimes, setDeliveryTimes] = React.useState([])
  const [bulkOrderIds, setBulkOrderIds] = React.useState([])
  const [bulkUpdates, setBulkUpdates] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(true)
  const [showOrderDetail, setShowOrderDetail] = React.useState(false)
  const [showStatusDialog, setShowStatusDialog] = React.useState(false)
  const [showDeliveryDialog, setShowDeliveryDialog] = React.useState(false)
  const [isToggleFilter, setIsToggleFilter] = React.useState(false)
  const [showDatePicker, setShowDatePicker] = React.useState(false)

  const onSelectionChange = (orderId) => {
    if (bulkOrderIds.includes(orderId)) {
      setBulkOrderIds(bulkOrderIds.filter((id) => id !== orderId))
    } else {
      setBulkOrderIds((prev) => [...prev, orderId])
    }
  }

  const onAllSelectionChange = () => {
    if (bulkOrderIds.length && bulkOrderIds.length === orders.length) {
      setBulkOrderIds([])
    } else {
      setBulkOrderIds(orders.map((order) => order.id))
    }
  }

  const handleBulkUpdate = async (name, value) => {
    try {
      console.log({ name, value })
      const { data } = await axios({
        method: 'PUT',
        url: 'orders',
        data: {
          orders: bulkOrderIds,
          ...bulkUpdates,
          [name]: value,
        },
      })
      if (data.success) {
        fetchOrders()
        fetchRequirements()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  const handleBulkDelete = async () => {
    try {
      const { data } = await axios({
        method: 'DELETE',
        url: 'orders',
        data: {
          orders: bulkOrderIds,
        },
      })
      if (data.success) {
        const temp = orders.filter((order) => !bulkOrderIds.includes(order.id))
        setOrders(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            orderNumber: data.order_number,
            name: data.user.name,
            total: data.total,
            initialAmount: data.initial_amount,
            afterCancellationAmount: data.after_cancellation_amount,
            paymentMethod: data.payment_method,
            paymentStatus: data.payment_status,
            deliveryDate: data.delivery_date,
            deliveryId: data.delivery.id,
            deliveryName: data.delivery.name,
            deliverySize: data.delivery_size,
            processingBatchNumber: data.processing_batch_number,
            preferredDeliveryTimeId: data.time_range.id,
            preferredDeliveryTimeName: data.time_range.name,
            address: data.address.address,
            township: data.address.township.name,
            city: data.address.city.name,
            status: data.status,
            platform: data.platform,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  const handleBulkSendCustomSMS = async () => {
    try {
      console.log('handle send bulk custom sms')
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  const handleBulkPrintInvoices = async () => {
    try {
      console.log('handle print invoices')
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  const handleBulkPrintPickList = async () => {
    try {
      console.log('handle print bulk print picklist')
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  const onChangeFilter = (name, value) => {
    if (params[name] !== value || name === 'page') {
      setParams((prev) => ({
        ...prev,
        [name]: value,
      }))
    } else {
      const temp = { ...params }
      delete temp[name]
      setParams(temp)
    }
  }

  const editOrder = async (
    id,
    index,
    name,
    value,
    defaultValue,
    setShowPopover
  ) => {
    try {
      console.log({
        name,
        value: value || defaultValue,
      })
      const { data } = await axios({
        method: 'PUT',
        url: `orders/${id}/partial-update`,
        data: {
          name,
          value: value || defaultValue,
        },
      })
      console.log('partial : ', data.data)
      if (data.success) {
        const temp = [...orders]
        temp[index] = data.data
        setOrders(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            orderNumber: data.order_number,
            name: data.user.name,
            total: data.total,
            initialAmount: data.initial_amount,
            afterCancellationAmount: data.after_cancellation_amount,
            paymentMethod: data.payment_method,
            paymentStatus: data.payment_status,
            deliveryDate: data.delivery_date,
            deliveryId: data.delivery.id,
            deliveryName: data.delivery.name,
            deliverySize: data.delivery_size,
            processingBatchNumber: data.processing_batch_number,
            preferredDeliveryTimeId: data.time_range.id,
            preferredDeliveryTimeName: data.time_range.name,
            address: data.address.address,
            township: data.address.township.name,
            city: data.address.city.name,
            status: data.status,
            platform: data.platform,
          }))
        )
        toast.success('Success')
        setShowPopover(false)
        fetchRequirements()
      }
    } catch (error) {
      console.log({ error })
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const fetchRequirements = async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: 'orders/filters',
      })
      if (data.success) {
        setTownships(data.data.townships)
        setDeliveries(data.data.deliveries)
        setDeliveryTimes(data.data.timeranges)
        setTotals(data.data.totals)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  const fetchOrders = async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: 'orders',
      })
      console.log('orders', data.data)
      if (data.success) {
        setOrders(data.data)
        setRows(
          data.data.map((data) => ({
            id: data.id,
            orderNumber: data.order_number,
            name: data.user.name,
            total: data.total,
            initialAmount: data.initial_amount,
            afterCancellationAmount: data.after_cancellation_amount,
            paymentMethod: data.payment_method,
            paymentStatus: data.payment_status,
            deliveryDate: data.delivery_date,
            deliveryId: data.delivery?.id,
            deliveryName: data.delivery?.name,
            deliveryName: data.delivery?.name,
            deliverySize: data.delivery_size,
            processingBatchNumber: data.processing_batch_number,
            preferredDeliveryTimeId: data.time_range.id,
            preferredDeliveryTimeName: data.time_range.name,
            address: data.address.address,
            township: data.address.township.name,
            city: data.address.city.name,
            status: data.status,
            platform: data.platform,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreOrder = async (id, index) => {
    try {
      const { data } = await axios({
        method: 'POST',
        url: `orders/${id}/restore`,
      })
      if (data.success) {
        const temp = [...orders]
        temp[index] = data.data
        setOrders(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            orderNumber: data.order_number,
            name: data.user.name,
            total: data.total,
            initialAmount: data.initial_amount,
            afterCancellationAmount: data.after_cancellation_amount,
            paymentMethod: data.payment_method,
            paymentStatus: data.payment_status,
            deliveryDate: data.delivery_date,
            deliveryId: data.delivery.id,
            deliveryName: data.delivery.name,
            deliverySize: data.delivery_size,
            processingBatchNumber: data.processing_batch_number,
            preferredDeliveryTimeId: data.time_range.id,
            preferredDeliveryTimeName: data.time_range.name,
            address: data.address.address,
            township: data.address.township.name,
            city: data.address.city.name,
            status: data.status,
            platform: data.platform,
          }))
        )
        toast.remove()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  const deleteOrder = async (id, index) => {
    try {
      const { data } = await axios({
        method: 'DELETE',
        url: `orders/${id}`,
      })
      console.log('delete order: ', data)
      if (data.success) {
        const temp = [...orders]
        temp.splice(index, 1)
        setOrders(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            orderNumber: data.order_number,
            name: data.user.name,
            total: data.total,
            initialAmount: data.initial_amount,
            afterCancellationAmount: data.after_cancellation_amount,
            paymentMethod: data.payment_method,
            paymentStatus: data.payment_status,
            deliveryDate: data.delivery_date,
            deliveryId: data.delivery.id,
            deliveryName: data.delivery.name,
            deliverySize: data.delivery_size,
            processingBatchNumber: data.processing_batch_number,
            preferredDeliveryTimeId: data.time_range.id,
            preferredDeliveryTimeName: data.time_range.name,
            address: data.address.address,
            township: data.address.township.name,
            city: data.address.city.name,
            status: data.status,
            platform: data.platform,
          }))
        )
        toast(
          <UndoAction
            onClick={() => restoreOrder(id, index)}
            name={`Order ${orders[index].id}`}
          />
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  const confirmOrder = async (id, index) => {
    console.log({ index })
    try {
      const { data } = await axios.put(`orders/${id}/partial-update`, {
        name: 'status',
        value: 'processing',
      })
      if (data.success) {
        const temp = [...orders]
        temp.splice(index, 1)
        setOrders(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            orderNumber: data.order_number,
            name: data.user.name,
            total: data.total,
            initialAmount: data.initial_amount,
            afterCancellationAmount: data.after_cancellation_amount,
            paymentMethod: data.payment_method,
            paymentStatus: data.payment_status,
            deliveryDate: data.delivery_date,
            deliveryId: data.delivery.id,
            deliveryName: data.delivery.name,
            deliverySize: data.delivery_size,
            processingBatchNumber: data.processing_batch_number,
            preferredDeliveryTimeId: data.time_range.id,
            preferredDeliveryTimeName: data.time_range.name,
            address: data.address.address,
            township: data.address.township.name,
            city: data.address.city.name,
            status: data.status,
            platform: data.platform,
          }))
        )
        fetchRequirements()
      }
      console.log('confirm order', data)
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  const askConfirmation = (onYes) => {
    const isConfirm = window.confirm('Are u sure?')
    if (isConfirm) {
      onYes()
    }
  }

  React.useEffect(() => {
    setRows([])
    if (results.length) {
      setIsLoading(false)
      setOrders(results)
      setRows(
        results.map((data) => ({
          id: data.id,
          orderNumber: data.order_number,
          name: data.user.name,
          total: data.total,
          initialAmount: data.initial_amount,
          afterCancellationAmount: data.after_cancellation_amount,
          paymentMethod: data.payment_method,
          paymentStatus: data.payment_status,
          deliveryDate: data.delivery_date,
          deliveryId: data.delivery?.id,
          deliveryName: data.delivery?.name,
          deliverySize: data.delivery_size,
          processingBatchNumber: data.processing_batch_number,
          preferredDeliveryTimeId: data.time_range.id,
          preferredDeliveryTimeName: data.time_range.name,
          address: data.address.address,
          township: data.address.township.name,
          city: data.address.city.name,
          status: data.status,
          platform: data.platform,
        }))
      )
    }
  }, [results])

  React.useEffect(() => {
    fetchRequirements()
    if (!isEmpty(params)) {
      let queries = []
      for (let property in params) {
        if (params[property]) {
          queries.push(`${property}=${params[property]}`)
        }
      }
      const queryString = queries.join('&')
      navigate(`orders?${queryString}`)
    } else {
      navigate(`orders`)
    }
  }, [params])

  React.useEffect(() => {
    if (!isLoading) {
      initTooltip()
    }
  }, [isLoading])

  React.useEffect(() => {
    initOffcanvas(setIsToggleFilter)
  }, [isToggleFilter])

  return (
    <Card>
      <div className='card-header bd-b-0'>
        <div className='row mg-b-5'>
          <div className='col-md-2 col-form-label'>Status</div>
          {orderStatus.map((status, index) => (
            <button
              className={`filter-btn btn btn-xs rounded-pill btn-${getColorByStatus(
                status.name.toLowerCase()
              )} mg-b-5 mg-r-5 mg-l-5 ${
                propertyOf(params)('status') &&
                propertyOf(params)('status') === status.id
                  ? 'active'
                  : ''
              }`}
              key={index}
              onClick={() => onChangeFilter('status', status.id)}
            >
              {status.name}
              <span className='badge badge-light mg-l-5'>
                {totals[status.id]}
              </span>
            </button>
          ))}
        </div>
        <div className='row mg-b-5'>
          <div className='col-md-2 col-form-label'>Delivery</div>
          {deliveries.map((delivery, index) => (
            <button
              className={`filter-btn btn btn-xs rounded-pill btn-outline-light mg-b-5 mg-l-5 mg-r-5 ${
                propertyOf(params)('delivery[]') &&
                propertyOf(params)('delivery[]') === delivery.id
                  ? 'active'
                  : ''
              }`}
              key={index}
              onClick={() => onChangeFilter('delivery[]', delivery.id)}
            >
              {delivery.name}
            </button>
          ))}
        </div>
        <div className='row mg-b-5'>
          <div className='col-md-2 col-form-label'>Delivery Date</div>
          <button
            className={`filter-btn btn btn-xs rounded-pill btn-outline-light mg-b-5 mg-l-5 mg-r-5 ${
              propertyOf(params)('date') &&
              propertyOf(params)('date') ===
                moment(new Date()).format('YYYY-MM-DD')
                ? 'active'
                : ''
            }`}
            onClick={() => {
              setShowDatePicker(false)
              onChangeFilter('date', moment(new Date()).format('YYYY-MM-DD'))
            }}
          >
            Today
          </button>
          <button
            className={`filter-btn btn btn-xs rounded-pill btn-outline-light mg-b-5 mg-l-5 mg-r-5 ${
              propertyOf(params)('date') &&
              propertyOf(params)('date') ===
                moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
                ? 'active'
                : ''
            }`}
            onClick={() => {
              setShowDatePicker(false)
              onChangeFilter(
                'date',
                moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
              )
            }}
          >
            Tomorrow
          </button>
          <button
            className={`filter-btn btn btn-xs rounded-pill btn-outline-light mg-b-5 mg-l-5 mg-r-5 ${
              propertyOf(params)('date') &&
              propertyOf(params)('date') !==
                moment(new Date()).format('YYYY-MM-DD') &&
              propertyOf(params)('date') !==
                moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
                ? 'active'
                : ''
            }`}
            onClick={() => setShowDatePicker(true)}
          >
            Custom
          </button>
          {showDatePicker && (
            <DatePicker
              selected={new Date()}
              className='form-control filter-input form-control-sm'
              onChange={(date) =>
                onChangeFilter('date', moment(date).format('YYYY-MM-DD'))
              }
            />
          )}
        </div>
        <div className='row mg-b-5'>
          <div className='col-md-2 col-form-label'>Townships</div>
          <div className='col-md-10'>
            <div className='row'>
              {townships.map((township, index) => (
                <button
                  className={`filter-btn btn btn-xs rounded-pill btn-outline-light mg-b-5 mg-l-5 mg-r-5 ${
                    propertyOf(params)('townships[]') &&
                    propertyOf(params)('townships[]') === township.id
                      ? 'active'
                      : ''
                  }`}
                  key={index}
                  onClick={() => onChangeFilter('townships[]', township.id)}
                >
                  {township.name}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className='row mg-b-20'>
          <div className='col-md-2 col-form-label'>Phone</div>
          <div className='col-md-3 pd-l-0 mg-l-5'>
            <input
              type='number'
              placeholder='09'
              value={!isEmpty(params) ? params['phone'] : ''}
              className='form-control filter-input form-control-sm'
              onChange={(e) => onChangeFilter('phone', e.target.value)}
            />
          </div>
        </div>
        <div className='row mg-b-5'>
          <div className='offset-md-2'>
            <button
              className='btn btn-xs rounded-pill action-btn btn-outline-light mg-b-5 mg-l-5 mg-r-5'
              onClick={() => {
                setIsToggleFilter((prev) => true)
                setParams([])
              }}
            >
              <PlusIcon className='mg-r-5' />
              Additional Filters
            </button>
            <button
              className='btn btn-xs rounded-pill action-btn btn-outline-light mg-b-5'
              onClick={() =>
                setParams({
                  page: 1,
                  limit: 100,
                })
              }
            >
              <ResetIcon className='mg-r-5' />
              Reset Filter
            </button>
          </div>
        </div>
        {isToggleFilter && (
          <OffCanvas>
            <OrdersFilter
              params={params}
              setParams={setParams}
              onChangeFilter={onChangeFilter}
            />
          </OffCanvas>
        )}

        <div className='d-flex justify-content-between'>
          <div className='dropdown'>
            <button
              type='button'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup={true}
              aria-expanded={false}
              disabled={!bulkOrderIds.length}
              className={`btn btn-sm dropdown-toggle btn-uppercase btn-white mg-t-sm-5 mg-r-5 pd-x-15 btn-xs`}
            >
              Bulk Actions
            </button>
            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
              <div className='dropdown-submenu'>
                <a className='dropdown-item'>Set Status</a>
                <ul className='dropdown-menu'>
                  {orderStatus.map((status, index) => (
                    <div key={index}>
                      <li className='dropdown-item'>
                        <a
                          //  onClick={() => askConfirmation(() => handleBulkUpdate('status', status.id))}
                          onClick={() => setShowStatusDialog(true)}
                        >
                          {status.name}
                        </a>
                        {showStatusDialog && (
                          <ConfirmationDialog
                            id='setStatus'
                            title='Are you Sure?'
                            content='Are you sure want to set Status?'
                            btnTitle='Update'
                            onClick={() =>
                              handleBulkUpdate('status', status.id)
                            }
                            onDismiss={() => setShowStatusDialog(false)}
                          />
                        )}
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
              <div className='dropdown-submenu'>
                <a className='dropdown-item'>Set Delivery</a>
                <ul className='dropdown-menu'>
                  {deliveries.map((delivery, index) => (
                    <div key={index}>
                      <li className='dropdown-item'>
                        <a
                          data-toggle='modal'
                          data-target='#setDelivery'
                          data-dismiss='modal'
                          // onClick={() => askConfirmation(() => handleBulkUpdate('delivery_id', delivery.id))}
                        >
                          {delivery.name}
                        </a>
                        {/* <Modal id='setDelivery'>
                            <ConfirmationDialog
                              title='Are you Sure?'
                              content='Are you sure want to set Delivery?'
                              btnTitle='Update'
                              onClick={() => handleBulkUpdate('delivery_id', delivery.id)}
                            />
                          </Modal> */}
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
              <div className='dropdown-submenu'>
                <a className='dropdown-item'>Set Processing Batch Number</a>
                <ul className='dropdown-menu'>
                  {processingBatchNumbers.map(
                    (processingBatchNumber, index) => (
                      <div key={index}>
                        <li className='dropdown-item'>
                          <a
                            onClick={() => {
                              askConfirmation(() =>
                                handleBulkUpdate(
                                  'processing_batch_number',
                                  processingBatchNumber.id
                                )
                              )
                            }}
                          >
                            {processingBatchNumber.name}
                          </a>
                        </li>
                      </div>
                    )
                  )}
                </ul>
              </div>
              <a
                className='dropdown-item'
                onClick={() => askConfirmation(handleBulkSendCustomSMS)}
              >
                Send Custom SMS
              </a>
              <a
                className='dropdown-item'
                onClick={() => askConfirmation(handleBulkPrintInvoices)}
              >
                Print Invoices
              </a>
              <a
                className='dropdown-item'
                onClick={() => askConfirmation(handleBulkPrintPickList)}
              >
                Print Pick List
              </a>
              <a
                className='dropdown-item'
                onClick={() => askConfirmation(handleBulkDelete)}
              >
                Delete
              </a>
            </div>
          </div>
          <div>
            <button
              className={`btn btn-sm btn-uppercase btn-primary mg-t-sm-5 mg-r-5 pd-x-15 btn-xs`}
              onClick={() => navigate('/orders/add')}
            >
              <PlusIcon />
              Add New Order
            </button>
          </div>
        </div>
      </div>
      <Table
        onAllChange={onAllSelectionChange}
        onChange={onSelectionChange}
        onClick={editOrder}
        allData={orders}
        bulkIds={bulkOrderIds}
        rows={rows}
        cols={[
          {
            width: '50px',
            title: {
              render: {
                type: 'checkbox',
                key: 'id',
                className: 'cursor-pointer',
              },
            },
            render: {
              type: 'checkbox',
              key: 'id',
            },
          },
          {
            title: 'ID',
            key: 'orderNumber',
            width: '100px',
            subs: [
              {
                key: 'id',
                className: 'tx-color-03',
              },
            ],
          },
          {
            title: 'Customer',
            key: 'name',
            subs: [
              {
                render: {
                  type: 'bullet',
                  key: 'platform',
                },
              },
            ],
          },
          {
            title: 'Total',
            key: 'total',
            unit: 'ks',
            subs: [
              {
                render: {
                  type: 'bullet tooltips',
                  key: 'initialAmount',
                  className: 'tx-color-03 cursor-help align-bottom',
                  unit: 'ks',
                },
              },
              {
                render: {
                  type: 'bullet tooltips',
                  key: 'afterCancellationAmount',
                  className: 'tx-color-03 cursor-help align-bottom',
                  unit: 'ks',
                },
              },
            ],
          },
          {
            title: 'Payment',
            key: 'paymentMethod',
            editable: {
              type: 'select',
              name: 'payment_method',
              label: 'Payment Method',
              options: paymentMethods,
            },
            subs: [
              {
                key: 'paymentStatus',
                className: 'tx-color-03',
              },
            ],
          },
          {
            title: 'Location',
            render: {
              type: 'tooltips',
              key: 'address',
              className:
                'text-truncate d-inline-block wd-100 cursor-help align-bottom',
            },
            subs: [
              {
                key: 'township',
                className: 'tx-color-03',
                unit: ',',
              },
              {
                key: 'city',
                className: 'tx-color-03',
              },
            ],
          },
          {
            title: 'Delivery Time',
            key: 'deliveryDate',
            editable: {
              type: 'date',
              name: 'delivery_date',
            },
            subs: [
              {
                key: 'preferredDeliveryTimeId',
                name: 'preferredDeliveryTimeName',
                className: 'tx-color-02',
                editable: {
                  type: 'select',
                  name: 'time_range_id',
                  options: deliveryTimes,
                },
              },
            ],
          },
          {
            title: 'Delivery',
            key: 'deliveryId',
            name: 'deliveryName',
            editable: {
              type: 'select',
              name: 'delivery_id',
              options: deliveries,
            },
            subs: [
              {
                key: 'deliverySize',
                className: 'tx-color-03',
                editable: {
                  type: 'select',
                  name: 'delivery_size',
                  options: deliverySizes,
                },
                render: {
                  type: 'bullet',
                  key: 'deliverySize',
                },
              },
              {
                key: 'processingBatchNumber',
                className: 'tx-color-03',
                editable: {
                  type: 'number',
                  name: 'processing_batch_number',
                },
                render: {
                  type: 'bullet',
                  key: 'processingBatchNumber',
                },
              },
            ],
          },
          {
            title: 'Status',
            key: 'status',
            width: '100px',
            editable: {
              type: 'select',
              name: 'status',
              options: orderStatus,
            },
          },
          {
            title: 'Detail',
            width: '100px',
            render: (id, index) => (
              <>
                <button
                  className='btn btn-xxs btn-primary btn-icon '
                  // data-toggle='modal'
                  // data-target={`#orderdetail${id}`}
                  // data-dismiss='modal'
                  onClick={() => setShowOrderDetail(true)}
                >
                  <EyeIcon />
                </button>
                {showOrderDetail && (
                  <Modal
                    id={`orderdetail${id}`}
                    modalSize='modal-lg'
                    onDismiss={() => setShowOrderDetail(false)}
                  >
                    <Modal.Header>
                      <h3 className='modal-title'>
                        #{orders[index].order_number}
                      </h3>
                      <div>
                        <button
                          className={`btn btn-icon btn-xxs mg-r-5 btn-secondary`}
                          onClick={() => navigate(`orders/${id}/invoice`)}
                        >
                          <PrinterIcon />
                        </button>
                        <button
                          className={`btn btn-icon btn-xxs mg-r-5 btn-info`}
                          onClick={() => navigate(`orders/${id}/edit`)}
                        >
                          <EditIcon />
                        </button>
                        <button
                          className={`btn btn-icon btn-xxs mg-r-5 btn-danger`}
                          onClick={() => deleteOrder(id, index)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </Modal.Header>
                    <Modal.Body>
                      <OrderDetail id={id} />
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <button
                          type='button'
                          className='btn btn-primary'
                          onDimiss={() => setShowOrderDetail(false)}
                        >
                          Close
                        </button>
                      </Modal.Footer> */}
                  </Modal>
                )}
              </>
            ),
          },
          {
            title: 'Actions',
            width: '200px',
            className: 'right-fixed',
            render: {
              type: 'actions',
              btns: [
                {
                  variant: 'btn-primary',
                  className: 'right-fixed',
                  onClick: (id, index) => confirmOrder(id, index),
                },
                {
                  icon: PrinterIcon,
                  variant: 'btn-secondary',
                  className: 'right-fixed',
                  onClick: (id) => navigate(`orders/${id}/invoice`),
                },
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  className: 'right-fixed',
                  onClick: (id) => navigate(`orders/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  className: 'right-fixed',
                  onClick: deleteOrder,
                },
              ],
            },
          },
        ]}
      />
      <Pagination
        page={searchParams.page}
        lastPage={lastPage}
        onChangeFilter={onChangeFilter}
      />
    </Card>
  )
}
