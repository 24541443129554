import React from 'react'
import { navigate } from '@reach/router'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import arrayMove from 'array-move'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import moment from 'moment'
import { SketchPicker } from 'react-color'
import { Calendar as CalendarIcon } from 'react-feather'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import AlgoliaModal from '../../common/AlgoliaModal'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import FileUpload from '../../components/FileUpload'
import LoadingSpinner from '../../components/LoadingSpinner'
import SortableList from '../../components/SortableList'
import SortableProduct from '../../components/SortableProduct'
import { searchClient } from '../../data/searchClient'
import getErrorMessage from '../../utils/getErrorMessage'

export default function BadgeForm({ editForm, id }) {
  const [name, setName] = React.useState('')
  const [image, setImage] = React.useState([])
  const [textColor, setTextColor] = React.useState('#00000')
  const [backgroundColor, setBackgroundColor] = React.useState('#ffffff')
  const [dateRange, setDateRange] = React.useState([new Date(), new Date()])
  const [isActive, setIsActive] = React.useState(true)
  const [priority, setPriority] = React.useState(0)
  const [products, setProducts] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [showTextColorPicker, setShowTextColorPicker] = React.useState(false)
  const [showBgColorPicker, setShowBgColorPicker] = React.useState(false)
  const [showProductPicker, setShowProductPicker] = React.useState(false)

  const badgeSchema = Yup.object({
    name: Yup.string()
      .required('Name Required'),
  })

  const fetchBadge = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`badges/${id}`)
      console.log('badge detail', data)
      if (data.success) {
        const badge = data.data
        setName(badge.name)
        setImage([{
          id: badge.files[0].id,
          url: `${process.env.REACT_APP_IMAGE_API_URL}${badge.files[0].url}_100.jpg`,
          status: 'success',
        }])
        setBackgroundColor(badge.background_color)
        setTextColor(badge.text_color)
        setDateRange([moment(badge.start).format('YYYY-MM-DD'), moment(badge.end).format('YYYY-MM-DD')])
        setIsActive(Boolean(badge.is_active))
        setPriority(badge.priority)
        setProducts(badge.products)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }
  console.log('products', products)
  const onSubmit = async ({
    name,
    backgroundColor,
    textColor,
    priority,
    isActive,
  }) => {
    try {
      const endpoint = editForm ? `badges/${id}` : `badges`
      const method = editForm ? 'PUT' : 'POST'
      const body = {
        name,
        files: image.map((file) => file.id),
        background_color: backgroundColor,
        text_color: textColor,
        start: moment(dateRange[0]).format('YYYY-MM-DD'),
        end: moment(dateRange[1]).format('YYYY-MM-DD'),
        priority,
        image: 'test_image',
        is_active: isActive,
        products: products.map(product => product.id),
      }
      const { data } = await axios({
        method,
        url: endpoint,
        data: body,
      })
      console.log('res', data.data)
      if (data.success) {
        toast.success('Success')
        navigate('/badges')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const handleProductsChange = (hit) => {
    const isProductExisted = products.find((product) => product.id === hit.id)
    if (isProductExisted) {
      setProducts(products.filter((product) => product.id !== hit.id))
    } else {
      setProducts(prev => [...prev, hit])
    }
  }

  const deleteProduct = (id) => {
    const remainingProducts = products.filter((product) => (product.id !== id))
    setProducts(remainingProducts)
    console.log('remaining', remainingProducts)
  }

  const isChecked = (hitId) => {
    return products.find((product) => product.id === hitId)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setProducts(arrayMove(products, oldIndex, newIndex))
  };


  console.log('products', products)

  React.useEffect(() => {
    editForm && fetchBadge()
  }, [])

  if (editForm && isLoading) {
    return (
      <LoadingSpinner />
    )
  }

  return (
    <Container>
      <Formik
        initialValues={{
          name,
          backgroundColor,
          textColor,
          isActive,
          priority,
          products
        }}
        validationSchema={badgeSchema}
        onSubmit={(values) => {
          onSubmit(values)
        }}
      >
        {
          ({ values, isSubmitting, setFieldValue, handleBlur, handleChange }) => (
            <Form className='pos-relative'>

              <div className='form-group row'>
                <div className='col-md-6 offset-md-3 tx-center'>
                  <span className='pd-x-10 pd-y-5 badge rounded-pill'
                    style={{
                      color: values.textColor,
                      backgroundColor: values.backgroundColor
                    }}>
                    {values.name}
                  </span>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Name
                </label>
                <div className='col-md-6'>
                  <Field
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='New'
                    autoFocus
                  />
                  <ErrorComponent>
                    <ErrorMessage name='name' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  Image
              </label>
                <div className='col-md-6'>
                  <FileUpload
                    name='image'
                    id={id}
                    relatedType='badges'
                    multiple={false}
                    photos={image}
                    setPhotos={setImage}
                  />
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  Text Color
                </label>
                <div className='col-md-3'>
                  <button
                    type='button'
                    className='btn btn-light btn-block'
                    onClick={() => setShowTextColorPicker(!showTextColorPicker)}
                  >
                    {
                      showTextColorPicker ? 'Close Color' : 'Open Color'
                    }
                  </button>
                  {
                    showTextColorPicker && (
                      <SketchPicker
                        name='textColor'
                        className='mg-t-2'
                        color={values.textColor}
                        onChange={color => setFieldValue('textColor', color.hex)}
                      />
                    )
                  }
                </div>
                <div className='col-md-3'>
                  <button
                    type='button'
                    className='btn btn-light btn-block'
                    onClick={() => setShowBgColorPicker(!showBgColorPicker)}
                  >
                    {
                      showBgColorPicker ? 'Close Background' : 'Open Background'
                    }
                  </button>
                  {
                    showBgColorPicker && (
                      <SketchPicker
                        name='backgroundColor'
                        className='mg-t-2'
                        color={values.backgroundColor}
                        onChange={(color) => setFieldValue('backgroundColor', color.hex)}
                      />
                    )
                  }
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  Date
                </label>
                <div className='col-md-4'>
                  <DateRangePicker
                    value={dateRange}
                    onChange={(dateRange) => setDateRange(dateRange)}
                    calendarIcon={<CalendarIcon color='#1b2e4b' />}
                  />
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  Products
                </label>
                <div className='col-md-3'>
                  <button
                    type='button'
                    className='btn btn-light btn-block'
                    onClick={() => setShowProductPicker(true)}
                  >Pick Products
                </button>
                  {
                    showProductPicker && (
                      <AlgoliaModal
                        id='modal'
                        searchClient={searchClient}
                        indexName='products_index'
                        onChange={handleProductsChange}
                        onCheck={isChecked}
                        onDismiss={() => setShowProductPicker(false)}
                      />
                    )
                  }
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label' >Priority</label>
                <div className='col-md-3'>
                  <Field
                    name='priority'
                    type='number'
                    className='form-control'
                    placeholder='1'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-4 offset-md-3'>
                  <div className='custom-switch custom-control'>
                    <input
                      name='isActive'
                      id='isActive'
                      className='custom-control-input '
                      type='checkbox'
                      value={values.isActive}
                      checked={values.isActive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor='isActive' className='custom-control-label'>Active</label>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-8 offset-md-3'>
                  <SortableList
                    items={products}
                    axis='y'
                    onDelete={deleteProduct}
                    onSortEnd={onSortEnd}
                    renderItemComponent={SortableProduct}
                    className='pd-0'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-6 offset-md-3'>
                  <button
                    className='btn btn-primary btn-uppercase btn-block'
                    type='submit'>
                    {isSubmitting ? 'Submitting' : 'submit'}
                  </button>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </Container>
  )
}