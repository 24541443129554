import React from 'react';
import MultiSelect from '../../common/MultiSelect'
import SingleSelect from '../../common/SingleSelect'
import axios from 'axios'
import getErrorMessage from '../../utils/getErrorMessage'
import toast from 'react-hot-toast'
import LoadingSpinner from '../LoadingSpinner';
import {
  RefreshCw as ResetIcon,
} from 'react-feather'
import { isEmpty } from 'underscore';
import { productTypes as types } from '../../data/types'

export default function ProductsFilter({
  params,
  setParams,
  setRows,
}) {
  const formRef = React.useRef(null)
  const [isLoading, setIsLoading] = React.useState(true);
  const [defaultCategories, setDefaultCategories] = React.useState([])
  console.log({ defaultCategories })

  const onChangeFilter = (name, value) => {
    setParams((params) => ({
      ...params,
      [name]: value,
    }))
    setRows([])
  }

  const fetchCategories = async () => {
    try {
      const { data } = await axios.get('categories')
      console.log({data})
      if (data.success) {
        if (!isEmpty(params['categories'])) {
          setDefaultCategories(
            data.data
              .filter((category) => params['categories'].includes(category.id))
              .map((data) => ({ label: data.name, value: data.id }))
          )
        }
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    fetchCategories()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <form className='form pd-15' ref={formRef}>
      <div className='form-group row'>
        <div className='col-12'>
          <label className='mg-b-0'>Name</label>
        </div>
        <div className='col-12'>
          <input
            type='text'
            className='form-control form-control-sm'
            value={params['name']}
            onChange={(e) => onChangeFilter('name', e.target.value)}
          />
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-12'>
          <label className='mg-b-0'>SKU</label>
        </div>
        <div className='col-12'>
          <input
            type='text'
            className='form-control form-control-sm'
            value={params['sku']}
            onChange={(e) => onChangeFilter('sku', e.target.value)}
          />
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-12'>
          <label className='mg-b-0'>Purchase Location</label>
        </div>
        <div className='col-12'>
          <input
            type='text'
            className='form-control form-control-sm'
            value={params['purchase_location']}
            onChange={(e) => onChangeFilter('purchase_location', e.target.value)}
          />
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-12'>
          <label className='mg-b-0'>Categories</label>
        </div>
        <div className='col-12'>
          <MultiSelect
            endpoint='childCategories'
            className='pd-0'
            value={defaultCategories || []}
            onChange={
              (categories) => onChangeFilter('categories', categories.map((category) => category.value))
            }
          />
        </div>
      </div>
      <div className='form-group row' id='essentials'>
        <div className='col-12'>
          <label className='mg-b-0'>Type</label>
        </div>
        <div className='col-12'>
          <SingleSelect
            label='Type'
            data={types}
            value={params['type']}
            onChange={(typeId) => onChangeFilter('type', typeId)}
          />
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-12'>
          <label className='mg-b-0'>Price</label>
        </div>
        <div className='col-6'>
          <input
            type='text'
            className='form-control form-control-sm'
            placeholder='min'
            value={params['price[min]']}
            onChange={(e) => onChangeFilter('price[min]', e.target.value)}
          />
        </div>
        <div className='col-6'>
          <input
            type='text'
            placeholder='max'
            className='form-control form-control-sm'
            value={params['price[max]']}
            onChange={(e) => onChangeFilter('price[max]', e.target.value)}
          />
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-12'>
          <div className='custom-switch custom-control'>
            <input
              id='outOfStock'
              className='custom-control-input '
              type='checkbox'
              checked={params['out_of_stock'] ? true : false}
              value={params['out_of_stock'] ? true : false}
              onChange={() => onChangeFilter('out_of_stock', !params['out_of_stock'])}
            />
            <label
              htmlFor='outOfStock'
              className='custom-control-label'>
              Out Of Stock
            </label>
          </div>
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-12'>
          <div className='custom-switch custom-control'>
            <input
              id='isActive'
              className='custom-control-input '
              type='checkbox'
              checked={params['is_active'] ? true : false}
              value={params['is_active'] ? true : false}
              onChange={(e) => onChangeFilter('is_active', !params['is_active'])}
            />
            <label
              htmlFor='isActive'
              className='custom-control-label'>
              Active
            </label>
          </div>
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-12'>
          <div className='custom-switch custom-control'>
            <input
              id='isHighlight'
              className='custom-control-input '
              type='checkbox'
              checked={params['is_highlight'] ? true : false}
              value={params['is_highlight'] ? true : false}
              onChange={(e) => onChangeFilter('is_highlight', !params['is_highlight'])}
            />
            <label
              htmlFor='isHighlight'
              className='custom-control-label'>
              Highlight
            </label>
          </div>
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-12'>
          <div className='custom-switch custom-control'>
            <input
              id='continuesSellingAfterOutOfStock'
              className='custom-control-input '
              type='checkbox'
              checked={params['continues_selling_after_out_of_stock'] ? true : false}
              value={params['continues_selling_after_out_of_stock'] ? true : false}
              onChange={
                () => onChangeFilter('continues_selling_after_out_of_stock', !params['continues_selling_after_out_of_stock'])
              }
            />
            <label
              htmlFor='continuesSellingAfterOutOfStock'
              className='custom-control-label'>
              Continue Selling After Out Of Stock
            </label>
          </div>
        </div>
      </div>
      <div className='form-group tx-center'>
        <button
          type='button'
          className='btn btn-secondary'
          onClick={() => {
            formRef.current.reset()
            setParams({})
          }}
        >
          <ResetIcon className='mg-r-5' />
              RESET
        </button>
      </div>
    </form>
  )
}