import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  ErrorMessage, Field, Form, Formik
} from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import SingleSelect from '../../common/SingleSelect'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'
import getUrlParameter from '../../utils/getUrlParameter'

export default function RegionForm({ editForm, id }) {
  const countryId = getUrlParameter('countryId')
  const [selectedCountryId, setSelectedCountryId] = React.useState(countryId || 0)
  const [name, setName] = React.useState('')
  const [mmName, setMmName] = React.useState('')
  const [isActive, setIsActive] = React.useState(true)
  const [order, setOrder] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchCity = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`cities/${id}`)
      console.log('city detail :', data)
      if (data.success) {
        const city = data.data
        setName(city.name)
        setMmName(city.mm_name)
        setSelectedCountryId(city.country_id)
        setIsActive(Boolean(city.is_active))
        setOrder(city.order)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async ({
    name,
    mmName,
    selectedCountryId,
    isActive,
    order
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `cities/${id}` : `cities`
      const body = {
        name,
        mm_name: mmName,
        country_id: parseInt(selectedCountryId),
        is_active: isActive
      }
      console.log('body', body)
      const { data } = await axios({
        method,
        url: endpoint,
        data: editForm ? { ...body, order } : body,
      })
      if (data.success) {
        toast.success('Success')
        navigate('/cities')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const citySchema = Yup.object({
    name: Yup.string()
      .required('Name Required'),
    mmName: Yup.string()
      .required('Myanmar Name Required'),
    selectedCountryId: Yup.string()
      .required('Country Required')
  })

  React.useEffect(() => {
    editForm && fetchCity()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={{
        name,
        mmName,
        selectedCountryId,
        isActive,
        order
      }}
      validationSchema={citySchema}
      onSubmit={(values) => {
        console.log('formik values', values)
        onSubmit(values)
      }}
    >
      {
        ({ values, setFieldValue, isSubmitting, handleChange, handleBlur }) => (
          <Container>
            <Form>
              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>*</span>Name
                </label>
                <div className='col-md-9'>
                  <Field
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='Yangon'
                    autoFocus
                  />
                  <ErrorComponent>
                    <ErrorMessage name='name' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>*</span> Myanmar Name
                </label>
                <div className='col-md-9'>
                  <Field
                    name='mmName'
                    type='text'
                    className='form-control'
                    placeholder='ရန်ကုန်'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='mmName' />
                  </ErrorComponent>
                </div>
              </div>

              {
                !countryId &&
                <div className='form-group row'>
                  <label className='col-md-3 tx-md-right col-form-label'>
                    <span className='tx-danger'>*</span>Country
                  </label>
                  <div className='col-md-9'>
                    <SingleSelect
                      name='selectedCountryId'
                      label='Country'
                      endpoint='countries'
                      value={values.selectedCountryId}
                      onChange={countryId => setFieldValue('selectedCountryId', countryId)}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              }

              {editForm && (
                <div className='form-group row'>
                  <label className='col-md-3 tx-md-right col-form-label'>Order</label>
                  <div className='col-md-9'>
                    <Field
                      name='order'
                      type='number'
                      className='form-control'
                    />
                  </div>
                </div>
              )}

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-control custom-switch'>
                    <input
                      name='isActive'
                      type='checkbox'
                      className='custom-control-input'
                      id='isActive'
                      value={values.isActive}
                      checked={values.isActive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label className='custom-control-label' htmlFor='isActive'>
                      Active
                  </label>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <button type='submit' className='btn btn-primary btn-block btn-uppercase'>
                    {isSubmitting ? 'submitting' : 'submit'}
                  </button>
                </div>
              </div>

            </Form>
          </Container>
        )
      }
    </Formik>
  )
}
