import React from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import Select from 'react-select'
import getErrorMessage from '../utils/getErrorMessage'

export default function MultiSelect({
  defaultValue,
  endpoint,
  options,
  className,
  onChange,
}) {
  const [data, setData] = React.useState(options || [])
  const [isLoading, setIsLoading] = React.useState(true)
  const fetchData = async () => {
    try {
      if (endpoint !== 'childCategories') {
        const { data } = await axios.get(endpoint)
        if (data.success) {
          setData(
            data.data.map((data) => ({ label: data.name, value: data.id }))
          )
        }
      } else {
        const { data } = await axios({
          method: 'GET',
          url: 'categories',
        })
        if (data.success) {
          let childs = []
          data.data.map((data) => {
            data.subs.map((sub) => childs.push(sub))
          })
          setData(
            childs.map((child) => ({ label: child.name, value: child.id }))
          )
        }
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    endpoint && fetchData()
  }, [])

  return (
    <Select
      className={`${className}`}
      closeMenuOnScroll={false}
      closeMenuOnSelect={false}
      defaultValue={defaultValue}
      options={data}
      isMulti
      onChange={onChange}
      isClearable
      isLoading={!data.length && isLoading}
    />
  )
}
