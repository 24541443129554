import React from 'react'
import { useParams } from '@reach/router'
import Card from '../../components/Card'
import UserForm from './UserForm'

export default function EditUserPage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit User'
        description='users'
        href='/users'
      />
      <UserForm editForm id={id} />
    </Card>

  )
}