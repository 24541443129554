import React from 'react'
import { navigate, Redirect } from '@reach/router'
import { useRecoilValue } from 'recoil'
import { loading, loggedIn } from '../utils/recoil'
import Layout from './Layout'
import LoadingSpinner from './LoadingSpinner'
import { Toaster } from 'react-hot-toast'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'

export default function ProtectedRoute({
  as: Component,
  path,
  sidebar = true,
  header = true,
  blank = false,
}) {
  const isLoggedIn = useRecoilValue(loggedIn)
  const isUserLoading = useRecoilValue(loading)

  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response && error.response.status === 403) {
        navigate('/403', {
          replace: true,
        })
        return Promise.reject(error)
      }
      return Promise.reject(error)
    }
  )

  if (isUserLoading) {
    return <LoadingSpinner />
  }

  return isLoggedIn ? (
    <Layout sidebar={sidebar} header={header} blank={blank}>
      <Toaster
        position='top-center'
        toastOptions={{
          success: {
            duration: 1000,
          },
          loading: {
            duration: 10000,
          },
          error: {
            duration: 4000,
          },
        }}
      />
      <Component />
    </Layout>
  ) : (
    <Redirect noThrow to={`/signin?redirect=${path.slice(1)}`} />
  )
}
