import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Clipboard as ClipboardIcon,
  Edit2 as EditIcon,
  Plus as PlusIcon,
  Trash2 as DeleteIcon,
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'

export default function BanksPage() {
  const [banks, setBanks] = React.useState([])
  const [activeBanks, setActiveBanks] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchBanks = async () => {
    try {
      const { data } = await axios.get('banks')
      if (data.success) {
        setBanks(data.data)
        setActiveBanks(filterActive(data.data))
        setRows(
          data.data.map((data) => ({
            id: data.id,
            name: data.name,
            accountName: data.account_name,
            accountNumber: data.account_number
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreBank = async (id, index) => {
    try {
      const { data } = await axios.post(`banks/${id}/restore`)
      if (data.success) {
        const temp = [...banks]
        temp[index] = data.data
        setBanks(temp)
        setActiveBanks(filterActive(temp))
        setRows(
          temp.map((data) => ({
            id: data.id,
            name: data.name,
            accountName: data.account_name,
            accountNumber: data.account_number
          }))
        )
        toast.remove()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteBank = async (id, index) => {
    try {
      const { data } = await axios.delete(`banks/${id}`)
      if (data.success) {
        const temp = [...banks]
        temp.splice(index, 1)
        setBanks(temp)
        setActiveBanks(filterActive(temp))
        setRows(
          temp.map((data) => ({
            id: data.id,
            name: data.name,
            accountName: data.account_name,
            accountNumber: data.account_number
          }))
        )
        toast.success(
          <UndoAction
            onClick={() => restoreBank(id, index)}
            name={banks[index].name}
          />, 4000
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const copyBankAccount = (id, index) => {
    let textField = document.createElement('textarea')
    textField.innerText = rows[index].accountNumber
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toast.success('Copied Bank Account')
  }

  React.useEffect(() => {
    fetchBanks()
  }, [])

  return (
    <Card>
      <Card.Header
        title='Banks'
        description='Zay Chin Bank Accounts'
        tableStats={[
          { label: 'total banks', value: banks.length },
          { label: 'active banks', value: activeBanks.length },
        ]}
        btns={[
          {
            title: 'Add new bank',
            icon: PlusIcon,
            href: 'banks/add',
            variant: 'primary',
          },
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Name',
            key: 'name',
            width: '200px',
          },
          {
            title: 'Account Name',
            key: 'accountName',
          },
          {
            title: 'Account Number',
            render: (id, index) => (
              <div>
                <span>{rows[index].accountNumber}</span>
                <button
                  className='btn link-03 btn-icon btn-xxs mg-r-5'
                  onClick={() => copyBankAccount(id, index)}
                >
                  <ClipboardIcon />
                </button>
              </div>
            )
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id) => navigate(`banks/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteBank,
                }
              ]
            },
          },
        ]}
      />
    </Card>
  )
}
