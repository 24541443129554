import React from 'react'
import arrayMove from 'array-move'
import axios from 'axios'
import PropTypes from 'prop-types'
import SortableImage from '../components/SortableImage'
import SortableList from '../components/SortableList'

const propTypes = {
  relatedType: PropTypes.string,
  multiple: PropTypes.bool,
  photos: PropTypes.array,
  setPhotos: PropTypes.func,
}

export default function FileUpload({
  id,
  name,
  relatedType,
  multiple,
  photos = [],
  setPhotos,
}) {
  console.log({
    id,
    name,
    relatedType,
    multiple,
    photos,
    setPhotos,
  })
  const imgRef = React.useRef()
  imgRef.current = photos

  const deletePhoto = async (id, index) => {
    console.log({ id })
    console.log({ index })
    try {
      const { data } = await axios.delete(`files/${id}`)
      if (data.success) {
        const temp = [...photos]
        temp.splice(index, 1)
        setPhotos(temp)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const onSortEndPhotos = ({ oldIndex, newIndex }) => {
    setPhotos(arrayMove(photos, oldIndex, newIndex))
  }

  const handleFileUpload = async (files) => {
    try {
      var iterableFiles = Array.from(files)

      //CreateUrl to preview image
      iterableFiles.map((file) => {
        setPhotos((photos) => [
          ...photos,
          {
            file,
            url: URL.createObjectURL(file),
            status: 'pending',
          },
        ])
      })

      //Call upload API
      iterableFiles.map(async (file) => {
        var formData = new FormData()
        formData.append('file', file)
        formData.append('related_type', relatedType)
        if (id) {
          formData.append('related_id', id)
        }
        const { data } = await axios({
          method: 'POST',
          url: 'files',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        if (data.success) {
          let temp = [...imgRef.current]
          const index = temp.findIndex((photo) => photo.file === file)
          if (index > 0) {
            temp[index]['status'] = 'success'
            temp[index]['id'] = data.data.id
            setPhotos(temp)
          } else {
            temp = [
              {
                id: data.data.id,
                status: 'success',
              },
            ]
            setPhotos(temp)
          }
        }
      })
    } catch (error) {
      console.log({ error })
    }
  }

  return (
    <>
      <div className='custom-file'>
        <input
          type='file'
          id={name}
          className='custom-file-input'
          onChange={(e) => handleFileUpload(e.target.files)}
          multiple={multiple}
        />
        <label className='custom-file-label' htmlFor={name}>
          Choose File
        </label>
      </div>
      <SortableList
        items={photos}
        axis='xy'
        onDelete={deletePhoto}
        onSortEnd={onSortEndPhotos}
        renderItemComponent={SortableImage}
        className='pd-0 mg-t-10'
      />
    </>
  )
}
FileUpload.propTypes = propTypes
