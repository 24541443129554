import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Edit2 as EditIcon, 
  Plus as PlusIcon,
  Trash2 as DeleteIcon,
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import LoadingSpinner from '../../components/LoadingSpinner'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'

export default function CountriesPage() {
  const [countries, setCountries] = React.useState([])
  const [activeCountries, setActiveCountries] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchCountries = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get('countries')
      console.log('countries response: ', data)
      if (data.success) {
        setCountries(data.data)
        setActiveCountries(filterActive(data.data))
        setRows(
          data.data.map((data) => ({
            id: data.id,
            name: data.name,
            flag: data.files[0] && `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreCountry = async (id, index) => {
    try {
      const { data } = await axios.post(`countries/${id}/restore`)
      console.log('restore response: ', data)
      if (data.success) {
        const temp = [...countries]
        temp[index] = data.data
        setCountries(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            name: data.name,
            flag: data.files[0] && `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
          }))
        )
        toast.remove()
      }
    } catch (error) {
      console.log('catch of try catch', error)
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteCountry = async (id, index) => {
    try {
      const { data } = await axios.delete(`countries/${id}`)
      console.log('delete response: ', data)
      if (data.success) {
        const temp = [...countries]
        temp.splice(index, 1)
        setCountries(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            name: data.name,
            flag: data.files[0] && `${process.env.REACT_APP_IMAGE_API_URL}${data.files[0].url}_100.jpg`,
          }))
        )
        toast.success(
          <UndoAction
            onClick={() => restoreCountry(id, index)}
            name={countries[index].name}
          />
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    fetchCountries()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Countries'
        description='Countries'
        tableStats={[
          { label: 'total countries', value: countries.length },
          { label: 'active countries', value: activeCountries.length },
        ]}
        btns={[
          {
            title: 'Add new Country',
            icon: PlusIcon,
            href: 'countries/add',
            variant: 'primary',
          },
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Flag',
            width: '100px',
            render: {
              type: 'image',
              key:'flag',
              alt:'',
              className:'img-fluid pd-0',
            }
          },
          {
            title: 'Name',
            key: 'name',
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: PlusIcon,
                  variant: 'btn-primary',
                  onClick: (id) => navigate(`/cities/add?countryId=${id}`),
                },
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id) => navigate(`/countries/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteCountry,
                }
              ]
            },
          },
        ]}
      />
    </Card>
  )
}
