import React from 'react'
import Card from '../../components/Card'
import SmsForm from './SmsForm'

export default function SendSmsPage() {
  return (
    <Card>
      <Card.Header
        title='Send SMS'
        description='sms'
        href='/sms'
      />
      <SmsForm />
    </Card>
  )
}