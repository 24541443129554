export const options = {
  legend: {
    display: true,
    fontSize: 20,
  },
  layout: {
    padding: 0
  },
  animation: {
    duration: 0
  },
  hover: {
    animationDuration: 0
  },
  responsiveAnimationDuration: 0,
  showLines: false,
  elements: {
    line: {
      tension: 0
    },
    point: {
      radius: 0
    }
  },
scales: {
  yAxes: [{
    ticks: {
      beginAtZero: true
    }
  }]
},
plugins: {
  datalabels: {
      display: false,
  },
}

}