import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Edit2 as EditIcon, 
  Plus as PlusIcon,
  Trash2 as DeleteIcon,
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import LoadingSpinner from '../../components/LoadingSpinner'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import getErrorMessage from '../../utils/getErrorMessage'

export default function RolesPage() {
  const [roles, setRoles] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchRoles = async () => {
    try {
      const { data } = await axios.get('roles')
      if (data.success) {
        setRoles(data.data)
        setRows(
          data.data.map((data) => ({
            id: data.id,
            name: data.name,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteRole = async (id, index) => {
    try {
      const { data } = await axios.delete(`roles/${id}`)
      if (data.success) {
        const temp = [...roles]
        temp.splice(index, 1)
        setRoles(temp)
        setRows(
          temp.map((temp) => ({
            id: temp.id,
            name: temp.name,
          }))
        )
        toast.success(
          <UndoAction
            onClick={() => restoreRole(id, index)}
            name={roles[index].name}
          />,
          0,
          4000
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreRole = async (id, index) => {
    try {
      const { data } = await axios.post(`roles/${id}/restore`)
      if (data.success) {
        const temp = [...roles]
        temp[index] = data.data
        setRoles(temp)
        setRows(
          temp.map((temp) => ({
            id: temp.id,
            name: temp.name,
          }))
        )
        toast.remove()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    fetchRoles()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Roles'
        description='Roles of users'
        tableStats={[{ label: 'Total Roles', value: roles.length }]}
        btns={[
          {
            title: 'Add new role',
            icon: PlusIcon,
            href: 'roles/add',
            variant: 'primary',
          },
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Name',
            key: 'name',
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id)=> navigate(`roles/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteRole,
                },
              ]
            }
          },
        ]}
      />
    </Card>
  )
}
