import React from 'react'
import Card from '../../components/Card'
import OrderForm from './OrderForm'

export default function CreateOrderPage() {
  return (
    <Card>
      <Card.Header
        title='Create Order'
        description='orders'
        href='/orders'
      />
      <OrderForm />
    </Card>
  )
}