import React from 'react'
export default function UndoAction({ name, onClick }) {
  return (
    <div className='d-flex justify-content-between align-items-center'>
      <span className='mg-r-10 tx-center'>{name ? name : 'User'} is deleted!</span>
      <button
        onClick={onClick}
        className='btn btn-white bd-0'
      >
        UNDO
      </button>
    </div>
  )
}
