import React from 'react'
export default function TextInput({ value, onChange }) {
  return (
      <input
        type='text'
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className='form-control mg-r-5'
        autoFocus
      />
  )
}