import React from 'react'
import { useParams } from '@reach/router'
import Card from '../../components/Card'
import DeliveryForm from './DeliveryForm'

export default function EditDeliveryPage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Delivery'
        description='deliveries'
        href='/deliveries'
      />
      <DeliveryForm editForm id={id} />
    </Card>
  )
}