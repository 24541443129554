import React from 'react';
import { navigate, useLocation } from '@reach/router';
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { parse } from 'query-string';
import { Trash2 as DeleteIcon } from 'react-feather';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import AsyncSingleSelect from '../../common/AsyncSingleSelect';
import SingleSelect from '../../common/SingleSelect';
import Addresses from '../../components/Addresses';
import ErrorComponent from '../../components/ErrorComponent';
import LoadingSpinner from '../../components/LoadingSpinner';
import PaymentMethods from '../../components/PaymentMethods';
import { platforms } from '../../data/orders';
import getErrorMessage from '../../utils/getErrorMessage';

export default function OrderForm({ editForm, id }) {
  const location = useLocation()
  const searchParams = parse(location.search, {
    parseNumbers: true,
  })
  const cartID = searchParams.cartId
  const [userId, setUserId] = React.useState(searchParams.userId)
  const [user, setUser] = React.useState('')
  const [addressId, setAddressId] = React.useState('');
  const [deliveryId, setDeliveryId] = React.useState('')
  const [deliverySize, setDeliverySize] = React.useState('')
  const [processingBatchNumber, setProcessingBatchNumber] = React.useState('')
  const [platform, setPlatform] = React.useState('facebook')
  const [paymentId, setPaymentId] = React.useState('cod')
  const [deliveryDate, setDeliveryDate] = React.useState('')
  const [preferredDeliveryTime, setPreferredDeliveryTime] = React.useState('')
  const [note, setNote] = React.useState('')
  const [requestPlasticBag, setRequestPlasticBag] = React.useState(true)
  const [callBeforeDelivery, setCallBeforeDelivery] = React.useState(true)
  const [isManualDelivery, setIsManualDelivery] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [products, setProducts] = React.useState([
    {
      product_id: '',
      price: 0,
      quantity: 0,
      is_foc: false,
    }
  ])

  const orderSchema = Yup.object({
    userId: Yup.string()
      .required('User Required'),
    paymentId: Yup.string()
      .required('Payment Required'),
    addressId: Yup.string()
      .required('Address Required')
  })

  const onChangeProduct = (product, index) => {
    let temp = [...products]
    temp[index].product_id = product.value
    temp[index].price = product.price
    temp[index].thumb = product.thumb
    if (index + 1 === temp.length) {
      temp = [
        ...temp, {
          product_id: '',
          price: 0,
          quantity: 0,
          is_foc: false
        }]
    }
    setProducts(temp)
  }
  console.log(products)

  const onChangeQuantity = (value, index) => {
    const temp = [...products]
    temp[index].quantity = value
    setProducts(temp)
  }

  const onToggleFoc = (product, index) => {
    const temp = [...products]
    temp[index].is_foc = !products[index].is_foc
    setProducts(temp)
  }

  const fetchOrder = async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `orders/${id}`
      })
      console.log('order Detail', data.data)
      if (data.success) {
        const order = data.data
        setUserId(order.user.id)
        setUser({ label: order.user.name, value: order.user.id })
        setAddressId(order.address.id)
        setDeliveryId(order.delivery.id)
        setDeliverySize(order.delivery_size)
        setProcessingBatchNumber(order.processing_batch_number)
        setPlatform(order.platform)
        setPaymentId(order.payment_method)
        setDeliveryDate(order.delivery_date)
        setPreferredDeliveryTime(order.time_range.id)
        setNote(order.note)
        setRequestPlasticBag(Boolean(order.request_plastic_bag))
        setCallBeforeDelivery(Boolean(order.call_before_delivery))
        setProducts(
          order.order_items.map(
            (item) => ({
              label: item.product.name,
              value: item.product.id,
              product_id: item.product.id,
              thumb: item.product.thumb.url,
              price: item.product.price,
              quantity: item.quantity,
              is_foc: Boolean(item.is_foc),
            })
          ))
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchCartItems = async (cardId) => {
    try {
      const { data } = await axios(`carts/${cardId}`)
      console.log('cart items and user', data)
      if (data.success) {
        setUser({ label: 'mya', value: 1 })
        setProducts(
          data.data.cart_items.map(
            (item) => ({
              label: item.product.name,
              value: item.product.id,
              product_id: item.product.id,
              thumb: item.product.thumb.url,
              price: item.product.price,
              quantity: item.quantity,
              is_foc: Boolean(item.is_foc),
            })
          )
        )
      }
    } catch (err) {
      const error = getErrorMessage(err)
      toast.error(error)
    }
  }

  const deleteProduct = (id) => {
    console.log({ id })
    setProducts(
      products.filter(
        (product) => product.product_id !== id
      )
    )
  }

  const deleteCart = async (id) => {
    try {
      const { data } = await axios.delete(`carts/${id}`)
      if (data.success) {
        toast.success('Deleted Cart!')
      }
    } catch (err) {
      const error = getErrorMessage(err)
      toast.error(error)
    }
  }

  const onSubmit = async ({
    userId,
    addressId,
    deliveryId,
    deliverySize,
    processingBatchNumber,
    platform,
    paymentId,
    deliveryDate,
    preferredDeliveryTime,
    note,
    requestPlasticBag,
    callBeforeDelivery,
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const url = editForm ? `orders/${id}` : `orders`
      const temp = products[products.length - 1].product_id ? [...products] : products.slice(0, products.length - 1)
      const body = {
        user_id: userId,
        address_id: addressId,
        delivery_id: deliveryId,
        delivery_size: deliverySize || 'medium',
        processing_batch_number: processingBatchNumber,
        platform,
        payment_method: paymentId,
        delivery_date: deliveryDate || '2021-01-14',
        time_range_id: preferredDeliveryTime,
        note,
        request_plastic_bag: requestPlasticBag,
        call_before_delivery: callBeforeDelivery,
        products: temp,
      }
      console.log({ body })
      const { data } = await axios({
        method,
        url,
        data: body,
      })
      console.log('order create', data)
      if (data.success) {
        toast.success('Success')
        cartID && deleteCart(cartID)
        navigate('/orders')
      }
    } catch (error) {
      console.log({ error })
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  React.useEffect(() => {
    editForm && fetchOrder()
    cartID && fetchCartItems(cartID)
  }, [])

  if (editForm && isLoading) {
    return (
      <LoadingSpinner />
    )
  }

  return (
    <Formik
      initialValues={{
        userId,
        addressId,
        deliveryId,
        deliverySize,
        processingBatchNumber,
        platform,
        paymentId,
        deliveryDate,
        preferredDeliveryTime,
        note,
        requestPlasticBag,
        callBeforeDelivery,
      }}
      validationSchema={
        orderSchema
      }
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {
        ({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form className='row mg-0'>
            <div className='col-md-6'>
              <div className='form-group row'>
                <label className='col-12'>
                  User<span className='tx-danger'> *</span>
                </label>
                <div className='col-10'>
                  <AsyncSingleSelect
                    defaultValue={user}
                    name='userId'
                    endpoint='users'
                    onChange={(user) => setFieldValue('userId', user.value)}
                  />
                  <ErrorComponent>
                    <ErrorMessage name='userId' />
                  </ErrorComponent>
                </div>
              </div>
              {
                values.userId && (
                  <div className='form-group row'>
                    <label className='col-12'>
                      Address<span className='tx-danger'> *</span>
                    </label>
                    <div className='col-10'>
                      <Addresses
                        name='addressId'
                        addressId={values.addressId}
                        userId={values.userId}
                        onChange={(addressId) => setFieldValue('addressId', addressId)}
                      />
                      <ErrorComponent>
                        <ErrorMessage name='addressId' />
                      </ErrorComponent>
                    </div>
                  </div>
                )
              }
              <div className='form-group row'>
                <label className='col-12'>
                  Processing Batch Number<span className='tx-danger'> *</span>
                </label>
                <div className='col-10'>
                  <Field
                    name='processingBatchNumber'
                    type='text'
                    className='form-control'
                    placeholder='1'
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-12'>
                  Delivery Time<span className='tx-danger'> *</span>
                </label>
                <div className='col-10'>
                  <SingleSelect
                    name='preferredDeliveryTime'
                    label='Delivery Time'
                    value={values.preferredDeliveryTime}
                    endpoint='timeranges'
                    onChange={preferredDeliveryTime => setFieldValue('preferredDeliveryTime', preferredDeliveryTime)}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-12'>
                  Platform
                </label>
                <div className='col-10'>
                  <SingleSelect
                    name='platform'
                    label='Platform'
                    value={values.platform}
                    data={platforms}
                    onChange={platform => setFieldValue('platform', platform)}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-10'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      type='checkbox'
                      className='custom-control-input'
                      id={'menualDelivery'}
                      checked={isManualDelivery}
                      onChange={() => setIsManualDelivery(!isManualDelivery)}
                    />
                    <label className='custom-control-label' htmlFor={'menualDelivery'}>Delivery</label>
                  </div>
                  {
                    isManualDelivery && (
                      <div className='mg-t-5'>
                        <SingleSelect
                          name='deliveryId'
                          label='Delivery'
                          value={values.deliveryId}
                          endpoint='deliveries'
                          onChange={deliveryId => setFieldValue('deliveryId', deliveryId)}
                          onBlur={handleBlur}
                        />
                      </div>
                    )
                  }
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-12'>
                  Note
                </label>
                <div className='col-10'>
                  <Field
                    name='note'
                    as='textarea'
                    className='form-control'
                    placeholder='ပစ္စည်းမမှားအောင် သေချာဂရုစိုက်ပေးပါ'
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-12'>
                  Payment Method
                </label>
                <div className='col-10'>
                  <PaymentMethods
                    name='paymentId'
                    value={values.paymentId}
                    paymentId={values.paymentId}
                    onChange={(paymentId) => setFieldValue('paymentId', paymentId)}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-12'>
                  Delivery Size
                </label>
                <div className='col-3'>
                  <div className='custom-control custom-radio'>
                    <input
                      type='radio'
                      id='small'
                      name='deliverySize'
                      className='custom-control-input'
                      checked={values.deliverySize === 'small'}
                      onChange={() => setFieldValue('deliverySize', 'small')}
                    />
                    <label className='custom-control-label' htmlFor='small'>Small</label>
                  </div>
                </div>
                <div className='col-3'>
                  <div className='custom-control custom-radio'>
                    <input
                      type='radio'
                      id='medium'
                      name='deliverySize'
                      className='custom-control-input'
                      checked={values.deliverySize === 'medium'}
                      onChange={() => setFieldValue('deliverySize', 'medium')}
                    />
                    <label className='custom-control-label' htmlFor='medium'>Medium</label>
                  </div>
                </div>
                <div className='col-3'>
                  <div className='custom-control custom-radio'>
                    <input
                      type='radio'
                      id='large'
                      name='deliverySize'
                      className='custom-control-input'
                      checked={values.deliverySize === 'large'}
                      onChange={() => setFieldValue('deliverySize', 'large')}
                    />
                    <label className='custom-control-label' htmlFor='large'>Large</label>
                  </div>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-12'>
                  <div className='custom-switch custom-control'>
                    <input
                      name='requestPlasticBag'
                      id='requestPlasticBag'
                      className='custom-control-input'
                      type='checkbox'
                      value={values.requestPlasticBag}
                      checked={values.requestPlasticBag}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor='requestPlasticBag' className='custom-control-label'>
                      Request Plastic Bag
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-12'>
                  <div className='custom-switch custom-control'>
                    <input
                      name='callBeforeDelivery'
                      id='callBeforeDelivery'
                      className='custom-control-input'
                      type='checkbox'
                      value={values.callBeforeDelivery}
                      checked={values.callBeforeDelivery}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor='callBeforeDelivery' className='custom-control-label'>
                      Call Before Delivery
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group row'>
                <label>&nbsp;</label>
                <div className='table-responsive'>
                  <table className='table table-dashboard'>
                    <thead>
                      <tr>
                        <th>Thumb</th>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>FOC</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        products.map(
                          (product, index) => (
                            <tr key={index}>
                              <td width='50px'>
                                {
                                  product.thumb ? (
                                    <img
                                      src={`${process.env.REACT_APP_IMAGE_API_URL}${product.thumb}_100.jpg`}
                                      alt=''
                                      className='img-fluid rounded ht-40'
                                    />
                                  ) : (
                                      <div className='bg-light ht-40 wd-50 rounded'></div>
                                    )
                                }
                              </td>
                              <td>
                                <AsyncSingleSelect
                                  defaultValue={{
                                    label: product.label,
                                    value: product.value,
                                  }}
                                  endpoint='products'
                                  onChange={(product) => onChangeProduct(product, index)}
                                  className='mg-b-20'
                                />
                              </td>
                              <td width='50px'>
                                <input
                                  value={product.quantity}
                                  type='number'
                                  className='form-control'
                                  placeholder='3'
                                  onChange={(e) => onChangeQuantity(e.target.value, index)}
                                />
                              </td>
                              <td width='50px'>
                                <div className='custom-switch custom-control'>
                                  <input
                                    id={index}
                                    className='custom-control-input'
                                    type='checkbox'
                                    tabIndex='-1'
                                    checked={product.is_foc}
                                    onChange={() => onToggleFoc(product, index)}
                                  />
                                  <label
                                    htmlFor={index}
                                    className='custom-control-label'
                                  >
                                    &nbsp;
                                  </label>
                                </div>
                              </td>
                              <td width='50px'>
                                <button
                                  className='btn btn-danger'
                                // onClick={() => deleteProduct(product.id)}
                                >
                                  <DeleteIcon />
                                </button>
                              </td>
                            </tr>
                          )
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-12'>
                  <button
                    type='submit'
                    className='btn btn-primary btn-block'
                  >
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )
      }
    </Formik>
  )
}