import React from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import getErrorMessage from '../utils/getErrorMessage'
import LoadingSpinner from './LoadingSpinner'
import AddressForm from './AddressForm'

export default function Addresses({ userId, addressId, onChange }) {
  const [isLoading, setIsLoading] = React.useState(true)
  const [addresses, setAddresses] = React.useState([])

  const fetchAddresses = async () => {
    try {
      setAddresses([])
      const { data } = await axios({
        method: 'GET',
        url: 'addresses',
        params: {
          user_id: userId,
        },
      })
      if (data.success) {
        setAddresses(data.data)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }
  React.useEffect(() => {
    fetchAddresses()
  }, [userId])

  if (isLoading) {
    return (
      <LoadingSpinner />
    )
  }

  return (
    <ul className='list-group'>
      {
        addresses.map((address, index) => {
          return (
            <li className='list-group-item' key={index}>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  id={address + index}
                  className='custom-control-input'
                  checked={addressId === address.id}
                  onChange={() => onChange(address.id)}
                />
                <label className='custom-control-label' htmlFor={address+index}>
                  {address.address}
                </label>
              </div>
            </li>
          )
        })
      }
      <li className='list-group-item'>
        <button
          className='btn btn-light btn-block'
          type='button'
          data-toggle='modal'
          data-dismiss='modal'
          data-target='#addressModal'
        >
          Add New Address
        </button>
        <div
          id='addressModal'
          className='modal fade'
          role='dialog'
          tabIndex='-1'
          aria-hidden={true}
        >
          <AddressForm userId={userId} />
        </div>
      </li>
    </ul>

  )
}
