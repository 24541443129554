import React from 'react'
import { useRecoilValue } from 'recoil'
import Layout from '../components/Layout'
import LoadingSpinner from '../components/LoadingSpinner'
import LTVDownloadWidget from '../components/widgets/LTVDownloadWidget'
import TodayWidget from '../components/widgets/TodayWidget'
import { loading, userData } from '../utils/recoil'
import Accounting from './Accounting'
import CustomerService from './CustomerService'
import {
  XCircle as XCircleIcon,
  CheckCircle as CheckCircleIcon,
} from 'react-feather'

export default function DashboardPage() {
  const isUserLoading = useRecoilValue(loading)
  const {  name } = useRecoilValue(userData)

  // delete me
  React.useEffect(() => {
    window.$.plot(
      '#flotChart2',
      [
        {
          data: [
            [0, 40.42460652446133],
            [1, 39.746131861430484],
            [2, 35.95109348595284],
            [3, 33.295567798337025],
            [4, 28.87960054374564],
            [5, 28.498853797438535],
            [6, 24.44598918395687],
            [7, 20.218403695742982],
            [8, 17.498233218421312],
            [9, 16.54060961040485],
            [10, 19.002383747980975],
            [11, 16.471725580977914],
            [12, 13.155182881964787],
            [13, 18.077483369454345],
            [14, 17.938434631237822],
            [15, 18.92413124205944],
            [16, 18.461208995002494],
            [17, 19.661876313219913],
            [18, 18.042303047352455],
            [19, 17.785290125636354],
            [20, 20.151980264909543],
            [21, 18.924923650083358],
            [22, 17.088923942341232],
            [23, 17.11745721938192],
            [24, 15.703502004647063],
            [25, 15.078540825575075],
            [26, 14.510809401000387],
            [27, 15.226574724712297],
            [28, 18.01709489679379],
            [29, 19.770761552221565],
            [30, 23.670209769802682],
            [31, 27.985742905483164],
            [32, 30.80634374024116],
            [33, 28.56215635604935],
            [34, 29.459971127621614],
            [35, 29.506514532069936],
            [36, 27.289754685028775],
            [37, 24.365568424856836],
            [38, 22.893664052525622],
            [39, 26.57527073377395],
            [40, 28.04483981176638],
            [41, 27.77031588135324],
            [42, 30.245343380918406],
            [43, 26.57479109054868],
            [44, 22.18111812493286],
            [45, 19.644777576179102],
            [46, 16.745896664550347],
            [47, 17.213789404459703],
            [48, 20.056299583848645],
            [49, 16.133489834808596],
            [50, 12.954908672170685],
            [51, 10.710124578123633],
            [52, 7.99331653229623],
            [53, 11.330824794029468],
            [54, 15.366888531658518],
            [55, 20.162146683566043],
            [56, 22.56433862111984],
            [57, 19.342499731952728],
            [58, 18.325580989588303],
            [59, 20.7511874504748],
            [60, 17.099488390174667],
            [61, 19.327912207799372],
            [62, 18.31650048764758],
            [63, 14.34889182281918],
            [64, 9.939606691311928],
            [65, 10.640765261408266],
            [66, 6.184018402150329],
            [67, 10.32603369640253],
            [68, 12.800228260925913],
            [69, 13.441825186707572],
            [70, 18.356807970216398],
            [71, 22.877870826719246],
            [72, 22.265182194135164],
            [73, 26.922230352208814],
            [74, 22.50189449417149],
            [75, 18.14060836488997],
            [76, 19.06846754782137],
            [77, 19.73961245162804],
            [78, 18.82061647678131],
            [79, 23.33852310774632],
            [80, 20.4810751737507],
            [81, 25.47004674625981],
            [82, 28.842343230667943],
            [83, 29.09658130355575],
            [84, 27.714558649179516],
            [85, 25.220943394214757],
            [86, 25.43025835749838],
            [87, 24.13072502126257],
            [88, 20.020443915879174],
            [89, 18.387986699568284],
            [90, 18.307930265812836],
            [91, 18.72058117598284],
            [92, 22.46850401457292],
            [93, 21.718447234477544],
            [94, 26.488413058421976],
            [95, 29.882771503348536],
            [96, 26.94717052753741],
            [97, 28.06481155716483],
            [98, 30.40253552214977],
            [99, 28.987765656899995],
            [100, 30.13551373541587],
            [101, 27.605418583328863],
            [102, 30.214101672191696],
            [103, 26.88133118194294],
            [104, 25.727723710013045],
            [105, 28.279900485071032],
            [106, 27.89821646957165],
            [107, 30.69854959893513],
            [108, 31.4282872565538],
            [109, 36.14975119379828],
            [110, 32.0227980362552],
            [111, 27.309945041337073],
            [112, 29.51230564564233],
            [113, 32.67035607222466],
            [114, 28.82372957289023],
            [115, 28.85242847072152],
            [116, 29.63844624105993],
            [117, 29.157219655397313],
            [118, 27.90616896335908],
            [119, 30.71160984027734],
            [120, 28.026131698214115],
            [121, 23.82439628518755],
            [122, 18.83160453591808],
            [123, 14.487027404093734],
            [124, 11.761696821209515],
            [125, 12.758521331246762],
            [126, 11.367219794014758],
            [127, 14.21423733022224],
            [128, 11.602480291802959],
            [129, 15.244397384751025],
            [130, 13.050114582189945],
            [131, 17.253378403411432],
            [132, 18.506683542934038],
            [133, 23.04087000728893],
            [134, 21.87625260158983],
            [135, 25.974296957094985],
            [136, 22.463388750666468],
            [137, 17.675052230498956],
            [138, 14.806456821972226],
            [139, 18.589538541056534],
            [140, 20.005874168046084],
            [141, 22.934846222699328],
            [142, 25.155316598067426],
            [143, 27.883126867602705],
            [144, 27.76231130416712],
            [145, 28.618896779193612],
            [146, 26.413595554645298],
            [147, 28.097785659338193],
            [148, 29.502272077881898],
            [149, 26.1165859635503],
          ],
          color: '#0168fa',
          curvedLines: { apply: true },
        },
      ],
      {
        series: {
          shadowSize: 0,
          lines: {
            show: true,
            lineWidth: 1.5,
            fill: 0.05,
          },
        },
        grid: {
          borderWidth: 0,
          labelMargin: 0,
        },
        yaxis: {
          show: false,
          max: 55,
        },
        xaxis: {
          show: true,
          color: 'rgba(131,146,165,.08)',
          min: 48,
          max: 102,
          tickSize: 5,
        },
      }
    )
  }, [])

  if (isUserLoading) {
    return <LoadingSpinner />
  }

  return (
    <div className='row'>
      <div className='col-md-8'>
        {/* <div className='card mg-b-30'>
          <div className='card-body'>
            <h1>Future is shaping up.</h1>
            <p>
              Welcome {name}. We are so pleased that you could test our new
              platform. We’ve got some really exciting features to share with
              you, we know you’re going to love.
            </p>
            <p>
              Starting from previous year, our admin software have become even
              more important in our daily process. So it’s critical that the
              technology we choose for our new admin software provides a truly
              great experience. When we approach designing for new softwares, we
              focus on three key attributes.
            </p>
            <ul>
              <li>
                First, it must be easy to use from the initial login to the way
                you interact with it every day
              </li>
              <li>
                And we design our softwares to work better together, seamlessly
                and intuitively.
              </li>
            </ul>
            <p>
              We applied these principles when we created our new admin
              software. And we called it the "Back Office". Back Office is a
              breakthrough software that combines easiness and speed. It
              provides an entirely new way to operate our whole process.
            </p>
            <p>
              This is just a start and we can't wait for you to test the
              incredible experiences with the new Back Office.
            </p>
          </div>
        </div> */}
      </div>
      <div className='col-md-12'>
        <div className='card mg-b-30'>
          <div className='card-header'>
            <h6 className='lh-5 mg-b-0'>Testing Do &amp; Don't</h6>
          </div>
          <div className='card-body'>
            <ul className='list-group list-group-horizontal'>
              <li className='list-group-item'>
                <CheckCircleIcon color='green' className='mg-r-10' />{' '}
                <span>Test existing features</span>
              </li>
              <li className='list-group-item'>
                <CheckCircleIcon color='green' className='mg-r-10' />{' '}
                <span>Report bug, error and incorrect flow / process</span>
              </li>
              <li className='list-group-item'>
                <XCircleIcon color='red' className='mg-r-10' />{' '}
                <span>No feature request for now.</span>
                {/* <p className='tx-color-03 mg-0'>
                  There is a very good chance that we already have plan for that
                  feature.
                </p> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='col-md-8'>
        <TodayWidget />
      </div>
      <div className='col-md-4'>
        <div className='mg-b-30'>
          <LTVDownloadWidget />
        </div>
        <div className='card'>
          <div className='card-header pd-t-20 pd-b-0 bd-b-0 d-flex justify-content-between'>
            <h6 className='lh-5 mg-b-0'>Order</h6>
            <a href='' className='tx-13 link-03'>
              This Month <i className='icon ion-ios-arrow-down tx-12'></i>
            </a>
          </div>
          <div className='card-body pd-0 pos-relative'>
            <div className='pos-absolute t-10 l-20 z-index-10'>
              <div className='d-flex align-items-baseline'>
                <h1 className='tx-normal tx-rubik mg-b-0 mg-r-5'>285</h1>
                <p className='tx-11 tx-color-03 mg-b-0'>
                  <span className='tx-medium tx-success'>
                    0.3% <i className='icon ion-md-arrow-down'></i>
                  </span>{' '}
                  than last month
                </p>
              </div>
              <p className='tx-12 tx-color-03 wd-60p'>Average order per day</p>
            </div>

            <div className='chart-sixteen'>
              <div id='flotChart2' className='flot-chart'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
