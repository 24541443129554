import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import toast from 'react-hot-toast'
import Cart from '../../components/Cart'
import GridColumns from '../../components/GridColumns'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'
import Card from '../../components/Card'

export default function CartsPage() {
  const [carts, setCarts] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchCarts = async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: 'carts',
        params: {
          page: 1
        }
      })
      console.log('carts', data)
      if (data.success) {
        setCarts(data.data)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteCart = async (id, index) => {
    try {
      const { data } = await axios({
        method: 'DELETE',
        url: `carts/${id}`
      })
      console.log('delete response', data)
      if (data.success) {
        const temp = [...carts]
        temp.splice(index, 1)
        setCarts(temp)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const createOrderFromCart = async (userId, cartId) => {
    if (userId) {
      navigate(`orders/add?userId=${userId}&cartId=${cartId}`)
    } else {
      navigate(`users/add?cartId=${cartId}`)
    }
  }

  const sendNotification = async (userId, clientId) => {
    try {
      console.log({ userId, clientId })
    } catch (err) {
      const error = getErrorMessage(err)
      toast.error(error)
    }
  }

  React.useEffect(() => {
    fetchCarts()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Card>
        <Card.Header
          title='Carts'
          description='Carts'
          tableStats={[
            { label: 'Total Carts', value: carts.length },
          ]}
          btns={[]}
        />
      </Card>
      <GridColumns>
        {
          carts.map(
            (cart, index) => (
              <Cart
                cart={cart}
                key={index}
                index={index}
                onCreateOrder={createOrderFromCart}
                onSendNotification={sendNotification}
                onDelete={deleteCart}
              />
            ))
        }
      </GridColumns>
    </>
  )
}