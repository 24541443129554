import React from 'react'
import {
  Save as SaveIcon,
  X as XIcon
} from 'react-feather'
import { isEmpty } from 'underscore'

export default function VariantsList({
  variants = [],
  onDelete,
  onChange,
}) {

  return (
    <ul className='list-group list-group-flush tx-13'>
      {
        variants.map(
          (variant, index) => (
            <li
              className='list-group-item d-flex  mg-t-5 bd-0 rounded'
              style={{ backgroundColor: '#E5E9F2' }}
              key={index}
            >
              <div className='avatar'>
                <img
                  src={`${process.env.REACT_APP_IMAGE_API_URL}${!isEmpty(variant.product.files) && variant.product.files[0].url}_100.jpg`}
                  className='rounded-circle'
                  alt='' />
              </div>
              <div className='mg-l-15'>
                <p className='tx-medium mg-b-0'>
                  {variant.product.name}
                </p>
                <small className='tx-12 mg-b-0'>
                  ( {variant.product.weight}{variant.product.unit} )
                </small>
                <small className='tx-12 tx-color-03 mg-b-0 mg-l-5'>
                  {variant.product.price} ks
                </small>
              </div>
              <div className='mg-l-auto'>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  placeholder='4kg'
                  value={variant.name}
                  onChange={(e) => onChange(index, e.target.value)}
                />
              </div>
              <button
                type='button'
                className='btn btn-icon link-03'
                onClick={() => onDelete(variant.id)}
              >
                <XIcon className='tx-15' />
              </button>
            </li>
          ))
      }
    </ul>
  )
}
