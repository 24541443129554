import { useParams } from '@reach/router'
import React from 'react'
import Card from '../../components/Card'
import CollectionForm from './CollectionForm'

export default function EditCollectionPage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Collection'
        description='collections'
        href='/collections'
      />
      <CollectionForm editForm id={id} />
    </Card>
  )
}