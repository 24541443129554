import React from 'react'
import Card from '../../components/Card'
import DeliveryForm from './DeliveryForm'

export default function AddDeliveryPage() {
  return (
    <Card>
      <Card.Header
        title='Add New Delivery'
        description='deliveries'
        href='/deliveries'
      />
      <DeliveryForm />
    </Card>
  )
}