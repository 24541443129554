import { useParams } from '@reach/router'
import React from 'react'
import Card from '../../components/Card'
import OrderForm from './OrderForm'

export default function EditOrderPage() {
  const { id } = useParams()
  
  return (
    <Card>
      <Card.Header
        title='Edit Order'
        description='orders'
        href='/orders'
      />
      <OrderForm editForm id={id} />
    </Card>
  )
}