import React from 'react'

export default function ComponentList({ onClickAdd }) {
  return (
    <div>
      <div className='card mb-2'>
        <div className='card-body'>
          <img
            src='/assets/builder/product-collection-component.jpg'
            className='img-fluid'
          />
          <h6 className='card-title mt-3'>Product Collection</h6>
          <a
            href='#'
            className='btn btn-light btn-xs btn-block'
            onClick={() =>
              onClickAdd({
                block_type: 'collection_products',
                id: Math.random(),
              })
            }
          >
            Add
          </a>
        </div>
      </div>
      <div className='card mb-2'>
        <div className='card-body'>
          <img src='/assets/builder/link-component.jpg' className='img-fluid' />
          <h6 className='card-title mt-3'>Cover</h6>
          <a
            href='#'
            className='btn btn-light btn-xs btn-block'
            onClick={() =>
              onClickAdd({
                block_type: 'link',
                id: Math.random(),
              })
            }
          >
            Add
          </a>
        </div>
      </div>
    </div>
  )
}
