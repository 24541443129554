import React from 'react'
import Card from '../../components/Card'
import BadgeForm from './BadgeForm'

export default function AddBadgePage() {
  return (
    <Card>
      <Card.Header
        title='Add New Badge'
        description='badges'
        href='/badges'
      />
      <BadgeForm />
    </Card>
  )
}