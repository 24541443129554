import React from 'react'
import Card from '../../components/Card'
import CategoryForm from './CategoryForm'

export default function AddCategoryPage() {
  return (
    <Card>
      <Card.Header
        title='Add New Category'
        description='categories'
        href='/categories'
      />
      <CategoryForm />
    </Card>
  )
}