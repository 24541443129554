import React from 'react'
import Card from '../../components/Card'
import DeliveryDatesForm from './DeliveryDatesForm'

export default function AddDeliveryDates() {
  return (
    <Card>
      <Card.Header
        title='Add Delivery Dates'
        description='DeliveryDates'
        href='/delivery-dates'
      />
      <DeliveryDatesForm />
    </Card>
  )
}
