import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import MultiSelect from '../../common/MultiSelect'
import SingleSelect from '../../common/SingleSelect'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'
import getUrlParameter from '../../utils/getUrlParameter'

export default function TownshipForm({ editForm, id }) {
  const cityId = getUrlParameter('cityId')
  const [name, setName] = React.useState('')
  const [mmName, setMmName] = React.useState('')
  const [selectedCityId, setSelectedCityId] = React.useState(cityId || '')
  const [deliveryDays, setDeliveryDays] = React.useState([])
  const [deliveryTimeRanges, setDeliveryTimeRanges] = React.useState([])
  const [deliveryFee, setDeliveryFee] = React.useState(0)
  const [minimumAmount, setMinimumAmount] = React.useState(0)
  const [isActive, setIsActive] = React.useState(true)
  const [order, setOrder] = React.useState('')
  const [onDemand, setOnDemand] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)

  const days = [
    {
      label: 'Monday',
      value: 1
    },
    {
      label: 'Tuesday',
      value: 2
    },
    {
      label: 'Wednesday',
      value: 3
    },
    {
      label: 'Thursday',
      value: 4
    },
    {
      label: 'Friday',
      value: 5
    },
    {
      label: 'Saturday',
      value: 6
    },
    {
      label: 'Sunday',
      value: 7
    }
  ]

  const townshipSchema = Yup.object({
    name: Yup.string()
      .required('Name Required'),
    mmName: Yup.string()
      .required('Myanmar Name Required'),
    selectedCityId: Yup.string()
      .required('City Required'),
    deliveryDays: Yup.array()
      .required('Delivery Days Required'),
    // deliveryTimeRanges: Yup.array()
    //   .required('Delivery TimeRanges Required'),
  })

  const fetchTownship = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`townships/${id}`)
      console.log('township detail: ', data)
      if (data.success) {
        const township = data.data
        setName(township.name)
        setMmName(township.mm_name)
        setSelectedCityId(township.city.id)
        setDeliveryFee(township.delivery_fee)
        setDeliveryDays(days.filter(day => township.delivery_days.includes(day.value)))
        // setDeliveryTimeRanges(township.delivery_times.map(time => ({ label: time.name, value: time.id })))
        setMinimumAmount(township.minimum_amount)
        setOnDemand(Boolean(township.on_demand))
        setIsActive(Boolean(township.is_active))
        setOrder(township.order)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async ({
    name,
    mmName,
    selectedCityId,
    deliveryDays,
    deliveryTimeRanges,
    deliveryFee,
    minimumAmount,
    onDemand,
    isActive,
    order
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `townships/${id}` : `townships`
      const body = {
        name,
        mm_name: mmName,
        city_id: parseInt(selectedCityId),
        delivery_days: deliveryDays.map(day => day.value),
        // delivery_times: deliveryTimeRanges.map(timerange => timerange.value),
        delivery_fee: deliveryFee,
        minimum_amount: minimumAmount,
        on_demand: onDemand,
        is_active: isActive,
        delivery_details: [{}],
      }
      console.log('before body', body)
      const { data } = await axios({
        method,
        url: endpoint,
        data: editForm ? { ...body, order } : body, 
      })
      if (data.success) {
        navigate('/townships')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    editForm && fetchTownship()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={{
        name,
        mmName,
        selectedCityId,
        deliveryDays,
        deliveryTimeRanges,
        deliveryFee,
        minimumAmount,
        isActive,
        order,
        onDemand
      }}
      validationSchema={townshipSchema}
      onSubmit={values => {
        console.log('formik values', values)
        onSubmit(values)
      }}

    >
      {
        ({
          values,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <Container>
            <Form>
              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>*</span>Name
                </label>
                <div className='col-md-9'>
                  <Field
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='Hlaing'
                    autoFocus
                  />
                  <ErrorComponent>
                    <ErrorMessage name='name' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>*</span>Myanmar Name
                </label>
                <div className='col-md-9'>
                  <Field
                    name='mmName'
                    type='text'
                    className='form-control'
                    placeholder='လှိုင်မြို့နယ်'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='mmName' />
                  </ErrorComponent>
                </div>
              </div>

              {
                !cityId &&
                <div className='form-group row'>
                  <label className='col-md-3 tx-md-right col-form-label'>
                    <span className='tx-danger'>*</span>City
                  </label>
                  <div className='col-md-9'>
                    <SingleSelect
                      name='selectedCityId'
                      value={values.selectedCityId}
                      label='City'
                      onChange={cityId => setFieldValue('selectedCityId', cityId)}
                      onBlur={handleBlur}
                      endpoint='cities'
                    />
                    <ErrorComponent>
                      <ErrorMessage name='selectedCityId' />
                    </ErrorComponent>
                  </div>
                </div>
              }

              <div className='form-group row' >
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>*</span>Delivery Days
                </label>
                <div className='col-md-9'>
                  <MultiSelect
                    name='deliveryDays'
                    options={days}
                    onChange={(days) => setFieldValue('deliveryDays', days)}
                    defaultValue={deliveryDays}
                    className='pd-0'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='deliveryDays' />
                  </ErrorComponent>
                </div>
              </div>

              {/* <div className='form-group row'>
                <label className='col-3 tx-right col-form-label'>Delivery Time Ranges</label>
                <MultiSelect
                  name='deliveryTimeRanges'
                  onChange={(timeranges) => setFieldValue('deliveryTimeRanges', timeranges)}
                  endpoint={'delivery-times'}
                  defaultValue={deliveryTimeRanges}
                  className='col-9 pd-0'
                />
                <ErrorComponent className='offset-3'>
                  <ErrorMessage name='deliveryTimeRanges' />
                </ErrorComponent>
              </div> */}

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  Delivery Fee
                </label>
                <div className='col-md-9'>
                  <Field
                    name='deliveryFee'
                    type='number'
                    className='form-control'
                    placeholder='0'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>Minimum Amount</label>
                <div className='col-md-9'>
                  <Field
                    name='minimumAmount'
                    type='number'
                    className='form-control'
                    placeholder='0'
                  />
                </div>
              </div>

              {
                editForm &&
                <div className='form-group row'>
                  <label className='col-md-3 tx-md-right col-form-label' >Order</label>
                  <div className='col-md-9'>
                    <Field
                      name='order'
                      type='number'
                      className='form-control'
                      placeholder='1'
                    />
                  </div>
                </div>
              }

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-switch custom-control'>
                    <input
                      name='isActive'
                      id='isActive'
                      className='custom-control-input '
                      type='checkbox'
                      value={values.isActive}
                      checked={values.isActive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor='isActive' className='custom-control-label'>Active</label>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-switch custom-control'>
                    <input
                      name='onDemand'
                      id='onDemand'
                      className='custom-control-input'
                      type='checkbox'
                      value={values.onDemand}
                      checked={values.onDemand}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor='onDemand' className='custom-control-label'>On Demand</label>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <button type='submit' className='btn btn-primary btn-block'>
                    {
                      isSubmitting ? 'Submitting' : 'Submit'
                    }
                  </button>
                </div>
              </div>
            </Form>
          </Container>
        )
      }
    </Formik>
  )
}
