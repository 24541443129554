import React from 'react'
import { InstantSearch, Pagination, SearchBox, Stats } from 'react-instantsearch-dom'
import ProductHits from '../components/AlgoliaHits'
import AlgoliaSearchBox from '../components/AlgoliaSearchBox'
import AlgoliaPagination from '../components/AlgoliaPagination'
import Modal from './Modal'

export default function AlgoliaModal({
  id,
  searchClient,
  indexName,
  onChange,
  onCheck,
  onDismiss,
}) {
  return (
    <Modal id={id} onDismiss={onDismiss}>
      <Modal.Body>
        <div className='modal-body pd-20 pd-sm-30'>
          <h4 className='tx-18 tx-sm-20 mg-b-3'>Products You May Add</h4>
          <p className='mg-b-20 tx-color-03'>Products you may add to your bundle</p>
          <a
            href=''
            role='button'
            className='close pos-absolute t-15 r-15'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </a>
          <div className='bd pd-b-5'>
            <InstantSearch
              searchClient={searchClient}
              indexName={indexName}
              className='input'
            >
              <AlgoliaSearchBox />
              <ProductHits onChange={onChange} onCheck={onCheck} />
              <Pagination />
              {/* <AlgoliaPagination showFirst={true} showLast={true}/> */}
            </InstantSearch>
          </div>
          <div className='d-flex justify-content-center mg-t-30'>
            <button type='button' className='btn btn-primary mg-l-5'>Preview</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}