export const orderStatus = [
  {
    id: 'pending',
    name: 'Pending',
  },
  {
    id: 'processing',
    name: 'Processing',
  },
  {
    id: 'approved',
    name: 'Approved',
  },
  {
    id: 'cancelled',
    name: 'Cancelled',
  },
  {
    id: 'completed',
    name: 'Completed',
  },
]

export const paymentMethods = [
  {
    id: 'cod',
    name: 'အိမ်ရောက်ငွေချေ',
    shortName: 'အိမ်ရောက်ငွေချေ',
  },
  {
    id: 'bank_transfer',
    name: 'ဘဏ်လွှဲ',
    shortName: 'ဘဏ်လွှဲ',
    subs: [
      {
        id: 3,
        name: 'KBZ',
        shortName: 'KBZ',
      },
      {
        id: 1,
        name: 'CB',
        shortName: 'CB',
      },
      {
        id: 2,
        name: 'AYA',
        shortName: 'AYA',
      },
      {
        id: 4,
        name: 'WAVE MONEY',
        shortName: 'wavemoney'
      }
    ],
  },
  {
    id: 'mptpay',
    name: 'MPT PAY',
    shortName: 'mptpay'
  },
  {
    id: 'ayapay',
    name: 'AYA PAY',
    shortName: 'ayapay'
  },
  {
    id: 'kbzpay',
    name: 'KBZ pay',
    shortName: 'kbzpay',
  },
  {
    id: 'mytelpay',
    name: 'MYTEL PAY',
    shortName: 'mytelpay'
  },
  {
    id: 'onepay',
    name: 'Onepay',
    shortName: 'onepay'
  }
]

export const processingBatchNumbers = [
  {
    id: 1,
    name: 1,
  },
  {
    id: 2,
    name: 2,
  },
  {
    id: 3,
    name: 3,
  },
  {
    id: 4,
    name: 4,
  },
  {
    id: 5,
    name: 5,
  },
  {
    id: 6,
    name: 6,
  },
  {
    id: 7,
    name: 7,
  },
  {
    id: 8,
    name: 8,
  },
  {
    id: 9,
    name: 9,
  },
  {
    id: 10,
    name: 10,
  },
]

export const deliverySizes = [
  {
    id: 'small',
    name: 'Small',
  },
  {
    id: 'medium',
    name: 'Medium',
  },
  {
    id: 'large',
    name: 'Large',
  },
]

export const platforms = [
  {
    id: 'facebook',
    name: 'Facebook',
  },
  {
    id: 'call',
    name: 'Call',
  },
  {
    id: 'cart',
    name: 'Cart',
  }
]