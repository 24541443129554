export default function initOffcanvas(setIsToggleFilter) {
  window.$(function () {
    'use strict'
    // The code below is for demo purposes only.
    // For you to not be confused, please refer to
    // Off-Canvas starter template in Collections

    window.$('.off-canvas-menu').on('click', function (e) {
      e.preventDefault();
      var target = window.$(this).attr('href');
      window.$(target).addClass('show');
    });


    window.$('.off-canvas .close').on('click', function (e) {
      e.preventDefault();
      window.$(this).closest('.off-canvas').removeClass('show');
    })

    window.$(document).on('click touchstart', function (e) {
      e.stopPropagation();

      // closing of sidebar menu when clicking outside of it
      if (!window.$(e.target).closest('.off-canvas-menu').length) {
        var offCanvas = window.$(e.target).closest('.off-canvas').length;
        if (!offCanvas) {
          window.$('.off-canvas.show').removeClass('show');
          setIsToggleFilter(false)
        }
      }
    });
  });
}