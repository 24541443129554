import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import MultiSelect from '../../common/MultiSelect'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import FileUpload from '../../components/FileUpload'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'

export default function DeliveryForm({ editForm, id }) {
  const [name, setName] = React.useState('')
  const [phones, setPhones] = React.useState([])
  const [townships, setTownships] = React.useState([])
  const [contact, setContact] = React.useState('')
  const [logo, setLogo] = React.useState([])
  const [isActive, setIsActive] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(true)

  const deliverySchema = Yup.object({
    name: Yup.string()
      .required('Name Required'),
    phones: Yup.string()
      .required('Phones Required'),
    townships: Yup.string()
      .required('Townships Required'),
    contact: Yup.string()
      .required('Contact Required'),
  })

  const onSubmit = async ({
    name,
    phones,
    townships,
    contact,
    isActive
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `deliveries/${id}` : `deliveries`
      const body = {
        name,
        phones: phones.split(','),
        townships: townships.map(township => township.value),
        contact,
        files: logo.map((logo) => logo.id),
        is_active: isActive
      }
      console.log('body', body)
      const { data } = await axios({
        method,
        url: endpoint,
        data: body,
      })
      if (data.success) {
        toast.success('Success')
        navigate('/deliveries')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const fetchDelivery = async () => {
    try {
      const { data } = await axios.get(`deliveries/${id}`)
      console.log('delivery detail', data)
      if (data.success) {
        const delivery = data.data
        setName(delivery.name)
        setPhones(delivery.phones?.toString())
        setTownships(delivery.townships.map(township => ({ label: township.name, value: township.id })))
        setContact(delivery.contact)
        setLogo(delivery.files.map((file) => ({
          id: file.id,
          url: `${process.env.REACT_APP_IMAGE_API_URL}${file.url}_100.jpg`,
          status: 'success',
        })))
        setIsActive(Boolean(delivery.is_active))
        setIsLoading(false)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    editForm && fetchDelivery()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={{
        name,
        phones,
        townships,
        contact,
        isActive
      }}
      validationSchema={deliverySchema}
      onSubmit={(values) => {
        console.log('submit values', values)
        onSubmit(values)
      }}
    >
      {
        ({ values, setFieldValue, handleChange, handleBlur, isSubmitting }) => (
          <Container>
            <Form>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Name
                </label>
                <div className='col-md-9'>
                  <Field
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='Beexprss'
                    autoFocus
                  />
                  <ErrorComponent>
                    <ErrorMessage name='name' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Phones
                </label>
                <div className='col-md-9'>
                  <Field
                    name='phones'
                    type='text'
                    className='form-control'
                    placeholder='09123456789,09987654321'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='phones' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3  text-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Townships
              </label>
                <div className='col-md-9'>
                  <MultiSelect
                    name='townships'
                    endpoint='townships'
                    onChange={(townships) => setFieldValue('townships', townships)}
                    defaultValue={values.townships}
                    className='pd-0'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='townships' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  Contact
                </label>
                <div className='col-md-9'>
                  <Field
                    name='contact'
                    type='text'
                    className='form-control'
                    placeholder='North Oakkala'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='contact' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  Logo
                </label>
                <div className='col-md-9'>
                  <FileUpload
                    name='logo'
                    relatedType='deliveries'
                    multiple={false}
                    photos={logo}
                    setPhotos={setLogo}
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-switch custom-control'>
                    <input
                      name='isActive'
                      type='checkbox'
                      id='isActive'
                      className='custom-control-input'
                      value={values.isActive}
                      checked={values.isActive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor='isActive' className='custom-control-label'>
                      Active
                    </label>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <button
                    type='submit'
                    className='btn btn-primary btn-block'
                  >
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </button>
                </div>
              </div>
            </Form>
          </Container >
        )
      }
    </Formik >
  )
}