import axios from 'axios'
import React from 'react'
import getErrorMessage from '../../utils/getErrorMessage'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import {
  Plus as PlusIcon,
  Trash2 as DeleteIcon,
} from 'react-feather'
import Table from '../../components/Table'
import { navigate } from '@reach/router'

export default function DeliveryDates() {
  const [deliveryDates, setDeliveryDates] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [bulkDateIds, setBulkDateIds] = React.useState([])

  const onSelectionChange = (dateId) => {
    if (bulkDateIds.includes(dateId)) {
      setBulkDateIds(bulkDateIds.filter((id) => id !== dateId))
    } else {
      setBulkDateIds((prev) => [...prev, dateId])
    }
  }

  const onAllSelectionChange = () => {
    if (bulkDateIds.length === deliveryDates.length) {
      setBulkDateIds([])
    } else {
      setBulkDateIds(deliveryDates.map((date) => date.id))
    }
  }

  const handleBulkUpdate = async (name, value) => {
    try {
      console.log(name, value)
      const { data } = await axios({
        method: 'PUT',
        url: 'deliverydates',
        data: {
          delivery_dates: bulkDateIds,
          [name]: value,
        }
      })
      if (data.success) {
        fetchDeliveryDates() // not perfect
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  const handleBulkDelete = async () => {
    try {
      console.log('handle bulk delet3')
      const { data } = await axios({
        method: 'DELETE',
        url: 'deliverydates',
        data: {
          delivery_dates: bulkDateIds,
        }
      })
      if (data.success) {
        const temp = deliveryDates.filter(
          (date) => !bulkDateIds.includes(date.id)
        )
        setDeliveryDates(temp)
        setRows(temp.map((data) => ({
          id: data.id,
          date: data.date,
          limit: data.limit,
        })))
        setBulkDateIds([])
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  const deleteDeliveryDate = async (id, index) => {
    try {
      const { data } = await axios({
        method: 'DELETE',
        url: `deliverydates/${id}`
      })
      console.log('delete', data)
      if (data.success) {
        const temp = [...deliveryDates]
        temp.splice(index, 1)
        setDeliveryDates(temp)
        setRows(temp.map((data) => ({
          id: data.id,
          date: data.date,
          limit: data.limit,
        })))
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  const editDeliveryDates = async (
    id,
    index,
    name,
    value,
    defaultValue,
    setShowPopover
  ) => {
    try {
      console.log({
        name,
        value: value || defaultValue,
      })
      const { data } = await axios({
        method: 'PUT',
        url: `deliverydates/${id}/partial-update`,
        data: {
          name,
          value: value || defaultValue,
        },
      })
      console.log('partial deliverylimit : ', data.data)
      if (data.success) {
        const temp = [...deliveryDates]
        temp[index] = data.data
        setDeliveryDates(temp)
        setRows(
          temp.map((data) => ({
            id: data.id,
            date: data.date,
            limit: data.limit,
          }))
        )
        toast.success('Success')
        setShowPopover(false)
      }
    } catch (error) {
      console.log({ error })
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const fetchDeliveryDates = async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: 'deliverydates'
      })
      console.log('res', data.data)
      if (data.success) {
        setDeliveryDates(data.data)
        setRows(
          data.data.map((data) => ({
            id: data.id,
            date: data.date,
            limit: data.limit,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const askConfirmation = (onYes) => {
    console.log('inside askconfirmation')
    const isConfirm = window.confirm('Are u sure?')
    if (isConfirm) {
      onYes()
    }
  }

  const askValueForUpdate = (callBack) => {
    const value = window.prompt('Update')
    console.log({ value })
    if (value) {
      callBack('delivery_limit', value)
    }

  }

  React.useEffect(() => {
    fetchDeliveryDates()
  }, [])

  return (
    <Card>
      <div className='card-header d-flex justify-content-end bd-b-0'>
        <div className='dropdown'>
          <button
            type='button'
            id='dropdownMenuButton'
            data-toggle='dropdown'
            disabled={!bulkDateIds.length}
            className={`btn btn-sm dropdown-toggle btn-uppercase btn-white mg-t-sm-5 mg-r-5 pd-x-15 btn-xs`}
          >
            Bulk Actions
          </button>
          <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
            <a
              className='dropdown-item'
              onClick={() => askValueForUpdate(handleBulkUpdate)}
            >
              Set Limit
            </a>
            <a
              className='dropdown-item'
              onClick={() => askConfirmation(handleBulkDelete)}
            >
              <span className='tx-danger'>Delete</span>
            </a>
          </div>
        </div>
        <div>
          <button
            className={`btn btn-sm btn-uppercase btn-primary mg-t-sm-5 mg-r-5 pd-x-15 btn-xs`}
            onClick={() => navigate('/delivery-dates/add')}
          >
            <PlusIcon />Add Delivery Dates
            </button>
        </div>
      </div>
      <Table
        onAllChange={onAllSelectionChange}
        onChange={onSelectionChange}
        onClick={editDeliveryDates}
        allData={deliveryDates}
        bulkIds={bulkDateIds}
        rows={rows}
        cols={[
          {
            title: {
              render: {
                type: 'checkbox',
                key: 'id',
                className: 'cursor-pointer',
              }
            },
            width: '50px',
            render: {
              type: 'checkbox',
              key: 'id',
            }
          },
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Date',
            key: 'date',
            width: '150px',
          },
          {
            title: 'Limit',
            key: 'limit',
            editable: {
              type: 'number',
              name: 'delivery_limit',
            }
          },
          {
            title: 'Actions',
            width: '50px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: DeleteIcon,
                  onClick: deleteDeliveryDate,
                  variant: 'btn-danger',
                }
              ]
            }
          }
        ]}
      />
    </Card>
  )
}
