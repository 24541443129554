import React from 'react'
import Card from '../../components/Card'
import ProductForm from './ProductForm'

export default function AddProductPage() {
  return (
    <Card>
      <Card.Header
        title='Add New Product'
        description='Products'
        href='/products'
      />
      <ProductForm />
    </Card>
  )
}