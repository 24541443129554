export const productUnits = [
  {
    id: 'item',
    name: 'Item',
  },
  {
    id: 'viss',
    name: 'Viss',
  },
  {
    id: 'litre',
    name: 'Litre',
  },
  {
    id: 'kg',
    name: 'Kg',
  },
  {
    id: 'g',
    name: 'G',
  },
]