import React from 'react'
import {
  X as XIcon
} from 'react-feather'
import { isEmpty } from 'underscore';

const SortableProduct = ({
  value,
  index,
  onDelete,
}) => {

  return (
    <li className='media bg-ui-02 pd-y-10 pd-x-15 mg-t-5 rounded cursor-grab' key={index}>
      <div className='avatar'>
        <img
          src={`${process.env.REACT_APP_IMAGE_API_URL}${!isEmpty(value.files) && value.files[0].url}_100.jpg`}
          className='rounded-circle'
          alt='' />
      </div>
      <div className='media-body mg-l-15'>
        <h6 className='tx-13 mg-b-2'>{value.name}</h6>
        <span className='d-block tx-color-02'>{value.price} ks</span>
      </div>
      <button
        type='button'
        className='btn btn-icon link-03'
        onClick={() => onDelete(value.id)}
      >
        <XIcon className='tx-15' />
      </button>
    </li>
  )
}

export default SortableProduct;
