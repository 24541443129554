import React from 'react'
import ReactDOM from 'react-dom'

const el = document.createElement('div')
const modalPortalRoot = document.getElementById('modal-portal')
const modalRoot = document.querySelector('#modal-portal .modal')

export default function Modal({ id, modalSize, children, onDismiss }) {
  const modalRef = React.useRef(null)

  React.useEffect(() => {
    modalPortalRoot.appendChild(el)
    modalRef.current.classList.add('show')

    const backdrop = document.createElement('div')
    backdrop.classList.add('modal-backdrop', 'fade', 'show')

    document.body.appendChild(backdrop)

    return () => {
      document.body.removeChild(backdrop)
    }
  }, [])

  return ReactDOM.createPortal(
    <div
      id={id}
      className='modal fade'
      role='dialog'
      ref={modalRef}
      onClick={onDismiss}
    >
      <div className={`modal-dialog modal-dialog-scrollable ${modalSize}`}>
        <div
          className='modal-content'
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {children}
        </div>
      </div>
    </div>,
    el
  )
}

Modal.Header = ({ children }) => {
  return <div className='modal-header'>{children}</div>
}

Modal.Body = ({ children, style }) => {
  return (
    <div className='modal-body' style={style}>
      {children}
    </div>
  )
}

Modal.Footer = ({ children }) => {
  return <div className='modal-footer'>{children}</div>
}
