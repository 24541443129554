import React from 'react'
import PropTypes from 'prop-types'

export default function TableStats({ data , className}) {
  return (
    <div>
      <div className={`${className} d-sm-flex`}>
        {
          data.map((data, index) => (
            <div className='media mg-r-30' key={index}>
              <div className='media-body'>
                <h6 className='tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8'>
                  {data.label}
                </h6>
                <h4 className='tx-15 tx-sm-13 tx-md-14 tx-normal tx-rubik mg-b-0'>
                  {data.value}
                </h4>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

TableStats.propTypes={
  data: PropTypes.array,
}

