import React from 'react'
import axios from 'axios'
import { Edit as EditIcon } from 'react-feather'
import toast from 'react-hot-toast'
import '../assets/css/popover.css'
import getErrorMessage from '../utils/getErrorMessage'
import Editable from './Editable'

export default function Popover({
  id,
  index,
  type,
  name,
  currentValue,
  defaultValue,
  options = [],
  onClick,
  children
}) {
  const [showPopover, setShowPopover] = React.useState(false)
  const [toggleValue, setToggleValue] = React.useState(Boolean(children))

  const handleChange = async (e) => {
    try {
      const { data } = await axios.put(`products/${id}/partial-update`, {
        name,
        value: !toggleValue,
      })
      if (data.success) {
        setToggleValue(!toggleValue)
        toast.success('Success')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  return (
    <span className='pos-relative'>
      {
        showPopover &&
        <Editable
          id={id}
          index={index}
          type={type}
          name={name}
          currentValue={currentValue}
          defaultValue={defaultValue}
          onClick={onClick}
          options={options}
          setShowPopover={setShowPopover}
        />
      }
      {
        type === 'switch' ? (
          <span className='custom-control custom-switch'>
            <input
              type='checkbox'
              className='custom-control-input'
              id={id + name}
              value={toggleValue}
              checked={toggleValue}
              onChange={handleChange}
            />
            <label className='custom-control-label' htmlFor={id + name}>&nbsp;</label>
          </span>
        ) : (
            <span
              className='hover pos-relative'
              onClick={() => setShowPopover(!showPopover)}
            >
              {children}
              <i className='pos-absolute tx-primary t--5 mg-l-1'>
                <EditIcon size={18} />
              </i>
            </span>
          )
      }
    </span >
  )
}