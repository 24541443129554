import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import Cookies from 'js-cookie'
import toast from 'react-hot-toast'
import { useSetRecoilState } from 'recoil'
import * as Yup from 'yup'
import ErrorComponent from '../../components/ErrorComponent'
import getErrorMessage from '../../utils/getErrorMessage'
import { loading, loggedIn, userData, zcToken } from '../../utils/recoil'

export default function VerificationPage(props) {
  const phone = props.location.state.phone
  const redirect = props.location.state.redirect
  const setToken = useSetRecoilState(zcToken)
  const setIsLoggedIn = useSetRecoilState(loggedIn)
  const setIsUserLoading = useSetRecoilState(loading)
  const setUserData = useSetRecoilState(userData)

  const onSubmit = async ({ otp }) => {
    try {
      setIsUserLoading(true)
      axios.defaults.baseURL = process.env.REACT_APP_MAIN_PUBLIC_URL
      const { data } = await axios({
        method: 'POST',
        url: 'auth/otp-confirmation',
        data: {
          phone,
          otp
        }
      })
      console.log('verification response', data)
      if (data.success) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
        Cookies.set('zcToken', data.token, {
          expires: 365,
        })
        setToken(data.token)
        setIsLoggedIn(true)
        setUserData(data.data)
        setIsUserLoading(false)
        toast.success('Success')
        navigate(`/${redirect}`)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
      setIsUserLoading(false)
    }
  }

  return (
    <Formik
      initialValues={{
        otp: ''
      }}
      validationSchema={Yup.object({
        otp: Yup.string()
          .required('Otp Required')
          .min(4, 'At least 4')
          .max(4, 'At most 4')
      })}
      onSubmit={(values) => {
        console.log('formik values', values)
        onSubmit(values)
      }}
    >
      {
        ({ isSubmitting }) => (
          <div className='row bd'>
            <Form className='wd-400 ht-600  mg-t-100 mg-l-auto mg-r-auto'>
              <div className='d-flex flex-column justify-content-center pd-20 mg-r-10 mg-l-10'>
                <div className='form-group'>
                  <Field
                    name='otp'
                    type='number'
                    placeholder='1234'
                    className='form-control mg-t-10'
                    autoFocus />
                  <ErrorComponent>
                    <ErrorMessage name='otp' />
                  </ErrorComponent>
                </div>
                <div className='form-group'>
                  <button type='submit' className='btn btn-primary btn-block'>
                    {isSubmitting ? 'Signing In...' : 'Sign In'}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        )
      }
    </Formik>
  )
}