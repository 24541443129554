import React from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import getErrorMessage from '../utils/getErrorMessage'
import LoadingSpinner from './LoadingSpinner'

export default function OrderDetail({ id }) {
  const [order, setOrder] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchOrder = async () => {
    try {
      const { data } = await axios.get(`orders/${id}`)
      if (data.success) {
        setOrder(data.data)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    fetchOrder()
  }, [])

  React.useEffect(() => {
    if (!isLoading) {
      window.$('[data-toggle="popover"]').popover()
    }
  }, [isLoading])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    // <div className='content tx-13'>
    <div>
      <div className='container pd-x-0 pd-lg-x-10 pd-xl-x-0'>
        <div className='row'>
          <div className='col-sm-6 col-lg-8'>
            <label className='tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03'>
              Shipped To
            </label>
            <h6 className='tx-15 mg-b-10'>{order.user.name}</h6>
            <p className='mg-b-0'>
              {order.address.address}, {order.address.township.name}
            </p>
            <p className='mg-b-0'>Tel No: {order.user.phone}</p>
          </div>
          <div className='col-sm-6 col-lg-4'>
            <label className='tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03'>
              Invoice Information
            </label>
            <ul className='list-unstyled lh-7'>
              <li className='d-flex justify-content-between'>
                <span>Order Number</span>
                <span>{order.order_number}</span>
              </li>
              <li className='d-flex justify-content-between'>
                <span>Delivery Date</span>
                <span>{order.delivery_date}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className='table-responsive mg-t-40'>
          <table className='table table-invoice bd-b' width={'100%'}>
            <thead>
              <tr>
                <th className='wd-10p'></th>
                <th className='wd-60p'>Product</th>
                <th className='tx-right wd-10p'>QTY</th>
                <th className='tx-right wd-10p'>Unit Price</th>
                <th className='tx-right wd-10p'>Amount</th>
              </tr>
            </thead>
            <tbody>
              {order.order_items.map((item, index) => (
                <tr key={index}>
                  <td scope='row'>
                    <img
                      src={`${process.env.REACT_APP_IMAGE_API_URL}${item.product.thumb.url}_100.jpg`}
                      width={25}
                      className='img-fluid cursor-pointer'
                      alt=''
                      data-container='body'
                      data-toggle='popover'
                      data-content={`<img src=${process.env.REACT_APP_IMAGE_API_URL}${item.product.thumb.url}_100.jpg alt=''/>`}
                      data-html={true}
                    />
                  </td>
                  <td className='tx-nowrap wd-60p'>{item.product.name}</td>
                  <td className='tx-right wd-10p'>{item.quantity}</td>
                  <td className='tx-right wd-10p'>{item.product.price}</td>
                  <td className='tx-right wd-10p'>
                    {item.product.price * item.quantity}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='row justify-content-between'>
          <div className='col-sm-6 col-lg-6 order-2 order-sm-0 mg-t-40 mg-sm-t-0'>
            <label className='tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03'>
              Notes
            </label>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo.
            </p>
          </div>
          <div className='col-sm-6 col-lg-4 order-1 order-sm-0'>
            <div className='table-responsive'>
              <table className='table table-borderless table-sm' width={'100%'}>
                <tbody>
                  <tr>
                    <td className='tx-right wd-50p'>Sub-Total</td>
                    <td className='wd-50p tx-right'>{order.sub_total} MMK</td>
                  </tr>
                  <tr>
                    <td className='tx-right wd-50p'>Total</td>
                    <td className='wd-50p tx-right'>{order.total} MMK</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
