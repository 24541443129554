import React from 'react'
import Card from '../../components/Card'
import PromotionForm from './PromotionForm'

export default function AddPromotionPage() {
  return (
    <Card>
      <Card.Header
        title='Add New Promotion'
        description='Promotions'
        href='/promotions'
      />
      <PromotionForm />
    </Card>
  )
}
