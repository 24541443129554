export default function initTooltip() {
  window.$(function () {
    'use strict'

    window.$('[data-toggle="tooltip"]').tooltip()

    window.$('.df-example .btn-primary').tooltip({
      template: '<div class="tooltip tooltip-primary" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    })

    window.$('.df-example .btn-secondary').tooltip({
      template: '<div class="tooltip tooltip-secondary" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    })

    window.$('.df-example .btn-success').tooltip({
      template: '<div class="tooltip tooltip-success" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    })

    window.$('.df-example .btn-danger').tooltip({
      template: '<div class="tooltip tooltip-danger" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    })


  });
}