import React from 'react'
import axios from 'axios'
import { Plus as PlusIcon } from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import LoadingSpinner from '../../components/LoadingSpinner'
import { setThousandWithComma } from '../../utils/formatThousands'
import getErrorMessage from '../../utils/getErrorMessage'

export default function EntriesPage() {
  const [entries, setEntries] = React.useState([{}])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchEntries = async () => {
    try {
      const { data } = await axios.get('inventories')
      console.log('inventories', data)
      if (data.success) {
        setEntries(data.data)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    fetchEntries()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Inventories'
        description='Entries'
        tableStats={[{ label: 'total products', value: entries.length }]}
        btns={[
          {
            title: 'Add New Entry',
            icon: PlusIcon,
            href: `/inventories/entries/add`,
            variant: 'primary',
          },
        ]}
      />
      <div className='table-responsive'>
        <table className='inventory-table table tx-center table-dashboard mg-b-0'>
          <thead className='thead-light'>
            <tr>
              <th colSpan={2} className='fixed'>
                Product
              </th>
              <th colSpan={2}>Opening</th>
              <th colSpan={2}>Purchase</th>
              <th colSpan={2}>Sale Return</th>
              <th>Purchase FOC</th>
              <th colSpan={2}>Sale</th>
              <th colSpan={2}>Waste</th>
              <th colSpan={2}>Promotion</th>
              <th>Sale FOC</th>
              <th colSpan={2}>Purchase Return</th>
              <th colSpan={2}>Adjustment</th>
              <th colSpan={2}>Balance</th>
              <th>Note</th>
            </tr>
          </thead>
          <thead className='thead-dark'>
            <tr>
              <th width={'120px'} className='fixed'>
                SKU
              </th>
              <th className='fixed'>Name</th>
              <th>Qty</th>
              <th>Total</th>
              <th>Qty</th>
              <th>Total</th>
              <th>Qty</th>
              <th>Total</th>
              <th>Qty</th>
              <th>Qty</th>
              <th>Total</th>
              <th>Qty</th>
              <th>Total</th>
              <th>Qty</th>
              <th>Total</th>
              <th>Qty</th>
              <th>Qty</th>
              <th>Total</th>
              <th>Qty</th>
              <th>Total</th>
              <th>Qty</th>
              <th>Total</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody className='tx-right'>
            {entries.map((entry, index) => (
              <tr
                key={index}
                className={entry.total_balance < 0 ? 'tx-danger' : ''}
              >
                <td className='fixed'>{entry.product?.sku}</td>
                <td className='tx-left fixed'>{entry.product?.name}</td>
                <td>{entry.opening_quantity}</td>
                <td>{setThousandWithComma(entry.opening_total)}</td>
                <td>{entry.purchase_quantity}</td>
                <td>{setThousandWithComma(entry.purchase_total)}</td>
                <td>{entry.sale_return_quantity}</td>
                <td>{setThousandWithComma(entry.sale_return_total)}</td>
                <td>{entry.purchase_foc_quantity}</td>
                <td>{entry.sale_quantity}</td>
                <td>{setThousandWithComma(entry.sale_total)}</td>
                <td>{entry.waste_quantity}</td>
                <td>{setThousandWithComma(entry.waste_total)}</td>
                <td>{entry.promo_quantity}</td>
                <td>{setThousandWithComma(entry.promo_total)}</td>
                <td>{entry.sale_foc_quantity}</td>
                <td>{entry.purchase_return_quantity}</td>
                <td>{setThousandWithComma(entry.purchase_return_total)}</td>
                <td>{entry.adjustment_quantity}</td>
                <td>{setThousandWithComma(entry.adjustment_total)}</td>
                <td>{entry.total_quantity}</td>
                <td>{setThousandWithComma(entry.total_balance)}</td>
                <td>{entry.note}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  )
}
