import React from 'react'
import Card from '../../components/Card'
import CountryForm from './CountryForm'

export default function AddCountryPage() {
  return (
    <Card>
      <Card.Header
        title='Add New Country'
        description='countries'
        href='/countries'
      />
      <CountryForm/>
    </Card>
  )
}