import React from 'react'
import { useParams } from '@reach/router'
import Card from '../../components/Card'
import DeliveryTimeForm from './DeliveryTimeForm'

export default function EditDeliveryTimePage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit DeliveryTime'
        description='deliveryTimes'
        href='/delivery-times'
      />
      <DeliveryTimeForm editForm id={id} />
    </Card>
  )
}
