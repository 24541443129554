import { info, primary, success, danger } from './colors.json'

export const weeklySales = {
  labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  datasets: [
    {
      label: 'Sales',
      data: [100, 200, 300, 400, 500, 600, 700],
      backgroundColor: info
    }
  ]
}

export const weeklyAverageSales = {
  labels: ['Weekly Average Sales'],
  datasets: [
    {
      label: 'Average Sales',
      data: [400],
      backgroundColor: primary
    }
  ]
}

export const promotions = {
  labels: ['Referrals', 'Buy One Get Free', 'Love ZC', 'Weekly Promotions'],
  datasets: [
    {
      label: 'Promotions',
      data: [300, 200, 200, 400],
      backgroundColor: [primary, info, danger, success]
    }
  ]
}

export const SalesWithCustomers = {
  labels: ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'],
  datasets: [
    {
      label: 'Old Customers',
      data: [100, 200, 300, 400, 300, 600, 100],
      borderColor: info,
      fill: false
    },
    {
      label: 'New Customers',
      data: [500, 600, 500, 400, 500, 100, 700],
      borderColor: primary,
      fill: false
    },
  ]
}
