import { Form, Formik, Field, ErrorMessage } from 'formik'
import React from 'react'
import Container from './Container'
import SingleSelect from '../common/SingleSelect'
import ErrorComponent from './ErrorComponent'
import * as Yup from 'yup'
import axios from 'axios'
import getErrorMessage from '../utils/getErrorMessage'
import toast from 'react-hot-toast'
import { navigate } from '@reach/router'

export default function AddressForm({ userId }) {
  const [address, setAddress] = React.useState('')
  const [townshipId, setTownshipId] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [notes, setNotes] = React.useState('')
  const [isPrimary, setIsPrimary] = React.useState(false)
  const [type, setType] = React.useState(null)
  const [isClose, setIsClose] = React.useState(false)
  const closeRef = React.useRef(null)
  closeRef.current = isClose;

  console.log('cuttent', closeRef.current)

  const addressSchema = Yup.object({
    address: Yup.string()
      .required('Address Required')
  })

  const onSubmit = async ({
    address,
    townshipId,
    phone,
    notes,
    isPrimary,
  }) => {
    try {
      const { data } = await axios({
        method: 'POST',
        url: 'addresses',
        data: {
          address,
          phone,
          notes,
          user_id: userId,
          township_id: townshipId,
          is_primary: isPrimary,
          type,
        }
      })
      console.log('address create', data)
      if (data.success) {
        setIsClose(true)
      }
    } catch (error) {
      const err = getErrorMessage(error)
      toast.error(err)
    }
  }

  return (
    <div className='modal-dialog modal-dialog-scrollable'>
      <div className='modal-content'>
        <Container>
          <Formik
            initialValues={{
              address,
              townshipId,
              phone,
              notes,
              isPrimary,
            }}
            validationSchema={addressSchema}
            onSubmit={(values) => {
              console.log('submitting')
              onSubmit(values)
            }}
          >
            {
              ({
                values,
                handleChange,
                setFieldValue,
                handleBlur,
                isSubmitting,
              }) => (
                <Form>
                  <div className='modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30'>
                    <div className='form-group'>
                      <label className='tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03'>
                        Address
                      </label>
                      <Field
                        name='address'
                        type='text'
                        className='form-control'
                        placeholder='Enter Address'
                      />
                      <ErrorComponent>
                        <ErrorMessage name='address' />
                      </ErrorComponent>
                    </div>
                    <div className='form-group'>
                      <label className='tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03'>
                        Township
                      </label>
                      <SingleSelect
                        name='townshipId'
                        label='Township'
                        value={values.townshipId}
                        endpoint='townships'
                        onChange={townshipId => setFieldValue('townshipId', townshipId)}
                        onBlur={handleBlur}
                      />
                      <ErrorComponent>
                        <ErrorMessage name='townshipId' />
                      </ErrorComponent>
                    </div>
                    <div className='form-group'>
                      <label className='tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03'>
                        Phone
                      </label>
                      <Field
                        name='phone'
                        type='tel'
                        className='form-control'
                        placeholder='09799000099'
                      />
                      <ErrorComponent>
                        <ErrorMessage name='phone' />
                      </ErrorComponent>
                    </div>
                    <div className='form-group'>
                      <label className='tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03'>
                        Note
                      </label>
                      <Field
                        name='notes'
                        as='textarea'
                        className='form-control'
                        placeholder='To be in time'
                      />
                    </div>
                    <div className='form-group'>
                      <div className='col-md-4'>
                        <div className='custom-switch custom-control'>
                          <input
                            name='isPrimary'
                            id='isPrimary'
                            className='custom-control-input pd-0'
                            type='checkbox'
                            value={values.isPrimary}
                            checked={values.isPrimary}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor='isPrimary' className='custom-control-label'>Default</label>
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <button
                        type='submit'
                        className='btn btn-primary btn-block btn-uppercase'
                        data-dismiss={isClose ? 'modal' : ''}
                      >
                        {isSubmitting ? 'Saving' : 'Save'}
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }
          </Formik>
        </Container>
      </div>
    </div>
  )
}

