import React from 'react'
import { useParams } from '@reach/router'
import ProductForm from './ProductForm'
import Card from '../../components/Card'

export default function EditProductPage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Product'
        description='Products'
        href='/products'
      />
      <ProductForm editForm id={id} />
    </Card>
  )
}