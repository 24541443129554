import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Edit2 as EditIcon,
  Plus as PlusIcon,
  Trash2 as DeleteIcon
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import getErrorMessage from '../../utils/getErrorMessage'
import LoadingSpinner from '../../components/LoadingSpinner'

export default function WarehousesPage() {
  const [warehouses, setWarehouses] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchWarehouses = async () => {
    try {
      const { data } = await axios.get('warehouses')
      if (data.success) {
        setWarehouses(data.data)
        setRows(
          data.data.map((data) => ({
            id: data.id,
            name: data.name,
          }))
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }
  const restoreWarehouse = async (id, index) => {
    try {
      const { data } = await axios.post(`warehouses/${id}/restore`)
      if (data.success) {
        const temp = [...warehouses]
        temp[index] = data.data
        setWarehouses(temp)
        setRows(temp)
        toast.remove()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteWarehouse = async (id, index) => {
    try {
      const { data } = await axios.delete(`warehouses/${id}`)
      if (data.success) {
        const temp = [...warehouses]
        temp.splice(index, 1)
        setWarehouses(temp)
        setRows(temp)
        toast.success(
          <UndoAction
            onClick={() => restoreWarehouse(id, index)}
            name={warehouses[index].name}
          />,
          4000
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
      fetchWarehouses()
    },[])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Warehouses'
        description='Warehouses'
        tableStats={[{ label: 'total warehouses', value: warehouses.length }]}
        btns={[
          {
            title: 'Add new warehouse',
            icon: PlusIcon,
            href: 'warehouses/add',
            variant: 'primary',
          },
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Name',
            key: 'name',
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id) => navigate(`warehouses/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteWarehouse,
                },
              ]
            }
          },
        ]}
      />
    </Card>
  )
}
