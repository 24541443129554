import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import FileUpload from '../../components/FileUpload'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'

export default function CountryForm({ editForm, id }) {
  const [name, setName] = React.useState('')
  const [shortName, setShortName] = React.useState('')
  const [flag, setFlag] = React.useState([])
  const [isActive, setIsActive] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(true)

  const countrySchema = Yup.object({
    name: Yup.string()
      .required('Name Required'),
    shortName: Yup.string()
      .required('Short Name Required'),
    isActive: Yup.bool()
  })

  const fetchCountry = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`countries/${id}`)
      console.log('detail data', data)
      if (data.success) {
        const country = data.data
        setName(country.name)
        setShortName(country.short_name)
        setFlag(country.files.map((file) => ({
          id: file.id,
          url: `${process.env.REACT_APP_IMAGE_API_URL}${file.url}_100.jpg`,
          status: 'success',
        })))
        setIsActive(Boolean(country.is_active))
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async ({
    name,
    shortName,
    isActive,
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `countries/${id}` : `countries`
      const body = {
        name,
        short_name: shortName,
        is_active: isActive,
        files: flag.map((flag) => flag.id)
      }
      console.log('body', body)
      const { data } = await axios({
        method,
        url: endpoint,
        data: body,
      })
      if (data.success) {
        toast.success('Success')
        navigate('/countries')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    editForm && fetchCountry()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={{
        name,
        shortName,
        isActive,
      }}
      validationSchema={countrySchema}
      onSubmit={(values) => {
        console.log('formik values', values)
        onSubmit(values)
      }}
    >
      {
        ({ values, setFieldValue, isSubmitting, handleChange, handleBlur }) => (
          <Container>
            <Form >
              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Name
                  </label>
                <div className='col-md-9'>
                  <Field
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='Myanmar'
                    autoFocus
                  />
                  <ErrorComponent>
                    <ErrorMessage name='name' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Short Name
                </label>
                <div className='col-md-9'>
                  <Field
                    name='shortName'
                    type='text'
                    className='form-control'
                    placeholder='MMR'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='shortName' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Flag
                  </label>
                <div className='col-md-9'>
                  <FileUpload
                    name='flag'
                    relatedType='countries'
                    multiple={false}
                    photos={flag}
                    setPhotos={setFlag}
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-control custom-switch'>
                    <input
                      name='isActive'
                      type='checkbox'
                      id='isActive'
                      className='custom-control-input'
                      value={values.isActive}
                      checked={values.isActive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label className='custom-control-label' htmlFor='isActive'>Active </label>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <button type='submit' className='btn btn-primary btn-block btn-uppercase'>
                    {isSubmitting ? 'submitting' : 'submit'}
                  </button>
                </div>
              </div>
            </Form>
          </Container>
        )
      }
    </Formik >
  )
}