import { useParams } from '@reach/router'
import React from 'react'
import PageHeader from '../../components/PageHeader'
import ConfigForm from './ConfigForm'

export default function EditConfigPage(props) {
  const { id } = useParams()

  return (
    <>
      <PageHeader
        title='Edit Config '
        breadcrumbs={['system', 'configurations']}
        btns={[
          {
            title: 'Go Back to Configurations',
            icons: 'left arrow',
            href: '/configurations',
            variant: 'white'
          }
        ]}
      />
      <ConfigForm editForm id={id} />
    </>
  )
}