import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import PropTypes from 'prop-types'
import SortableImage from './SortableImage';

const SortableList = SortableContainer(({ className, onDelete, renderItemComponent, items = [] }) => (
  <div className={`${className}`}>
    <ul className={`
    list-unstyled
    media-list 
    tx-12 tx-sm-13
    mg-b-5
    ${renderItemComponent === SortableImage && 'row'}`
    }>
      {items && items.map((value, index) => (
        <SortableItem
          renderItemComponent={renderItemComponent}
          key={`${index}-${value}`}
          index={index}
          value={value}
          onDelete={onDelete}
        />
      ))}
    </ul>
  </div>
));

SortableList.propTypes = {
  items: PropTypes.array
}

const SortableItem = SortableElement(
  ({ renderItemComponent: Component, value, index, onDelete }) => (
    <Component index={index} value={value} onDelete={onDelete} />
  )
)

export default SortableList;