import React from 'react'
import { useParams } from '@reach/router'
import Card from '../../components/Card'
import RoleForm from './RoleForm'

export default function EditRolePage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Role'
        description='roles'
        href='/roles'
      />
      <RoleForm editForm id={id} />
    </Card>

  )
}