import React from 'react'
import PageHeader from '../../components/PageHeader'
import ConfigForm from './ConfigForm'

export default function AddConfigPage() {
  return (
    <div>
      <PageHeader
        title='Add New Configuration'
        breadcrumbs={['system', 'configurations']}
        btns={[
          {
            title: 'Go Back to Configurations',
            icons: 'arrow left',
            href: '/configurations',
            variant: 'white'
          }
        ]}
      />
     <ConfigForm />

    </div>
  )
}