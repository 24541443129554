import React from 'react'
import { navigate } from '@reach/router'
import arrayMove from 'array-move'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import slugify from 'slugify'
import * as Yup from 'yup'
import AlgoliaModal from '../../common/AlgoliaModal'
import MultiSelect from '../../common/MultiSelect'
import SingleSelect from '../../common/SingleSelect'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import LoadingSpinner from '../../components/LoadingSpinner'
import SortableList from '../../components/SortableList'
import SortableProduct from '../../components/SortableProduct'
import { searchClient } from '../../data/searchClient'
import getErrorMessage from '../../utils/getErrorMessage'
import axios from 'axios'
import FileUpload from '../../components/FileUpload'
import VariantsList from '../../components/VariantsList'
import { productTypes as types } from '../../data/types'
import { productUnits as units } from '../../data/units'

export default function ProductForm({ editForm, id }) {
  const [name, setName] = React.useState('')
  const [mmName, setMmName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [mmDescription, setMmDescription] = React.useState('')
  const [categories, setCategories] = React.useState([])
  const [townships, setTownships] = React.useState([])
  const [brandId, setBrandId] = React.useState('')
  const [suppliers, setSuppliers] = React.useState([])
  const [type, setType] = React.useState('basic')
  const [products, setProducts] = React.useState([])
  const [price, setPrice] = React.useState('')
  const [prices, setPrices] = React.useState([])
  const [slug, setSlug] = React.useState('')
  const [sku, setSku] = React.useState('')
  const [weight, setWeight] = React.useState('')
  const [unit, setUnit] = React.useState('item')
  const [maxQuantityPerOrder, setMaxQuantityPerOrder] = React.useState(0)
  const [outOfStock, setOutOfStock] = React.useState(false)
  const [stockLocation, setStockLocation] = React.useState('')
  const [purchaseLocation, setPurchaseLocation] = React.useState('')
  const [photos, setPhotos] = React.useState([])
  const [isActive, setIsActive] = React.useState(true)
  const [isHighlighted, setIsHighlighted] = React.useState(false)
  const [variants, setVariants] = React.useState([])
  const [badges, setBadges] = React.useState([])
  const [
    continueSellingAfterOutOfStock,
    setContinueSellingAfterOutOfStock,
  ] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(true)
  const [showVariantPicker, setShowVariantPicker] = React.useState(false)
  const [showProductPicker, setShowProductPicker] = React.useState(false)
  const photosRef = React.useRef()
  photosRef.current = photos

  const dropdownItems = [
    {
      href: '/products/add',
      // onClick: onSubmitAndAddNew,
      name: 'Submit and Add New'
    },
    {
      href: '/products/2/edit',
      // onClick: onSubmitAndStay,
      name: 'Submit and Stay'
    }
  ]

  const fetchProduct = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`products/${id}`)
      console.log('Product Detail', data)
      if (data.success) {
        const product = data.data
        setName(product.name)
        setMmName(product.mm_name)
        setDescription(product.description)
        setMmDescription(product.mm_description)
        setType(product.type)
        setBrandId(product.brand_id)
        setPrice(product.price)
        setSlug(product.slug)
        setSku(product.sku)
        setWeight(product.weight)
        setUnit(product.unit)
        setMaxQuantityPerOrder(product.max_quantity_per_order)
        setStockLocation(product.stock_location)
        setPurchaseLocation(product.purchase_location)
        setOutOfStock(Boolean(product.out_of_stock))
        setIsActive(Boolean(product.is_active))
        setIsHighlighted(Boolean(product.is_highlighted))
        setContinueSellingAfterOutOfStock(Boolean(product.continues_selling_after_out_of_stock))
        setProducts(product.child_products)
        setVariants(product.variants)
        setPhotos(
          product.files.map(
            (file) => ({
              id: file.id,
              url: `${process.env.REACT_APP_IMAGE_API_URL}${file.url}_100.jpg`,
              status: 'success'
            })
          ))
        setCategories(
          product.categories.map(
            (category) => ({ label: category.name, value: category.id })
          )
        )
        setBadges(
          product.badges.map(
            (badge) => ({ label: badge.name, value: badge.id })
          )
        )
        setSuppliers(
          product.suppliers.map(
            (supplier) => ({ label: supplier.name, value: supplier.id })
          )
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const productSchema = Yup.object({
    name: Yup.string()
      .required('Name Required'),
    mmName: Yup.string()
      .required('Myanmar Name Required'),
    type: Yup.string()
      .required('Type Required'),
    sku: Yup.string()
      .required('SKU required'),
    slug: Yup.string()
      .required('Slug Required'),
    categories: Yup.string()
      .required('Categories Required'),
    price: Yup.number()
      .required('Price Required'),
    weight: Yup.number()
      .required('Weight Required'),
  })

  const onSubmit = async ({
    name,
    mmName,
    description,
    mmDescription,
    type,
    categories,
    badges,
    suppliers,
    brandId,
    price,
    slug,
    sku,
    weight,
    unit,
    maxQuantityPerOrder,
    stockLocation,
    purchaseLocation,
    outOfStock,
    isActive,
    isHighlighted,
    continueSellingAfterOutOfStock,
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `products/${id}` : `products`
      const body = {
        name,
        mm_name: mmName,
        description,
        mm_description: mmDescription,
        type,
        categories: categories.map(category => category.value),
        badges: badges.map(badge => badge.value),
        suppliers: suppliers.map(supplier => supplier.value),
        brand_id: brandId,
        price,
        slug,
        sku,
        weight,
        unit,
        max_quantity_per_order: maxQuantityPerOrder,
        stock_location: stockLocation,
        purchase_location: purchaseLocation,
        files: photosRef.current.map((photo) => (photo.id)),
        out_of_stock: outOfStock,
        is_active: isActive,
        is_highlighted: isHighlighted,
        continues_selling_after_out_of_stock: continueSellingAfterOutOfStock,
        child_products: products,
        variants: variants.map(
          (variant) => ({
            product_id: variant.product.id,
            name: variant.name
          })
        ),
      }
      const { data } = await axios({
        method,
        url: endpoint,
        data: body,
      })
      if (data.success) {
        toast.success('Success')
        navigate('/products')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteProduct = (id) => {
    const remainingProducts = products.filter((product) => (product.id !== id))
    setProducts(remainingProducts)
  }

  const deleteVariantProduct = (id) => {
    const remainingVariants = variants.filter((variant) => (variant.id !== id))
    setVariants(remainingVariants)
  }

  const submitVariantName = async (id, value) => {
    try {
      const { data } = await axios.post(``)
      if (data.success) {

      }
    } catch (err) {
      const error = getErrorMessage(err)
      toast.error(error)
    }
  }

  const onSortEndProducts = ({ oldIndex, newIndex }) => {
    setProducts(arrayMove(products, oldIndex, newIndex))
  };

  const isChecked = (hitId) => {
    return products.find((product) => product.id === hitId)
  }

  const handleProductsChange = (hit) => {
    const isProductExisted = products.find((product) => product.id === hit.id)
    if (isProductExisted) {
      setProducts(
        products.filter(
          (product) => product.id !== hit.id)
      )
    } else {
      setProducts((prev) => [...prev, hit])
    }
  }

  const isVariantsChecked = (hitId) => {
    return variants.find((variant) => variant.product?.id === hitId)
  }

  const handleVariantsChange = (hit) => {
    const isVariantsExisted = variants.find(
      (variant) => variant.product.id === hit.id
    )
    if (isVariantsExisted) {
      setVariants(
        variants.filter(
          (variant) => variant.product.id !== hit.id)
      )
    } else {
      setVariants(
        (prev) => [
          ...prev,
          {
            name: '',
            product: hit,
          }
        ])
    }
  }

  const handleVariantNameChange = async (index, value) => {
    const temp = [...variants]
    temp[index].name = value
    setVariants(temp)
  }

  React.useEffect(() => {
    editForm && fetchProduct()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Container>
      <Formik
        initialValues={{
          name,
          mmName,
          description,
          mmDescription,
          type,
          products,
          categories,
          badges,
          suppliers,
          townships,
          brandId,
          price,
          slug,
          sku,
          weight,
          unit,
          maxQuantityPerOrder,
          stockLocation,
          purchaseLocation,
          outOfStock,
          isActive,
          isHighlighted,
          continueSellingAfterOutOfStock,
        }}
        validationSchema={productSchema}
        onSubmit={(values) => {
          onSubmit(values)
        }}
      >
        {({ values, setFieldValue, handleChange, handleBlur, isSubmitting }) => (
          <div className='pos-relative' >
            <div className='section-nav d-none d-sm-none d-md-none d-lg-block pos-fixed t-100 r-40'>
              <nav id='navSection' className='nav flex-column'>
                <a href='#essentials' className='nav-link'>Essentials</a>
                <a href='#optionals' className='nav-link'>Product Details</a>
                {values.type === 'bundle' && <a href='#bundle' className='nav-link'>Bundle</a>}
                <a href='#on-off' className='nav-link'>On/Off</a>
              </nav>
            </div>
            <Form encType='multipart/form-data'>
              <div className='form-group row' id='essentials'>
                <label className='col-md-3 text-md-right col-form-label'>
                  <span className='tx-danger'>* </span>  Type
                </label>
                <div className='col-md-9'>
                  <SingleSelect
                    name='type'
                    label='Types'
                    value={values.type}
                    data={types}
                    onChange={type => setFieldValue('type', type)}
                    onBlur={handleBlur}
                  />
                  <ErrorComponent>
                    <ErrorMessage name='type' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Name{' '}
                </label>
                <div className='col-md-9'>
                  <Field
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='Eggs'
                    onChange={(e) => {
                      setFieldValue('name', e.target.value)
                      setFieldValue('slug', slugify(e.target.value).toLowerCase())
                    }}
                    onBlur={handleBlur}
                    autoFocus
                  />
                  <ErrorComponent>
                    <ErrorMessage name='name' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  <span className='tx-danger'>* </span> Slug
                </label>
                <div className='col-md-9'>
                  <Field
                    name='slug'
                    type='text'
                    className='form-control'
                    placeholder='grocery-and-dry-goods'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='slug' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  <span className='tx-danger'>* </span> Myanmar Name
              </label>
                <div className='col-md-9'>
                  <Field
                    name='mmName'
                    type='text'
                    className='form-control'
                    placeholder='ကြက်ဥများ'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='mmName' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  <span className='tx-danger'>* </span> Price
                </label>
                <div className='col-md-9'>
                  <Field
                    name='price'
                    type='number'
                    className='form-control'
                    placeholder='0'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='price' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  <span className='tx-danger'>* </span>SKU
                </label>
                <div className='col-md-9'>
                  <Field
                    name='sku'
                    type='text'
                    className='form-control'
                    placeholder='B-200-11'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='sku' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  <span className='tx-danger'>* </span> Weight
                </label>
                <div className='col-md-9'>
                  <Field
                    name='weight'
                    type='number'
                    className='form-control'
                    placeholder='50'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='weight' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  Unit
              </label>
                <div className='col-md-9'>
                  <SingleSelect
                    name='unit'
                    label='Unit'
                    value={values.unit}
                    data={units}
                    onChange={(unit) => setFieldValue('unit', unit)}
                    className='mg-0'
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Categories
                </label>
                <div className='col-md-9'>
                  <MultiSelect
                    name='categories'
                    endpoint='childCategories'
                    onChange={(categories) => setFieldValue('categories', categories)}
                    defaultValue={values.categories}
                    className='pd-0'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='categoryId' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row' id='optionals'>
                <label className='col-md-3 text-md-right col-form-label'>
                  Brand
                </label>
                <div className='col-md-9'>
                  <SingleSelect
                    name='brandId'
                    label='Brands'
                    value={values.brandId}
                    endpoint='brands'
                    onChange={(brandId) => setFieldValue('brandId', brandId)}
                    onBlur={handleBlur}
                    className='mg-0'
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  Badges
                </label>
                <div className='col-md-9'>
                  <MultiSelect
                    name='badges'
                    endpoint='badges'
                    onChange={(badges) => setFieldValue('badges', badges)}
                    defaultValue={values.badges}
                    className='pd-0'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='badges' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  Suppliers
                </label>
                <div className='col-md-9'>
                  <MultiSelect
                    name='suppliers'
                    endpoint='suppliers'
                    onChange={(suppliers) => setFieldValue('suppliers', suppliers)}
                    defaultValue={values.suppliers}
                    className='pd-0'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='suppliers' />
                  </ErrorComponent>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  Description
                </label>
                <div className='col-md-9'>
                  <Field
                    name='description'
                    as='textarea'
                    className='form-control'
                    placeholder='Editor Choice'
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  Myanmar Description
                </label>
                <div className='col-md-9'>
                  <Field
                    name='mmDescription'
                    as='textarea'
                    className='form-control'
                    placeholder='အယ်ဒီတာ အကြိုက်'
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right '>
                  Max Quantity Per Order
                    </label>
                <div className='col-md-9'>
                  <Field
                    name='maxQuantityPerOrder'
                    type='number'
                    className='form-control'
                    placeholder='0'
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  Purchase Location
                    </label>
                <div className='col-md-9'>
                  <Field
                    name='purchaseLocation'
                    type='text'
                    className='form-control'
                    placeholder='pazuntaung market'
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-md-3 text-md-right col-form-label'>
                  Stock Location
                    </label>
                <div className='col-md-9'>
                  <Field
                    name='stockLocation'
                    type='text'
                    className='form-control'
                    placeholder='B-22'
                  />
                </div>
              </div>

              <div>
                <div className='form-group row'>
                  <label className='col-md-3 tx-md-right col-form-label'>
                    Product Image
                  </label>
                  <div className='col-md-9'>
                    <FileUpload
                      id={id}
                      relatedType='products'
                      multiple={true}
                      photos={photos}
                      setPhotos={setPhotos}
                    />
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Variants
                </label>
                <div className='col-md-9'>
                  <button
                    type='button'
                    className='btn btn-light'
                    onClick={() => setShowVariantPicker(true)}
                  >Pick Variants
                  </button>
                  {
                    showVariantPicker && (
                      <AlgoliaModal
                        id='variantModal'
                        searchClient={searchClient}
                        indexName='products_index'
                        onChange={handleVariantsChange}
                        onCheck={isVariantsChecked}
                        onDismiss={() => setShowVariantPicker(false)}
                      />
                    )
                  }
                  <VariantsList
                    variants={variants}
                    setVariants={setVariants}
                    onDelete={deleteVariantProduct}
                    onChange={handleVariantNameChange}
                  />
                </div>
              </div>

              {
                values.type === 'bundle' &&
                <div className='form-group row' id='bundle'>
                  <label className='col-md-3 tx-md-right col-form-label'>
                    <span className='tx-danger'>* </span>Related Products
                  </label>
                  <div className='col-md-9'>
                    <button
                      type='button'
                      className='btn btn-light'
                      onClick={() => setShowProductPicker(true)}
                    >Pick Products
                    </button>
                    {
                      showProductPicker && (
                        <AlgoliaModal
                          id='bundleModal'
                          searchClient={searchClient}
                          indexName='products_index'
                          onChange={handleProductsChange}
                          onCheck={isChecked}
                          onDismiss={() => setShowProductPicker(false)}
                        />
                      )
                    }
                    <SortableList
                      items={products}
                      axis='y'
                      onDelete={deleteProduct}
                      onSortEnd={onSortEndProducts}
                      renderItemComponent={SortableProduct}
                      className='pd-0 mg-t-10'
                    />
                  </div>
                </div>
              }

              <div className='row form-group'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-switch custom-control'>
                    <input
                      name='outOfStock'
                      type='checkbox'
                      id='outOfStock'
                      className='custom-control-input'
                      value={values.outOfStock}
                      checked={values.outOfStock}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor='outOfStock' className='custom-control-label'>
                      Out Of Stock
                </label>
                  </div>
                </div>
              </div>

              <div className='row form-group'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-switch custom-control'>
                    <input
                      name='isHighlighted'
                      type='checkbox'
                      id='isHighlighted'
                      className='custom-control-input'
                      value={values.isHighlighted}
                      checked={values.isHighlighted}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor='isHighlighted' className='custom-control-label'>
                      Highlight
                    </label>
                  </div>
                </div>
              </div>

              <div className='form-group row' id='on-off'>
                <div className='offset-md-3 col-md-9'>
                  <div className='custom-switch custom-control'>
                    <input
                      name='isActive'
                      type='checkbox'
                      id='isActive'
                      className='custom-control-input'
                      value={values.isActive}
                      checked={values.isActive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor='isActive' className='custom-control-label'>
                      Active
                    </label>
                  </div>
                </div>
              </div>

              <div className='row form-group'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-switch custom-control'>
                    <input
                      name='continueSellingAfterOutOfStock'
                      type='checkbox'
                      id='continueSellingAfterOutOfStock'
                      className='custom-control-input'
                      value={values.continueSellingAfterOutOfStock}
                      checked={values.continueSellingAfterOutOfStock}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label
                      htmlFor='continueSellingAfterOutOfStock'
                      className='custom-control-label'
                    >
                      Continue Selling After Out-Of-Stock
                </label>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <button
                    type='submit'
                    className='btn btn-primary btn-block'
                  >
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </Container>
  )
}
