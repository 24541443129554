import React from 'react'
import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  DollarSign as DollarIcon,
  Maximize2 as MaximizeIcon,
} from 'react-feather'

export default function TodayWidget() {
  return (
    <div className='card'>
      <div className='card-header pd-y-20 d-md-flex align-items-center justify-content-between'>
        <h6 className='mg-b-0'>
          Today <span className='tx-color-03'>in a nutshell</span>
        </h6>
        <div>
          <a href='' className='tx-13 link-03'>
            <MaximizeIcon className='wd-15 ht-15' />
          </a>
        </div>
      </div>
      <div className='card-body pos-relative'>
        <div className='d-sm-flex mg-b-10'>
          <div className='media'>
            <div className='wd-40 wd-md-50 ht-40 ht-md-50 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6'>
              <BarChartIcon />
            </div>
            <div className='media-body'>
              <h6 className='tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8'>
                Total Order
              </h6>
              <h4 className='tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0'>
                210
              </h4>
            </div>
          </div>
          <div className='media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40'>
            <div className='wd-40 wd-md-50 ht-40 ht-md-50 bg-pink tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-5'>
              <DollarIcon />
            </div>
            <div className='media-body'>
              <h6 className='tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8'>
                Total Earning
              </h6>
              <h4 className='tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0'>
                234,769 <small className='tx-color-03 tx-10'>MMK</small>
              </h4>
            </div>
          </div>
          <div className='media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40'>
            <div className='wd-40 wd-md-50 ht-40 ht-md-50 bg-primary tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4'>
              <UsersIcon />
            </div>
            <div className='media-body'>
              <h6 className='tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8'>
                New Users
              </h6>
              <h4 className='tx-20 tx-sm-18 tx-md-20 tx-normal tx-rubik mg-b-0'>
                24
              </h4>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <ul className='list-group list-group-flush mg-b-0 tx-13'>
              <li className='list-group-item pd-x-0 d-flex justify-content-between'>
                <span className='tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0'>
                  High Performing Products
                </span>
                <span className='tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0'>
                  Quantity
                </span>
              </li>
              <li className='list-group-item pd-x-0 d-flex justify-content-between'>
                <span className='tx-medium'>Chicken Bone 0.5viss</span>
                <span className='tx-rubik'>48</span>
              </li>
              <li className='list-group-item pd-x-0 d-flex justify-content-between'>
                <span className='tx-medium'>Coca-Cola</span>
                <span className='tx-rubik'>40</span>
              </li>
              <li className='list-group-item pd-x-0 d-flex justify-content-between'>
                <span className='tx-medium'>Fresh Egg</span>
                <span className='tx-rubik'>32</span>
              </li>
              <li className='list-group-item pd-x-0 d-flex justify-content-between'>
                <span className='tx-medium'>Knorr 60g</span>
                <span className='tx-rubik'>29</span>
              </li>
              <li className='list-group-item pd-x-0 d-flex justify-content-between'>
                <span className='tx-medium'>Pork Loin 0.5viss</span>
                <span className='tx-rubik'>20</span>
              </li>
            </ul>
          </div>
          <div className='col-md-6'>
            <ul className='list-group list-group-flush mg-b-0 tx-13'>
              <li className='list-group-item pd-x-0 d-flex justify-content-between'>
                <span className='tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0'>
                  Low on stock
                </span>
                <span className='tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0'>
                  Quantity
                </span>
              </li>
              <li className='list-group-item pd-x-0 d-flex justify-content-between'>
                <span className='tx-medium'>Chicken Bone 0.5viss</span>
                <span className='tx-rubik tx-danger'>-10</span>
              </li>
              <li className='list-group-item pd-x-0 d-flex justify-content-between'>
                <span className='tx-medium'>Fresh Egg</span>
                <span className='tx-rubik tx-danger'>-8</span>
              </li>
              <li className='list-group-item pd-x-0 d-flex justify-content-between'>
                <span className='tx-medium'>Knorr 60g</span>
                <span className='tx-rubik tx-warning'>10</span>
              </li>
              <li className='list-group-item pd-x-0 d-flex justify-content-between'>
                <span className='tx-medium'>Myanmar Honey</span>
                <span className='tx-rubik tx-warning'>29</span>
              </li>
              <li className='list-group-item pd-x-0 d-flex justify-content-between'>
                <span className='tx-medium'>Pork Loin</span>
                <span className='tx-rubik tx-warning'>30</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
