import React from 'react'
import { navigate, useLocation } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { parse } from 'query-string'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import MultiSelect from '../../common/MultiSelect'
import SingleSelect from '../../common/SingleSelect'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'
import isMmPhoneNumber from '../../utils/isMmPhoneNumber'

export default function UserForm({ editForm, id }) {
  const location = useLocation()
  const cartId = parse(location.search, {
    parseNumbers: true,
  }).cartId
  console.log({ cartId })
  const [name, setName] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [status, setStatus] = React.useState('approved')
  const [roles, setRoles] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const statuses = [
    {
      id: 'unverified',
      name: 'Unverified'
    },
    {
      id: 'approved',
      name: 'Approved'
    },
    {
      id: 'banned',
      name: 'Banned'
    }
  ]

  const userSchema = Yup.object({
    name: Yup.string()
      .required('Name Required'),
    phone: Yup.string()
      .required('Phone Required'),
    roles: Yup.array()
      .required('Roles Required')
  })

  const fetchUser = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`users/${id}`)
      if (data.success) {
        const user = data.data
        setName(user.name)
        setStatus(user.status)
        setPhone(user.phone)
        setRoles(user.roles.map(role => ({ label: role.name, value: role.id })))
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async ({
    phone,
    roles,
    name,
    status
  }) => {
    console.log('is myanmarphone', isMmPhoneNumber(phone))
    try {
      if (isMmPhoneNumber(phone)) {
        const method = editForm ? 'PUT' : 'POST'
        const endpoint = editForm ? `users/${id}` : `users`
        const body = {
          phone,
          status,
          name,
          roles: roles.map((role) => role.value)
        }
        const { data } = await axios({
          method,
          url: endpoint,
          data: body,
        })
        console.log('user create', data)
        if (data.success) {
          toast.success('Success')
          if (cartId) {
            navigate(`/orders/add?userId=${data.data.id}&cartId=${cartId}`)
          } else {
            navigate('/users')
          }
        }
      } else {
        toast.error('Invalid Phone')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    editForm && fetchUser()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={{
        phone,
        name,
        status,
        roles
      }}
      validationSchema={userSchema}
      onSubmit={(values) => {
        console.log('formik values', values)
        onSubmit(values)
      }}
    >
      {
        ({ values, isSubmitting, setFieldValue, handleBlur }) => (
          <Container>
            <Form>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Name
                </label>
                <div className='col-md-9'>
                  <Field
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='Hsu'
                    autoFocus
                  />
                  <ErrorComponent>
                    <ErrorMessage name='name' />
                  </ErrorComponent>
                </div>
              </div>


              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Phone
                </label>
                <div className='col-md-9'>
                  <Field
                    name='phone'
                    type='tel'
                    className='form-control'
                    placeholder='09'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='phone' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Roles
                  </label>
                <div className='col-md-9'>
                  <MultiSelect
                    className='pd-0'
                    name='roles'
                    onChange={(roles) => setFieldValue('roles', roles)}
                    endpoint='roles'
                    defaultValue={roles}
                    onBlur={handleBlur}
                  />
                  <ErrorComponent>
                    <ErrorMessage name='roles' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>Status </label>
                <div className='col-md-9'>
                  <SingleSelect
                    name='status'
                    label='Status'
                    data={statuses}
                    value={values.status}
                    onChange={status => setFieldValue('status', status)}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <button
                    className='btn btn-primary btn-uppercase btn-block'
                    type='submit'>
                    {isSubmitting ? 'Submitting' : 'submit'}
                  </button>
                </div>
              </div>
            </Form>
          </Container>
        )
      }
    </Formik>
  )
}