import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import slugify from 'slugify'
import * as Yup from 'yup'
import SingleSelect from '../../common/SingleSelect'
import Container from '../../components/Container'
import ErrorComponent from '../../components/ErrorComponent'
import FileUpload from '../../components/FileUpload'
import LoadingSpinner from '../../components/LoadingSpinner'
import getErrorMessage from '../../utils/getErrorMessage'

export default function CategoryForm({ id, editForm }) {
  const [name, setName] = React.useState('')
  const [mmName, setMmName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [mmDescription, setMmDescription] = React.useState('')
  const [slug, setSlug] = React.useState('')
  const [parentId, setParentId] = React.useState(0)
  const [thumbnail, setThumbnail] = React.useState([])
  const [cover, setCover] = React.useState([])
  const [order, setOrder] = React.useState('')
  const [isHighlighted, setIsHighlighted] = React.useState(false)
  const [isActive, setIsActive] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchCategory = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`categories/${id}`)
      console.log('category detail', data)
      if (data.success) {
        const category = data.data
        setName(category.name)
        setMmName(category.mm_name)
        setDescription(category.description)
        setMmDescription(category.mm_description)
        setSlug(category.slug)
        setParentId(category.parent_id)
        setThumbnail(category.thumb ? (
          [{
            id: category.thumb.id,
            url: `${process.env.REACT_APP_IMAGE_API_URL}${category.thumb.url}_100.jpg`,
            status: 'success',
          }]
        ) : ([])
        )
        setCover(category.cover ? (
          [{
            id: category.cover.id,
            url: `${process.env.REACT_APP_IMAGE_API_URL}${category.cover.url}_100.jpg`,
            status: 'success',
          }]
        ) : ([])
        )
        setOrder(category.order)
        setIsHighlighted(Boolean(category.is_highlighted))
        setIsActive(Boolean(category.is_active))
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const categorySchema = Yup.object().shape({
    name: Yup.string()
      .required('Name Required'),
    mmName: Yup.string()
      .required('Myanmar Name Required'),
    slug: Yup.string()
      .required('Slug Required'),
  })

  const onSubmit = async ({
    name,
    mmName,
    description,
    mmDescription,
    slug,
    parentId,
    isActive,
    order,
    isHighlighted,
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `categories/${id}` : `categories`
      const body = {
        name,
        mm_name: mmName,
        description,
        mm_description: mmDescription,
        slug,
        parent_id: parentId ? parseInt(parentId) : 0,
        thumb_id: thumbnail[0]?.id,
        cover_id: cover[0]?.id,
        is_active: isActive,
        is_highlighted: isHighlighted,
      }
      console.log('api body:', body)
      const { data } = await axios({
        method,
        url: endpoint,
        data: editForm ? { ...body, order } : body,
      })
      if (data.success) {
        toast.success('Success')
        navigate('/categories')
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    editForm && fetchCategory()
  }, [])

  if (editForm && isLoading) {
    return <LoadingSpinner />
  }


  return (
    <Formik
      initialValues={{
        name,
        mmName,
        description,
        mmDescription,
        slug,
        parentId,
        order,
        isHighlighted,
        isActive
      }}
      validationSchema={categorySchema}
      onSubmit={(values) => { onSubmit(values) }}

    >
      {
        ({ values, isSubmitting, setValues, setFieldValue, handleChange, handleBlur }) => (
          <Container>
            <Form>
              <div className='form-group row' >
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Name
                  </label>
                <div className='col-md-9'>
                  <Field
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='Grocery And Dry Goods'
                    onChange={(e) => {
                      setFieldValue('name', e.target.value)
                      setFieldValue('slug', slugify(e.target.value).toLowerCase())
                    }}
                    onBlur={handleBlur}
                    autoFocus
                  />
                  <ErrorComponent>
                    <ErrorMessage name='name' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row '>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Slug
                </label>
                <div className='col-md-9'>
                  <Field
                    name='slug'
                    type='text'
                    className='form-control'
                    placeholder='grocery-and-dry-goods'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorComponent>
                    <ErrorMessage name='slug' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row '>
                <label className='col-md-3 tx-md-right col-form-label'>
                  <span className='tx-danger'>* </span>Myanmar Name
                </label>
                <div className='col-md-9'>
                  <Field
                    name='mmName'
                    type='text'
                    className='form-control'
                    placeholder='ကုန်စုံနှင့်ကုန်ခြောက်များ'
                  />
                  <ErrorComponent>
                    <ErrorMessage name='mmName' />
                  </ErrorComponent>
                </div>
              </div>

              <div className='form-group row'>
                <label className='col-md-3 tx-md-right col-form-label'>
                  Description
                </label>
                <div className='col-md-9'>
                  <Field
                    name='description'
                    as='textarea'
                    className='form-control'
                    placeholder='Editor Choice'
                  />
                </div>
              </div>

              <div className='form-group row '>
                <label className='col-md-3 tx-md-right col-form-label'>
                  Myanmar Description
                </label>
                <div className='col-md-9'>
                  <Field
                    name='mmDescription'
                    as='textarea'
                    className='form-control'
                    placeholder='အယ်ဒီတာ အကြိုက်'
                  />
                </div>
              </div>

              <div className='form-group row '>
                <label className='col-md-3 tx-md-right col-form-label'>
                  Parent Category
                </label>
                <div className='col-md-9'>
                  <SingleSelect
                    name='parentId'
                    label='Categories'
                    endpoint='categories'
                    value={values.parentId}
                    onChange={parentId => setFieldValue('parentId', parentId)}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className='form-group row '>
                <label className='col-md-3 tx-md-right col-form-label'>
                  Thumbnail
                </label>
                <div className='col-md-9'>
                  <FileUpload
                    id={id}
                    name='thumbnail'
                    relatedType='categories'
                    multiple={false}
                    photos={thumbnail}
                    setPhotos={setThumbnail}
                  />
                </div>
              </div>

              <div className='form-group row '>
                <label className='col-md-3 tx-md-right col-form-label'>
                  Cover
                </label>
                <div className='col-md-9'>
                  <FileUpload
                    id={id}
                    name='cover'
                    relatedType='categories'
                    multiple={false}
                    photos={cover}
                    setPhotos={setCover}
                  />
                </div>
              </div>

              {editForm && (
                <div className='form-group row'>
                  <label className='col-md-3 tx-md-right'>Order</label>
                  <div className='col-md-9'>
                    <Field
                      name='order'
                      type='number'
                      className='form-control'
                    />
                  </div>
                </div>
              )}

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-switch custom-control'>
                    <Field
                      name='isActive'
                      type='checkbox'
                      id='isActive'
                      className='custom-control-input'
                      value={values.isActive}
                      checked={values.isActive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor='isActive' className='custom-control-label'>Active </label>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <div className='custom-switch custom-control'>
                    <input
                      name='isHighlighted'
                      type='checkbox'
                      id='isHighlighted'
                      className='custom-control-input'
                      value={values.isHighlighted}
                      checked={values.isHighlighted}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor='isHighlighted' className='custom-control-label'> Highlight</label>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-md-9 offset-md-3'>
                  <button type='submit' className='btn btn-primary btn-block'>
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </button>
                </div>
              </div>
            </Form>
          </Container>
        )
      }
    </Formik>
  )
}