import React from 'react'
import Card from '../../components/Card'
import RoleForm from './RoleForm'

export default function AddRolePage() {
  return (
    <Card>
      <Card.Header
        title='Add New Role'
        description='roles'
        href='/roles'
      />
      <RoleForm />
    </Card>
  )
}