import { useParams } from '@reach/router'
import React from 'react'
import Card from '../../components/Card'
import WarehouseForm from './WarehouseForm'

export default function EditWarehousePage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Warehouse'
        description='warehouses'
        href='/warehouses'
      />
      <WarehouseForm editForm id={id} />
    </Card>
  )
}