import { useParams } from '@reach/router'
import React from 'react'
import Card from '../../components/Card'
import TownshipForm from './TownshipForm'

export default function EditTownshipPage(props) {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Township'
        description='townships'
        href='/townships'
      />
      <TownshipForm editForm id={id} />
    </Card>
  )
}