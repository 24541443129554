import React from 'react'
import Card from './Card'
export default function Container({ children, className }) {
  return (
    <div
      className={`wd-70p m-auto pd-0 ${className}`}
      style={{ maxWidth: 800 }}
    >
      {children}
    </div>
  )
}
