import React from 'react'
import { useParams } from '@reach/router'
import Card from '../../components/Card'
import PromotionForm from './PromotionForm'

export default function EditPromotionPage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Promotion'
        description='promotions'
        href='/promotions'
      />
      <PromotionForm editForm id={id} />
    </Card>
  )
}
