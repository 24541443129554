import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

const offCanvasRoot = document.getElementById('off-canvas')
const el = document.createElement('div')

export default function OffCanvas(props) {
  useEffect(() => {
    offCanvasRoot.appendChild(el)
    offCanvasRoot.classList.add('show')

    return () => {
      offCanvasRoot.classList.remove('show')
      offCanvasRoot.removeChild(el)
    }
  }, [])

  return ReactDOM.createPortal(
    <div className={props.className}>{props.children}</div>,
    el
  )
}
