import React from 'react'
import { Settings as SettingsIcon, Trash as TrashIcon } from 'react-feather'
import Modal from '../../common/Modal'
import Select from '../../common/Select'
import _ from 'underscore'

function ProductCollectionComponentItem({ item, index, onDelete, onUpdate }) {
  const [displaySetting, setDisplaySetting] = React.useState(false)
  const [title, setTitle] = React.useState('')
  const [categoryId, setCategoryId] = React.useState(null)
  const [type, setType] = React.useState('collection_products')

  // function updatePreview() {
  //   onUpdate(
  //     {
  //       title,
  //       type,
  //       categoryId,
  //       isShowPreview: true,
  //       isPreviewLoading: true,
  //     },
  //     index
  //   )
  //   console.log(index)
  // }

  console.log(item)

  return (
    <div className='component-item card mb-3'>
      <div className='card-header'>
        <div className='d-flex justify-content-between align-items-center'>
          {item.title ? (
            item.title
          ) : (
            <a onClick={() => setDisplaySetting(true)}>{'Add title'}</a>
          )}
          <div className='d-flex'>
            <button
              className='btn btn-light btn-xs mg-r-5'
              onClick={() => setDisplaySetting(true)}
            >
              <SettingsIcon />
            </button>
            <button
              className='btn btn-danger btn-xs'
              onClick={() => onDelete(index)}
            >
              <TrashIcon />
            </button>
          </div>
        </div>
      </div>
      <div className='card-body'>
        {item.isShowPreview && (
          <div>
            {item.isPreviewLoading ? (
              <div className='spinner-border text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              <div>
                {item.type === 'category_products' && (
                  <div className='d-flex overflow-hidden'>
                    {_.map(item.previewData.data.products, (product) => (
                      <div className='wd-150 mg-r-10'>
                        <div className='wd-100 ht-100 bg-gray-400 mg-b-10'></div>
                        <h6 className='mg-b-0'>{product.name}</h6>
                        <span className='tx-primary'>{product.price} ks</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {displaySetting && (
        <Modal onDismiss={() => setDisplaySetting(false)}>
          <Modal.Header>Configure Component</Modal.Header>
          <Modal.Body style={{ minHeight: 400 }}>
            <label htmlFor='title'>Title</label>
            <input
              type='text'
              name='title'
              className='form-control'
              placeholder='Title...'
              autoFocus
              onChange={(e) => setTitle(e.target.value)}
            />
            <div className='mg-y-10'>
              <label htmlFor='title'>Type</label>
              <div className='d-flex'>
                <div className='custom-control custom-radio mg-r-10'>
                  <input
                    type='radio'
                    id='collection_products'
                    name='type'
                    className='custom-control-input'
                    defaultChecked={type === 'collection_products'}
                    onChange={(e) => setType('collection_products')}
                  />
                  <label
                    className='custom-control-label'
                    for='collection_products'
                  >
                    Product Collection
                  </label>
                </div>
                <div className='custom-control custom-radio mg-r-10'>
                  <input
                    type='radio'
                    id='category_products'
                    name='type'
                    className='custom-control-input'
                    defaultChecked={type === 'category_products'}
                    onChange={(e) => setType('category_products')}
                  />
                  <label
                    className='custom-control-label'
                    for='category_products'
                  >
                    Category
                  </label>
                </div>
                <div className='custom-control custom-radio'>
                  <input
                    type='radio'
                    id='category_collection'
                    name='type'
                    className='custom-control-input'
                    defaultChecked={type === 'category_collection'}
                    onChange={(e) => setType('category_collection')}
                  />
                  <label
                    className='custom-control-label'
                    for='category_collections'
                  >
                    Category Collection
                  </label>
                </div>
              </div>
            </div>
            <div className='mg-y-10'>
              {type === 'collection_products' && (
                <Select
                  endpoint={'collections'}
                  searchable
                  onChange={console.log}
                />
              )}
              {type === 'category_products' && (
                <Select
                  endpoint={'categories'}
                  searchable
                  onChange={(value) => setCategoryId(value.value)}
                />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className='btn btn-primary'
              onClick={() => {
                setDisplaySetting(false)
                // updatePreview()
                onUpdate(
                  {
                    title,
                    type,
                    categoryId,
                    isShowPreview: true,
                    isPreviewLoading: true,
                  },
                  index
                )
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default ProductCollectionComponentItem
