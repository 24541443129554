import React from 'react'
import Card from '../../components/Card'
import SupplierForm from './SupplierForm'

export default function AddSupplierPage(){
  return (
    <Card>
      <Card.Header
        title='Add New Supplier'
        description='suppliers'
        href='/suppliers'
      />
      <SupplierForm />
    </Card>
  )
}