import { useParams } from '@reach/router'
import React from 'react'
import Card from '../../components/Card'
import BadgeForm from './BadgeForm'

export default function EditUserPage() {
  const { id } = useParams()

  return (
    <Card>
      <Card.Header
        title='Edit Badge'
        description='badges'
        href='/badges'
      />
      <BadgeForm editForm id={id} />
    </Card>

  )
}