import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import {
  Edit2 as EditIcon, Plus as PlusIcon,
  Trash2 as DeleteIcon
} from 'react-feather'
import toast from 'react-hot-toast'
import Card from '../../components/Card'
import LoadingSpinner from '../../components/LoadingSpinner'
import OffCanvas from '../../components/OffCanvas'
import Table from '../../components/Table'
import UndoAction from '../../components/UndoAction'
import filterActive from '../../utils/filterActive'
import getErrorMessage from '../../utils/getErrorMessage'
import initOffCanvas from '../../dashforge/dashforge.offcanvas'

export default function RegionPage() {
  const [displayOffCanvas, setDisplayOffCanvas] = React.useState(false)
  const [cities, setCities] = React.useState([])
  const [activeCities, setActiveCities] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const toggleOffCanvas = React.useCallback(() => {
    console.log({ displayOffCanvas });
    setDisplayOffCanvas((displayOffCanvas) => !displayOffCanvas)
  }, [displayOffCanvas])

  const fetchCities = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get('cities')
      if (data.success) {
        setCities(data.data)
        setActiveCities(filterActive(data.data))
        setRows(
          data.data.map((data) => ({
            id: data.id,
            name: data.name,
            totalTownships: data.total_townships,
          }))
        )
        setIsLoading(false)
      }
    } catch (error) {
      const err = error.response
        ? error.response.data.message
        : error.toString()
      err && toast.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreCity = async (id, index) => {
    try {
      const { data } = await axios.post(`cities/${id}/restore`)
      if (data.success) {
        const temp = [...cities]
        temp[index] = data.data
        setCities(temp)
        setRows(temp)
        toast.remove()
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  const deleteCity = async (id, index) => {
    try {
      const { data } = await axios.delete(`cities/${id}`)
      if (data.success) {
        const temp = [...cities]
        temp.splice(index, 1)
        setCities(temp)
        setRows(temp)
        toast.success(
          <UndoAction
            onClick={() => restoreCity(id, index)}
            name={cities[index].name}
          />
        )
      }
    } catch (error) {
      const err = getErrorMessage(error)
      err && toast.error(err)
    }
  }

  React.useEffect(() => {
    fetchCities()
  }, [])

  React.useEffect(() => {
    if (!isLoading) {
      initOffCanvas()
    }
  }, [displayOffCanvas])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card>
      <Card.Header
        title='Cities'
        description='Cities'
        tableStats={[
          { label: 'total cities', value: cities.length },
          { label: 'active cities', value: activeCities.length },
        ]}
        btns={[
          {
            title: 'Add new city',
            icon: PlusIcon,
            href: 'cities/add',
            variant: 'primary',
          },
        ]}
      />
      <Table
        rows={rows}
        cols={[
          {
            title: 'ID',
            key: 'id',
            width: '50px',
          },
          {
            title: 'Name',
            key: 'name',
          },
          {
            title: 'Total Townships',
            key: 'totalTownships',
          },
          {
            title: 'Actions',
            width: '100px',
            render: {
              type: 'actions',
              btns: [
                {
                  icon: PlusIcon,
                  variant: 'btn-primary',
                  onClick: (id) => navigate(`/townships/add?cityId=${id}`),
                },
                {
                  icon: EditIcon,
                  variant: 'btn-info',
                  onClick: (id) => navigate(`/cities/${id}/edit`),
                },
                {
                  icon: DeleteIcon,
                  variant: 'btn-danger',
                  onClick: deleteCity,
                }
              ]
            },
          },
        ]}
      />
      {displayOffCanvas && (
        <OffCanvas className='pd-25'>
          <ul className='sidebar-nav mg-b-25'>
            <li className='nav-label mg-b-15'>Townships</li>
            <li className='nav-item show'>
              <a href='#'>Yangon</a>
            </li>
            <li className='nav-item show'>
              <a href='#'>Mandalay</a>
            </li>
          </ul>
        </OffCanvas>
      )}
    </Card>
  )
}
