import React from 'react'
import BankForm from './BankForm'
import Card from '../../components/Card'

export default function AddBankPage() {
  return (
    <Card>
      <Card.Header
        title='Add New Bank'
        description='Banks'
        href='/banks'
      />
      <BankForm />
    </Card>
  )
}