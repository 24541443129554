import React from 'react'
import { navigate } from '@reach/router'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import MultiSelect from '../../common/MultiSelect'
import SingleSelect from '../../common/SingleSelect'
import ErrorComponent from '../../components/ErrorComponent'
import Loading from '../../components/Loading'

export default function ConfigForm({ editForm, id }) {
  const [key, setKey] = React.useState()
  const [value, setValue] = React.useState()
  const [mmValue, setMmValue] = React.useState()
  const [type, setType] = React.useState()
  const [title, setTitle] = React.useState()
  const [description, setDescription] = React.useState()
  const [platform, setPlatform] = React.useState([])
  const [version, setVersion] = React.useState([])
  const [active, setActive] = React.useState(false)
  const [variables, setVariables] = React.useState(['deliver_date', 'app_version', 'delivery_day'])
  const [isLoading, setIsLoading] = React.useState(true)

  //config type
  const types = [
    {
      name: 'True/False',
      id: 'boolean'
    },
    {
      name: 'String',
      id: 'string'
    },
    {
      name: 'Number',
      id: 'number'
    }
  ]

  //valid platforms
  const platforms = [
    {
      label: 'IOS',
      value: 'ios'
    },
    {
      label: 'ANDROID',
      value: 'android'
    },
    {
      label: 'WEB',
      value: 'web',
    },
    {
      label: 'SYSTEM',
      value: 'system'
    }
  ]

  //valid versions
  const versions = [
    {
      label: 'V3.1.0',
      value: 'V3.1.0'
    },
    {
      label: 'V3.0.4',
      value: 'V3.0.4',
    },
    {
      label: 'V3.0.2',
      value: 'V3.0.2',
    },
    {
      label: 'V3.0',
      value: 'V3.0',
    },
    {
      label: 'V2.0.7',
      value: 'V2.0.7',
    },
    {
      label: 'V2.0.6',
      value: 'V2.0.6',
    },
    {
      label: 'V2.0.5',
      value: 'V2.0.5',
    },
    {
      label: 'V2.0',
      value: 'V2.0',
    },
  ]

  const configSchema = Yup.object({
    key: Yup.string()
      .required('Key Required'),
    value: Yup.string()
      .required('Value Required'),
    mmValue: Yup.string()
      .required('Myanmar Value Required'),
    type: Yup.string()
      .required('Config Type Required'),
    platform: Yup.array()
      .required('Platform Required'),
    version: Yup.array()
      .required('Version Required'),
  })

  const onSubmit = async ({
    key,
    value,
    mmValue,
    type,
    title,
    description,
    platform,
    version,
    active
  }) => {
    try {
      const method = editForm ? 'PUT' : 'POST'
      const endpoint = editForm ? `app-configs/${id}` : `app-configs`
      const body = {
        key,
        value,
        mmValue,
        type,
        title,
        description,
        platform,
        version
      }
      const { data } = await axios.get({
        method,
        url: endpoint,
        data: !editForm ? body : { ...body, active },
      })
      if (data.success) {
        navigate('/configurations')
      } else {
        console.log('Response Fail: ', data.message)
      }
    } catch (error) {
      console.log('Configuration Submit Error: ', error)
    }
  }

  const fetchConfig = async () => {
    try {
      const { data } = await axios.get(`app-configs/${id}`)
      console.log('config detail', data.data)
      if (data.success) {
        const config = data.data
        setKey(config.key)
        setValue(config.value)
        setMmValue(config.mm_value)
        setType(config.type)
        setTitle(config.title)
        setDescription(config.description)
        setPlatform(config.platform)
        setVersion(config.version)
        setActive(config.active)
      }
    } catch (error) {
      console.log('Fetch Config Error: ', error)
    }
  }

  const fetchVariables = async () => {
    try {
      const { data } = await axios.get('variables')
      if (data.success) {
        setVariables(data.data)
      } else {
        console.log('data Fail: ', data.message)
      }
    } catch (error) {
      console.log('Fetch variables Error: ', error)
    }
  }

  React.useEffect(() => {
    editForm && fetchConfig()
    // fetchVariables()
  }, [])

  if (editForm && isLoading) {
    return <Loading />
  }

  return (
    <Formik
      initialValues={{
        key,
        value,
        mmValue,
        type,
        title,
        description,
        platform,
        version,
        active
      }}
      validationSchema={configSchema}
      onSubmit={(values) => {
        console.log(' formik values', values)
        onSubmit(values)
      }}
    >
      {
        ({
          values,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur
        }) => (
          <Form>
            <div className='form-group'>
              <label>Key</label>
              <Field
                name='key'
                type='text'
                className='form-control'
                placeholder='announcement'
              />
              <ErrorMessage
                name='key'
                render={message => <ErrorComponent>{message}</ErrorComponent>}
              />
            </div>
            <div className='form-group'>
              <label className='col-sm-12'>Value</label>
              <div className='form-group d-sm-flex'>
                <Field
                  name='value'
                  type='text'
                  className='form-control'
                  placeholder=' Earliest delivery date is <% earliest_delivery_date %>'
                />
                <button className='btn btn-white ' data-toggle='modal' data-target='#modal'>Show</button>
                <div className='modal fade' id='modal' tabindex='-1' role='dialog' aria-labelledby='exampleModalLabel' aria-hidden='true'>
                  <div className='modal-dialog' role='document'>
                    <div className='modal-content tx-14'>
                      <div className='modal-header'>
                        <h6 className='modal-title' id='exampleModalLabel'>Dynamic Variable</h6>
                        <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div className='modal-body'>
                        <ul>
                          {
                            variables.map(variable => (<li>{`<%=${variable}%>`}<button onClick={() => alert('copy')}>copy</button></li>))
                          }
                        </ul>
                      </div>
                      <div className='modal-footer'>
                        <button type='button' className='btn btn-primary tx-13' data-dismiss='modal'>OK</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ErrorMessage
                name='value'
                render={message => <ErrorComponent>{message}</ErrorComponent>}
              />
            </div>
            <div className='form-group'>
              <label>Myanmar Value</label>
              <Field
                name='mmValue'
                type='text'
                className='form-control'
                placeholder='အစောဆုံးပို့ပေးနိုင်မည့်ရက်...'
              />
              <ErrorMessage
                name='mmValue'
                render={message => <ErrorComponent>{message}</ErrorComponent>}
              />
            </div>
            <div className='form-group'>
              <label>Type</label>
              <SingleSelect
                name='type'
                data={types}
                value={type}
                label='Types'
                onChange={type => setFieldValue('type', type)}
              />
              <ErrorMessage
                name='type'
                render={message => <ErrorComponent>{message}</ErrorComponent>}
              />
            </div>
            <div className='form-group'>
              <label>Title</label>
              <Field
                name='title'
                type='text'
                className='form-control'
                placeholder='I am Title'
              />
              <ErrorMessage
                name='title'
                render={message => <ErrorComponent>{message}</ErrorComponent>}
              />
            </div>
            <div className='form-group'>
              <label>Description</label>
              <Field
                name='description'
                as='textarea'
                className='form-control'
                placeholder='I am description'
              />
              <ErrorMessage
                name='description'
                render={message => <ErrorComponent>{message}</ErrorComponent>}
              />
            </div>
            <div className='form-group'>
              <label>Platform</label>
              <MultiSelect
                name='platform'
                options={platforms}
                defaultValue={platform}
                onBlur={handleBlur}
                onChange={platforms => setFieldValue('platform', platforms)}
              />
              <ErrorMessage
                name='platform'
                render={message => <ErrorComponent>{message}</ErrorComponent>}
              />
            </div>
            <div className='form-group'>
              <label>Version</label>
              <MultiSelect
                name='version'
                options={versions}
                defaultValue={version}
                onBlur={handleBlur}
                onChange={versions => setFieldValue('version', versions)}
              />
              <ErrorMessage
                name='version'
                render={message => <ErrorComponent>{message}</ErrorComponent>}
              />
            </div>
            <div className='form-group'>
              <label>Active</label>
              <div className='custom-control custom-switch'>
                <input
                  name='active'
                  type='checkbox'
                  className='custom-control-input'
                  value={values.active}
                  checked={values.active}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label className='custom-control-label'>  </label>
                <ErrorMessage
                  name='active'
                  render={message => <ErrorComponent>{message}</ErrorComponent>}
                />
              </div>
            </div>
            <div className='form-group'>
              <button className='btn btn-primary btn-block' type='submit'>
                {isSubmitting ? 'Submitting' : 'Submit'}
              </button>
            </div>
          </Form>
        )
      }
    </Formik>
  )
}

